import React from "react";
import SD_Paroksha_Seva_One from "../../Components/body/paroksha-seva/sd-paroksha-seva-component-one";
import SdComingSoon from "../sd-about/sd-coming-soon";

const Sd_private_policy = () => {
  return (
    <div>
      <SD_Paroksha_Seva_One from="Privacy Policy" />
      <SdComingSoon />
    </div>
  );
};
export default Sd_private_policy;
