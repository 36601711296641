import React from "react";
import { withRouter } from "react-router";
import {
  SD_gallery_01,
  SD_gallery_04,
  SD_gallery_02,
  SD_gallery_03,
  SD_gallery_05,
  SD_gallery_06,
  SD_gallery_07,
  SD_gallery_08,
  orange_arrow,
  SD_divider,
} from "../../Assets/imageList";
import "../../Utils/sd-styles/sd-main-sup.scss";
const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];

const Sd_gallery_semi_view = ({ history }) => {
  //-------------------------------------------------------------states-----------------------------------------------------------//
  const [selectedlanguage, setSelectedlanguage] = React.useState("en-in");
  //-------------------------------------------------------------states-----------------------------------------------------------//

  //-------------------------------------------------------------useffeccts-----------------------------------------------------------//
  React.useEffect(() => {
    if (
      supportedLanguages.includes(
        history?.location?.pathname?.split?.("/")?.[1]
      )
    ) {
      setSelectedlanguage(history?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
  }, []);
  //-------------------------------------------------------------useffeccts-----------------------------------------------------------//
  return (
    <div className="sd-template-five">
      <div className="top" style={{ background: "white" }}>
        <h1>Photo Gallery</h1>
        <img src={SD_divider} alt="-" style={{ height: "20px" }}></img>
      </div>
      <br></br>
      <div className="sd-photo-grid">
        <img src={SD_gallery_01} alt="picture1" className="item1" />
        <img src={SD_gallery_08} alt="picture8" className="item2" />
        <img src={SD_gallery_05} alt="picture5" className="item3" />

        <img src={SD_gallery_04} alt="picture4" className="item4" />
        <img src={SD_gallery_02} alt="picture2" className="item5" />
        <img src={SD_gallery_07} alt="picture7" className="item6" />
      </div>
      <button
        className="sd-b-orange"
        onClick={() => {
          history?.push(`/${selectedlanguage}/media-room/photo-gallery`);
        }}
      >
        View Now <img src={orange_arrow} alt=">" />
      </button>
    </div>
  );
};
export default withRouter(Sd_gallery_semi_view);
