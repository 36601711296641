import Axios from "../Utils/sd-axios/Axios";

const UserService = {
  createUserPass: async (phone, displayName, OTP, password) => {
    try {
      const resp = await Axios.post("/user/create/pass", {
        phone: phone,
        displayName: displayName,
        otp: parseInt(OTP),
        password: password,
        templeId: 2,
      });
      return resp.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        throw new Error("Session Expired");
      } else if (
        // error?.response?.status !== 500 &&
        error?.response?.data?.message
      ) {
        throw new Error(error?.response?.data?.message);
      } else {
        //console.error(error);
        throw new Error("something is wrong");
      }
    }
  },

  isRegistered: async (phone) => {
    try {
      const resp = await Axios.get(`/user/is-registered?phone=${phone}`);
      return resp.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        throw new Error(error?.response?.data?.message);
      } else {
        //console.error(error);
        throw new Error("something is wrong");
      }
    }
  },

  guestLogin: async (phone, OTP) => {
    try {
      const resp = await Axios.post("/user/guest-login", {
        phone: phone,
        otp: parseInt(OTP),
      });
      return resp.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        throw new Error(error?.response?.data?.message);
      } else if (
        // error?.response?.status !== 500 &&
        error?.response?.data?.message
      ) {
        throw new Error(error?.response?.data?.message);
      } else {
        //console.error(error);
        throw new Error("something is wrong");
      }
    }
  },

  checkToken: async (templeID) => {
    try {
      const resp = await Axios.get("/user/check-token", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Code": templeID,
        },
      });
      return resp.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        throw new Error("unauthorized");
      } else if (
        // error?.response?.status !== 500 &&
        error?.response?.data?.message
      ) {
        throw new Error(error?.response?.data?.message);
      } else {
        //console.error(error);
        throw new Error("something is wrong");
      }
    }
  },
  otpogin: async (phone, otp) => {
    try {
      const resp = await Axios.post("/user/login/phone-otp", {
        phone: phone,
        otp: parseInt(otp),
      });
      return resp.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        throw new Error(error?.response?.data?.message);
      } else if (
        // error?.response?.status !== 500 &&
        error?.response?.data?.message
      ) {
        throw new Error(error?.response?.data?.message);
      } else {
        //console.error(error);
        throw new Error("something is wrong");
      }
    }
  },
  resetPassword: async (phone, otp, password) => {
    try {
      const resp = await Axios.post("user/password/forgot", {
        phone: phone,
        otp: parseInt(otp),
        password: password,
        templeId: 2,
      });
      return resp.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        throw new Error("unauthorized");
      } else if (
        // error?.response?.status !== 500 &&
        error?.response?.data?.message
      ) {
        throw new Error(error?.response?.data?.message);
      } else {
        //console.error(error);
        throw new Error("something is wrong");
      }
    }
  },
  userLogin: async (phone, password, token) => {
    try {
      const resp = await Axios.post("/user/login", {
        phone: phone,
        password: password,
        captchaHumanKey: token,
      });
      return resp.data;
    } catch (error) {
      if (
        // error?.response?.status !== 500 &&
        error?.response?.data?.message
      ) {
        throw new Error(error?.response?.data?.message);
      } else {
        //console.error(error);
        throw new Error("something is wrong");
      }
    }
  },
  changePassword: async (old, pass, phone) => {
    try {
      const resp = await Axios.post("/user/password/reset", {
        oldPassword: old,
        phone: phone,
        newPassword: pass,
      });
      return resp.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        throw new Error("unauthorized");
      } else if (
        // error?.response?.status !== 500 &&
        error?.response?.data?.message
      ) {
        throw new Error(error?.response?.data?.message);
      } else {
        //console.error(error);
        throw new Error("something is wrong");
      }
    }
  },
  editUserDetails: async (data, templeID) => {
    try {
      const resp = await Axios.post("/user/edit-user", data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Code": templeID,
        },
      });
      return resp.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        throw new Error("unauthorized");
      } else if (
        // error?.response?.status !== 500 &&
        error?.response?.data?.message
      ) {
        throw new Error(error?.response?.data?.message);
      } else {
        //console.error(error);
        throw new Error("something is wrong");
      }
    }
  },
  getVisitors: async (id) => {
    try {
      const resp = await Axios.get(
        "/temple-visitor-count/visitors-count/online",
        {
          headers: {
            "X-Temple-Code": id,
          },
        }
      );
      return resp.data;
    } catch (error) {
      if (
        // error?.response?.status !== 500 &&
        error?.response?.data?.message
      ) {
        throw new Error(error?.response?.data?.message);
      } else {
        //console.error(error);
        throw new Error("something is wrong");
      }
    }
  },
  getTempleConfig: async (id) => {
    try {
      const resp = await Axios.get(`/temples/get-by-temple-code/${id}`);
      return resp.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        throw new Error(error?.response?.data?.message);
      } else {
        //console.error(error);
        throw new Error(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.response?.message
            ? error?.response?.message
            : "something is wrong"
        );
      }
    }
  },
  addVisitors: async () => {
    try {
      const resp = await Axios.post("/analytics/visitors-count/inc");
      return resp.data;
    } catch (error) {
      if (
        // error?.response?.status !== 500 &&
        error?.response?.data?.message
      ) {
        throw new Error(error?.response?.data?.message);
      } else {
        //console.error(error);
        throw new Error("something is wrong");
      }
    }
  },
};

export default UserService;
