/* eslint-disable react/react-in-jsx-scope */
const paddingStyle = {
  paddingLeft: "40px",
  marginTop: "-23px",
};
export const data = {
  paroksha_seva: [
    {
      question: `What is the significance of Paroksha Seva?`,
      answer: `Paroksha Seva is for those who are unable to participate in Sevas directly. Devasthanam facilitates the devotee participation in the Seva virtually.`,
    },
    //{
    //question: `What are the uses of Paroksha Seva?`,
    //answer: `Paroksha seva is for those who are unable to participate in Sevas directly. Devasthanam facilitates the devotee participation in the Seva virtually.`,
    //},
    {
      question: `What is the difference between Paroksha seva and Pratyaksha Seva?`,
      answer: `Paroksha seva is for those who are unable to participate in Sevas directly. Devasthanam facilitates the devotee participation in the Seva virtually. For those who can visit the Devasthanam, they will be able to participate in the Seva directly at the temple.`,
    },
    {
      question: `Who should attend Paroksha seva?`,
      answer: `Any devotee can attend Paroksha Seva. `,
    },
    {
      question: `Can unmarried persons attend Paroksha Seva?`,
      answer: `Yes, unmarried people can attend Paroksha Seva. `,
    },
    {
      question: `What are the timings of Paroksha Seva?`,
      answer: `Based on the Seva, Paroksha Seva timings will differ. Seva timings are listed on the Devasthanam website.`,
    },
    {
      question: `Will Paroksha Sevas be performed at the same timings every day?`,
      answer: `Yes. All Paroksha Sevas will be performed during the designated timings.`,
    },
    {
      question: `Will you perform Paroksha seva on auspicious and festival days?`,
      answer: `Paroksha Seva services on auspicious and festival days depends on the devotee count. `,
    },
    {
      question: `If yes, are there any change of timings in performing Paroksha Seva?`,
      answer: `No. All Paroksha Sevas will be performed during the designated timings. `,
    },
    {
      question: ` How to book Paroksha seva? `,
      answer: `You may book Paroksha Seva via Devasthanam official website www.srisailadevasthanam.org, on the Paroksha Seva page. `,
    },
    {
      question: `Can we book Paroksha seva offline? `,
      answer: ` No. Paroksha Seva is an online service.`,
    },
    {
      question: `What are the proofs to be submitted to book Paroksha Seva?`,
      answer: `There is no need to submit any proof to book a Paroksha Seva. `,
    },
    {
      question: `How prior can we book Paroksha Seva?`,
      answer: `One may book Paroksha Seva 15 Days prior.`,
    },
    {
      question: `What is the cut off time to book Paroksha Seva?`,
      answer: ` Paroksha Seva can only be booked at least 24 Hrs prior.`,
    },
    {
      question: ` Can we attend Paroksha seva by booking a ticket for Pratyaksha Seva?`,
      answer: `No. Paroksha seva ticket is only applicable for Paroksha Sevas.`,
    },
    {
      question: `Can we attend Pratyaksha Seva by booking a ticket for Paroksha Seva?`,
      answer: `Pratyaksha Seva ticket holders are allowed to utilise the ticket only for Pratyaksha Sevas.`,
    },
    {
      question: `Is there any difference in Seva rituals between Paroksha seva and Pratyaksha seva?`,
      answer: `Paroksha seva is for those who are unable to participate in Sevas directly. Devasthanam facilitates the devotee participation in the seva virtually.For those who can visit the Devasthanam, they will be able to participate in the Seva directly at the temple.`,
    },
    {
      question: `Will you perform two or more Sevas at the same time?`,
      answer: `Yes, but you may not book two or more Sevas that are scheduled at the same time.`,
    },
    {
      question: `If we book tickets for two different Sevas at the same time, how can we watch?`,
      answer: `If you've booked two different Sevas, you can watch them live via Sri Kanipaka TV Youtube channel.`,
    },
    {
      question: `Are there any offers available, if we book more Sevas? `,
      answer: ` No. There are no offers available. `,
    },
    {
      question: `What are the rules and regulations to be followed during Paroksha Seva?`,
      answer: `Devotees are requested to take a bath and watch the Seva live on Sri Kanipaka TV`,
    },
    {
      question: `Will you read-out Gothra-Namas of the whole family with a single ticket?`,
      answer: `No. Names of 4 People will be read-out as applicable under one ticket.`,
    },
    {
      question: ` With a single ticket, how many Gothrams can be allowed to read?`,
      answer: `Names of 4 People will be read-out as applicable under one ticket.`,
    },
    {
      question: `Will you perform seva to each individual ticket holders separately?`,
      answer: `No. Performing seva to each individual ticket holders separately is not possible.`,
    },
    {
      question: `Will you provide Prasadam for Paroksha seva attendees?`,
      answer: `Kumkuma of the goddess & Vibhudhi of the god will be delivered to the devotee's postal address.`,
    },
    {
      question: `How will we know that you sent Prasadam for us?`,
      answer: `You will get an SMS notification with a tracking number`,
    },
    {
      question: `What Prasadam will be sent by you for Paroksha seva attendees?`,
      answer: `Kumkuma of the goddess & Vibhudhi of the god will be delivered to the devotee's postal address.`,
    },
    {
      question: `How will you send Prasadam to our place?`,
      answer: `Kumkuma of the goddess & Vibhudhi of the god will be delivered to the devotee's postal address via Indian Postal.`,
    },
    {
      question: `How can we track our Prasadam?`,
      answer: `You will get an SMS notification with a tracking number.`,
    },
    {
      question: `How many days will it take to receive Prasadam?`,
      answer: `The number of days depends on the distance of your place from Kanipakam. `,
    },
    {
      question: `How can we watch Paroksha Seva live?`,
      answer: `You may watch the Seva live on Sri Kanipaka TV YouTube channel.`,
    },
    {
      question: `Without the Internet, is there any possibility for us to watch Paroksha seva on any TV channel (Ex: Bhakti TV)?`,
      answer: `No. You may watch the Seva live on Sri Kanipaka TV YouTube channel.`,
    },
    {
      question: `If we don’t have the Internet, how can we watch Paroksha seva live?`,
      answer: `You may watch the Seva live on the Sri Kanipaka TV YouTube channel that requires an internet connection.`,
    },
    {
      question: `If we miss Paroksha seva live, can we watch the recap?`,
      answer: `You may watch the Seva on recap via Sri Kanipaka TV YouTube channel.`,
    },
    {
      question: `Is there any daily limit for Paroksha Seva booking?`,
      answer: `Yes. There is a daily limit to Paroksha Seva bookings. `,
    },
    {
      question: `Can we cancel the Paroksha Seva ticket?`,
      answer: `No. Once booked, the ticket can not be cancelled.`,
    },
    {
      question: `Can we transfer our ticket to another person or change the date of Seva?`,
      answer: `No. You may not transfer your ticket or change the date of Seva.`,
    },
    {
      question: `Can NRIs book Paroksha seva?`,
      answer: `Yes. NRIs may book Paroksha Seva.`,
    },
    {
      question: `Can NRIs get Prasadam by booking Paroksha Seva?`,
      answer: `No. Currently, we do not provide a Prasadam delivery facility to NRIs.`,
    },
    {
      question: `How can I provide feedback and suggestions?`,
      answer: `Devasthanam information centre employees will attend to all your feedback, queries & complaints. They may record your suggestion and send it to the concerned department to serve you better.`,
    },
    {
      question: `What are the terms and conditions to be followed to book Paroksha Seva?`,
      answer: `Devotees are requested to dress in traditional attires for the Seva.`,
    },
    {
      question: `For Further queries and complaints, whom should we contact?`,
      answer: `Devasthanam information centre employees will attend to all your queries & complaints. They may record your complaint and send it to the concerned department to serve you better.`,
    },
  ],
  prak_chandi_homam: [
    {
      question: `What is the significance of Chandi Homam?`,
      answer: `Chandi Devi represents Mahakali, Mahalakshmi and Maha Saraswathi. By performing Chandi Homam, participants get the blessings of all the three goddesses.`,
    },
    {
      question: `What are the timings of Chandi Homam?`,
      answer: `The timings of Chandi Homam are 11:00 a.m To 1:00 p.m.`,
    },
    {
      question: `What is the price of Chandi Homam?`,
      answer: `Chandi Homam costs Rs.1116/-.`,
    },
    {
      question: `How many names and gothrams will be read-out for one ticket during Chandi Homam?`,
      answer: `Names of 4 People will be read-out as applicable under one ticket during Chandi Homam.`,
    },
    {
      question: `How much time will it take for Homam?`,
      answer: `Chandi Homam takes around 2 Hrs.`,
    },
    {
      question: `What are the formalities to be followed during Seva?`,
      answer: `Devotees are requested to dress in traditional attires for the Homam.`,
    },
    {
      question: `What are the ritual elements used in Homam? `,
      answer: `Ritual elements like Sukandha Dhrayams etc are used for Homam.`,
    },
    {
      question: `What Prasadam will be provided for us?`,
      answer: `Kumkuma of the goddess & Vibhudhi of the god will be delivered to the devotee's postal address.`,
    },
    {
      question: `Where can I watch Chandi Homam live?`,
      answer: `You may watch Chandi Homam live on Sri Kanipaka TV YouTube channel. `,
    },
    {
      question: `For Further queries and complaints, whom should we contact?`,
      answer: `Devasthanam information centre employees will attend to all your queries & complaints. They may record your complaint and send it to the concerned department to serve you better.`,
    },
  ],
  park_lk: [
    {
      question: `Why Laksha Kumkumarchana ?`,
      answer: `Kumkuma is beloved element of Shakthi, participants of Laksha Kumkumarchana in Kanipakam will obtain sacred blessings of Shakthi.`,
    },
    {
      question: `What are the timings of Laksha Kumkumarchana ?`,
      answer: `On every Full Moon Day, Laksha Kumkumarchana starts by 05:30 p.m.`,
    },
    {
      question: `What is the price of Laksha Kumkumarchana ?`,
      answer: `Rs. 1116/-.`,
    },
    {
      question: `How many names and gothrams will be readout for one ticket during Laksha Kumkumarchana ?`,
      answer: `4 People on One gothram.`,
    },
    {
      question: `How much time it will take for Laksha Kumkumarchana ?`,
      answer: `It varies on circumstances. But usually it takes more than an hour.`,
    },
    {
      question: `What are the ritual elements used in Laksha Kumkumarchana?`,
      answer: `Kumkuma, flowers and some auspicious elements are used in the ritual. `,
    },
    {
      question: `What Prasadam will be provided for us?`,
      answer: `Kumkuma & Vibhudhi of the god will be delivered to the devotee's postal address.`,
    },
    {
      question: `Where can I watch Laksha Kumkumarchana live ?`,
      answer: `Through Sri Kanipaka TV YouTube channel.`,
    },
    {
      question: `If any queries and complaints, whom should we contact ?`,
      answer: `Devasthanam call centre employees can record the complaints and send it to concern department.`,
    },
  ],
  park_ganapthi_homam: [
    {
      question: `What is the significance of Ganapathi Homam?`,
      answer: `Ganapathi Homam is performed to avoid hurdles/obstacles in work and life in general.`,
    },
    {
      question: `What are the timings of Ganapathi Homam? `,
      answer: `The timings of Ganapathi Homam are 06:45 a.m to 07:45 a.m.`,
    },
    {
      question: `What is the price of Ganapathi Homam?`,
      answer: `Ganapathi Homam costs Rs. 1116/-.`,
    },
    {
      question: `How many names and gothrams will be readout for one ticket during Ganapathi Homam?`,
      answer: `Names of 4 People will be read-out as applicable under one ticket during Ganapathi Homam.`,
    },
    {
      question: `How much time will it take for Homam?`,
      answer: `Ganapathi Homam takes around 1 hr.`,
    },
    {
      question: `What are the formalities to be followed during the Seva?`,
      answer: `Devotees who book Paroksha Seva are requested to take a bath and watch the Seva live on Sri Kanipaka TV.`,
    },
    {
      question: `What Prasadam will be provided for us?`,
      answer: `Kumkuma of the goddess & Vibhudhi of the god will be delivered to the devotee's postal address.`,
    },
    {
      question: `Where can I watch Ganapathi Homam live?`,
      answer: `You may watch the Homam live on Sri Kanipaka TV YouTube channel.`,
    },
    {
      question: `For Further queries and complaints, whom should we contact?`,
      answer: `:- Devasthanam information centre employees will attend to all your queries & complaints. They may record your complaint and send it to the concerned department to serve you better.`,
    },
  ],
  park_kumkumarchana: [
    {
      question: `What is the significance of Kumkumarchana?`,
      answer: `Kumkuma is very dear to Goddess Shakthi. Participants of Kumkumarchana at Kanipakam will earn the complete & sacred blessings of Goddess Shakthi.`,
    },
    {
      question: `What are the timings of Kumkumarchana?`,
      answer: `The timings of Kumkumarchana is 8:00 a.m`,
    },
    {
      question: `What is the price of Kumkumarchana?`,
      answer: `Kumkumarchana costs Rs. 1116/-.`,
    },
    {
      question: `How many names and gothrams will be readout for one ticket during Kumkumarchana?`,
      answer: `:- Names of 4 People will be read-out as applicable under one ticket during Kumkumarchana.`,
    },
    {
      question: `How much time it will take for Kumkumarchana?`,
      answer: `Kumkumarchana takes around 30 Mins.`,
    },
    {
      question: `What are the formalities to be followed during Seva?`,
      answer: `Devotees who book Paroksha Seva are requested to take a bath and watch the Seva live on Sri Kanipaka TV.`,
    },
    {
      question: `What are the ritual elements used in Kumkumarchana?`,
      answer: `Kumkuma is the ritual element used in Kumkumarchana.`,
    },
    {
      question: `What Prasadam will be provided for us?`,
      answer: `Kumkuma of the goddess & Vibhudhi of the god will be delivered to the devotee's postal address.`,
    },
    {
      question: `Where can I watch Kumkumarchana live?`,
      answer: `You may watch the Seva live on Sri Kanipaka TV YouTube channel.`,
    },
    {
      question: `For Further queries and complaints, whom should we contact?`,
      answer: `Devasthanam information centre employees will attend to all your queries & complaints. They may record your complaint and send it to the concerned department to serve you better.`,
    },
  ],
  park_leela_kalyanotsavam: [
    {
      question: `What is the significance of Leela Kalyanotsavam?`,
      answer: `People participate in Leela Kalyanotsavam to get rid of marital obstacles.`,
    },
    {
      question: `What are the timings of Leela Kalyanotsavam?`,
      answer: `The timings of Leela Kalyanotsavam is 7:30 p.m`,
    },
    {
      question: `How many names and gothrams will be read-out for one ticket during Seva?`,
      answer: `Names of 4 People will be read-out as applicable under one ticket during the Seva.`,
    },
    {
      question: `How much time it will take for Leela Kalyanotsavam?`,
      answer: `Leela Kalyanotsavam takes around 1 hr.`,
    },
    {
      question: `What Prasadam will be provided for us?`,
      answer: `Kumkuma of the goddess & Vibhudhi of the god will be delivered to the devotee's postal address.`,
    },
    {
      question: `Where can I watch Leela Kalyanotsavam live?`,
      answer: `You may watch the Seva live on Sri Kanipaka TV YouTube channel.`,
    },
    {
      question: `For Further queries and complaints, whom should we contact?`,
      answer: `Devasthanam information centre employees will attend to all your queries & complaints. They may record your complaint and send it to the concerned department to serve you better.`,
    },
  ],
  park_mrityunkjaya: [
    {
      question: `What is the significance of Mrityunjaya Homam?`,
      answer: `Untimely deaths can be avoided by participating in Mrityunjaya Homam at Kanipakam.`,
    },
    {
      question: `What are the timings of Mrityunjaya Homam?`,
      answer: `The timings of Mrityunjaya Homam are 8:00 a.m To 9:00 a.m.`,
    },
    {
      question: `What is the price of Mrityunjaya Homam?`,
      answer: `Mrityunjaya Homam costs Rs. 1116/-.`,
    },
    {
      question: `How many names and gothrams will be readout for one ticket during Mrityunjaya Homam?`,
      answer: `Names of 4 People will be read-out as applicable under one ticket during the Seva.`,
    },
    {
      question: `How much time will it take for Homam?`,
      answer: `Mrityunjaya Homam takes around 1 hr.`,
    },
    {
      question: `What Prasadam will be provided for us?`,
      answer: `Kumkuma of the goddess & Vibhudhi of the god will be delivered to the devotee's postal address.`,
    },
    {
      question: `Where can I watch Mrityunjaya Homam live?`,
      answer: `You may watch the Seva live on Sri Kanipaka TV YouTube channel.`,
    },
    {
      question: `For Further queries and complaints, whom should we contact?`,
      answer: `Devasthanam information centre employees will attend to all your queries & complaints. They may record your complaint and send it to the concerned department to serve you better.`,
    },
  ],
  park_rudra_homam: [
    {
      question: `What is the significance of Rudra Homam?`,
      answer: `Rudra is another form of Lord Shiva who performs a fierce dance. The participants of Rudra Homam earn the Holy protection of Lord Shiva.`,
    },
    {
      question: `What are the timings of Rudra Homam?`,
      answer: `The timings of Rudra Homam are 8:00 a.m To 9:00 a.m.`,
    },
    {
      question: `What is the price of Rudra Homam?`,
      answer: `Rudra Homam costs Rs. 1116/-.`,
    },
    {
      question: `How many names and gothrams will be readout for one ticket during Rudra Homam?`,
      answer: `Names of 4 People will be read-out as applicable under one ticket during the Seva.`,
    },
    {
      question: `How much time will it take for Homam?`,
      answer: `The Homam takes around 1 hr.`,
    },
    {
      question: `What Prasadam will be provided for us?`,
      answer: `Kumkuma of the goddess & Vibhudhi of the god will be delivered to the devotee's  postal address.`,
    },
    {
      question: `Where can I watch Rudra Homam live?`,
      answer: `You may watch the Seva live on Sri Kanipaka TV YouTube channel.`,
    },
    {
      question: `For Further queries and complaints, whom should we contact?`,
      answer: `Devasthanam information centre employees will attend to all your queries & complaints. They may record your complaint and send it to the concerned department to serve you better.`,
    },
  ],
  park_rudra_abhishekam: [
    {
      question: `What is the significance of Rudraabhishekam?`,
      answer: `It is believed that by reciting Rudram during Rudraabhishekam, Sri Mallikarjuna Swamy will be gratified and fulfil the wishes of the participants.`,
    },
    {
      question: `What are the timings of Rudraabhishekam?`,
      answer: `The timings of Rudraabhishekam is 8:00 a.m.`,
    },
    {
      question: `What is the price of Rudraabhishekam?`,
      answer: `Rudraabhishekam costs Rs. 1116/-.`,
    },
    {
      question: `How many names and gothrams will be read-out for one ticket during Rudraabhishekam?`,
      answer: `Names of 4 People will be read-out as applicable under one ticket during the Seva.`,
    },
    {
      question: `How much time will it take for Rudraabhishekam?`,
      answer: `Rudraabhishekam takes around 1 hr.`,
    },
    {
      question: `What Prasadam will be provided for us?`,
      answer: `Kumkuma of the goddess & Vibhudhi of the god will be delivered to the devotee's
    postal address.`,
    },
    {
      question: `Where can I watch Rudraabhishekam live?`,
      answer: `You may watch the Seva live on Sri Kanipaka TV YouTube channel.`,
    },
    {
      question: `For Further queries and complaints, whom should we contact?`,
      answer: `Devasthanam information centre employees will attend to all your queries & complaints. They may record your complaint and send it to the concerned department to serve you better.`,
    },
  ],
  park_valli_devasena: [
    {
      question: `What is the significance of Subramanyeswara Swamy Kalyanam?`,
      answer: `People perform Valli Devasena Sametha Subramanyeswara Swamy Kalyanam to get rid of Kuja Dosham.`,
    },
    {
      question: `What are the timings of Subramanyeswara Swamy Kalyanam?`,
      answer: `The timings of Subramanyeswara Swamy Kalyanam are 9:30 a.m To 10:30 a.m.`,
    },
    {
      question: `What is the price of Subramanyeswara Swamy Kalyanam?`,
      answer: `the price of Subramanyeswara Swamy Kalyanamis Rs. 1116/-.`,
    },
    {
      question: `How many names and gothrams will be readout for one ticket during
    Subramanyeswara Swamy Kalyanam?`,
      answer: `Names of 4 People will be read-out as applicable under one ticket during the Seva.`,
    },
    {
      question: `How much time will it take for Subramanyeswara Swamy Kalyanam?`,
      answer: `Subramanyeswara Swamy Kalyanam takes around 1 hr.`,
    },
    {
      question: `What Prasadam will be provided for us?`,
      answer: `Kumkuma of the goddess & Vibhudhi of the god will be delivered to the devotee's postal address.`,
    },
    {
      question: `Where can I watch it live?`,
      answer: `You may watch the Seva live on Sri Kanipaka TV YouTube channel.`,
    },
    {
      question: `For Further queries and complaints, whom should we contact?`,
      answer: `Devasthanam information centre employees will attend to all your queries & complaints. They may record your complaint and send it to the concerned department to serve you better.`,
    },
  ],
  park_vedasirvachanam: [
    {
      question: `What is the significance of Vedasirvachanam`,
      answer: `The kindness and sacred blessings of Lord Shiva will be showered on participants of Vedasirvachanam.`,
    },
    {
      question: `What are the timings of Vedasirvachanam?`,
      answer: `the timings of Vedasirvachanam are 6:45 p.m To 7:45 p.m.`,
    },
    {
      question: `What is the price of Vedasirvachanam?`,
      answer: `Vedasirvachanam costs Rs. 1116/-.`,
    },
    {
      question: `How many names and gothrams will be readout for one ticket during Vedasirvachanam?`,
      answer: `Names of 4 People will be read-out as applicable under one ticket during the Seva.`,
    },
    {
      question: `How much time will it take for Vedasirvachanam?`,
      answer: `Vedasirvachanam takes around 1 hr.`,
    },
    {
      question: `What are the formalities to be followed during Seva?`,
      answer: `Devotees who book Paroksha Seva are requested to take a bath and watch the Seva live on Sri Kanipaka TV.`,
    },
    {
      question: `What Prasadam will be provided for us?`,
      answer: `Kumkuma of the goddess & Vibhudhi of the god will be delivered to the devotee's  postal address.`,
    },
    {
      question: "Where can I watch Vedasirvachanam live?",
      answer: "You may watch the Seva live on Sri Kanipaka TV YouTube channel.",
    },
    {
      question: `For Further queries and complaints, whom should we contact?`,
      answer: `Devasthanam information centre employees will attend to all your queries & complaints. They may record your complaint and send it to the concerned department to serve you better.`,
    },
  ],
  park_ekantha_Seva: [
    {
      question: `What is the significance of Vedasirvachanam`,
      answer: `The kindness and sacred blessings of Lord Shiva will be showered on participants of Vedasirvachanam.`,
    },
    { question: ``, answer: `` },
    { question: ``, answer: `` },
    { question: ``, answer: `` },
    { question: ``, answer: `` },
    { question: ``, answer: `` },
    { question: ``, answer: `` },
    { question: ``, answer: `` },

    {
      question: `For Further queries and complaints, whom should we contact?`,
      answer: `Devasthanam information centre employees will attend to all your queries & complaints. They may record your complaint and send it to the concerned department to serve you better.`,
    },
  ],
  online_accomadation: [
    {
      question: `What is the process to book Accommodation?`,
      answer: `Visit the Devasthanam official website for Devasthanam accommodation www.srisailadevasthanam.org. Click on Accommodation-select Advanced date and book the accommodation.`,
    },
    {
      question: `Is registration required to book accommodation?`,
      answer: `Yes, Registration is required for accommodation booking.`,
    },
    {
      question: `What are the documents to be submitted while booking accommodation?`,
      answer: `Name of the Person, an authorized Id Proof, like Aadhar Card, Driving Licence etc. `,
    },
    {
      question: `Am I supposed to show the original copy of documents submitted during booking at the reception of choultry?`,
      answer: `Yes, you must show the Original Id proof that you've submitted for booking accommodation.`,
    },
    {
      question: `What types of rooms are available in accommodation?`,
      answer: `There are two types of Accommodation available.Online bookings: 1. Non-A.C (2-bed ) 2. A.C (2-Bed) Offline bookings: 1. Non-A.C (2-bed ) 2. A.C (2-Bed), Dormitory.`,
    },
    {
      question: `What are the check-in and check-out timings?`,
      answer: `Check-in and check-out timings are 08:00 AM To 8:00 AM.`,
    },
    {
      question: `Is a safety deposit required for checking-in?`,
      answer: `Yes, a safety deposit amount is mandatory for checking-in`,
    },
    {
      question: `Where should I collect my deposit amount while checking-out?`,
      answer: `At your respective Accommodation Reception you may collect the deposit amount while checking-out.`,
    },
    {
      question: `Is it secure to keep my belongings in the choultry room?`,
      answer: `For depositing your belongings, there are individual rooms available and for dormitories separate lockers are available.`,
    },
    {
      question: `Can I change the room you allotted, if I don’t like it?`,
      answer: `Once allotted, it’s not possible to change the room.`,
    },
    {
      question: `How prior should I book accommodation online?`,
      answer: `One may book accommodation 15 days prior only.`,
    },
    {
      question: `Can I book accommodation offline?`,
      answer: `Yes, you may book accommodation offline.`,
    },
    {
      question: `What are the documents to be shown to avail accommodation?`,
      answer: `Documents like Address Proof like Aadhar Card, Driving License, etc. are valid to avail accommodation`,
    },
    {
      question: `How many choultries are available in Kanipakam?`,
      answer: `A total of 65 choultries are available in Kanipakam`,
    },
    {
      question: `Is there any choultry operating under Devasthanam?`,
      answer: `Yes. Choultries operation under Devasthanam for accommodation are available.`,
    },
    {
      question: `How many rooms can I book within a single registration?`,
      answer: `A single person is allowed to book single accommodation only. There is no restriction on the number of room bookings `,
    },
    {
      question: `Can I choose a specific room of my choice during the accommodation booking?`,
      answer: `Yes, you will be able to select a room of your choice during accommodation booking.`,
    },
    {
      question: `How many persons are allowed to stay in a room?`,
      answer: `Only Four Persons are allowed to stay in a room.`,
    },
    {
      question: `Will choultry provide any extra beds, if needed?`,
      answer: `No. choultry will not be able to provide any extra beds. Please read the room description carefully before booking.`,
    },
    {
      question: `How long can I stay in a choultry room?`,
      answer: `You may stay in a choultry room for 24 Hours.`,
    },
    {
      question: `Is there Annadaanam in choultries?`,
      answer: `Not all, but a few choultries have Annadanam service.`,
    },
    {
      question: `What are the rules and regulations to be followed to stay in choultries?`,
      answer: `Normally one may stay up to two days in a choultry during the off-season. During Peak days, one is allowed to stay in a room for only a single day.`,
    },
    {
      question: `Can I cancel my accommodation?`,
      answer: `No. Once booked, accommodation can not be cancelled. `,
    },
    {
      question: `Is there any cancellation fee?`,
      answer: `No. There is no cancellation fee.`,
    },
    {
      question: `How prior can I cancel my booking?`,
      answer: `Cancellation of a booking is not available.`,
    },
    {
      question: `For queries and complaints, whom should we contact?`,
      answer: `You may contact the Devasthanam information centre for all your queries and complaints. For more queries, please send a Mail to Devasthanam official e-mail id endow-eosri@gov.in.`,
    },
  ],
  aagama_patasala_trust: [
    {
      question: `What is the donation limit for Aagama Patasala trust?`,
      answer: `There is no limit for a donation.`,
    },
    {
      question: `What type of payments are accepted??`,
      answer: `All digital transactions are accepted.`,
    },
    {
      question: `How many students are allowed to join in Aagama Patasala per academic year?`,
      answer: `No Limit.`,
    },
    {
      question: `What is the syllabus for Aagama Patasala?`,
      answer: `Aagamas.`,
    },
    {
      question: `Can I donate books, stationery and clothes for students of Aagama Patasala?`,
      answer: `Yes.`,
    },
    {
      question: `Can I donate in some other’s name?`,
      answer: `Yes.`,
    },
    {
      question: `Is my donation tax-deductible?`,
      answer: `Yes.`,
    },
    {
      question: `Can I get an 80G certificate?`,
      answer: `Yes.`,
    },
    {
      question: `Will I get any privileges by donations?`,
      answer: `Yes.`,
    },
    {
      question: `How can I get a receipt of the donation?`,
      answer: `The receipt is generated and sent to the donor's e-mail.`,
    },
    {
      question: `Can NRIs donate to Aagama Patasala?`,
      answer: `Yes.`,
    },
    {
      question: `Can I get the Aagama Patasala Trust financial summary?`,
      answer: `No.`,
    },
    {
      question: `Is there any age limit to join Aagama Patasala?`,
      answer: `Yes.`,
    },
  ],
  anna_prasadam_trust: [
    {
      question: `What is the donation limit for Annaprasadam trust?`,
      answer: `You may donate a Minimum of 500 Rupees to a Maximum of any amount your bank permits.`,
    },
    {
      question: `What is the amount of the Saswata Annaprasadam scheme?`,
      answer: `A Min Rs. 5000 /- is required to avail Saswata Annaprasadam scheme.`,
    },
    {
      question: `What type of payments are accepted?`,
      answer: `All digital Transactions are accepted.`,
    },
    {
      question: `Can I donate with a cheque or DD?`,
      answer: `Yes. We accept a cheque or a DD`,
    },
    {
      question: `Can I donate any assets?`,
      answer: `Yes. Please contact the Executive officer to donate your assets.`,
    },
    {
      question: `How many people will you feed per day?`,
      answer: `The number of people we feed per day depends on the devotee’s count.`,
    },
    {
      question: `Can we choose a specific day of donation?`,
      answer: `Yes. You may choose the date of your choice to donate.`,
    },
    {
      question: `How much amount do I have to pay as a donation towards the complete Annaprasadam scheme for one day?`,
      answer: `A Minimum amount of 500 needs to be donated per day.`,
    },
    {
      question: `What is the tenure of the longest period of Annaprasadam donation?`,
      answer: `The maximum tenure for Annaprasadam donation is 10 Years Only.`,
    },
    {
      question: `Will you communicate after the completion of tenure with us?`,
      answer: `Yes. We communicate during and after the completion of the tenure.`,
    },
    {
      question: `What is the menu of Annaprasadam?`,
      answer: `We have a variety of menu items available on different days of Annaprasadam.`,
    },
    {
      question: `Can I donate rice, vegetables or any other raw food items to Annaprasadam Trust?`,
      answer: `Yes, You may donate food items to Annaprasadam Trust.`,
    },
    {
      question: `Can I donate in some other’s name?`,
      answer: `Yes, you may donate in other's name.`,
    },
    {
      question: `Is my donation tax-deductible?`,
      answer: `Yes. Your donation is eligible for tax-deduction.`,
    },
    {
      question: `Can I get an 80G certificate?`,
      answer: `Yes. An 80G certificate is sent to you after donation.`,
    },
    {
      question: `Will I get any privileges by donating?`,
      answer: `You are eligible for donor card privilege if you donate more than 1Lakh. A Donor Card issued in the donor's name facilitates the Darshan for 5 Members.`,
    },
    {
      question: `How can I get a receipt of the donation?`,
      answer: `The donation receipt is sent to the email and a bond will be sent via Indian postal to the donor's address.`,
    },
    {
      question: `Can NRIs donate to Annaprasadam Trust ?`,
      answer: `Yes. NRI's may donate to Annaprasadam Trust`,
    },
    {
      question: `Can I get the Annaprasadam Trust financial summary?`,
      answer: `No. Annaprasadam Trust financial summary can not be claimed or viewed.`,
    },
    {
      question: `Can I participate as a volunteer in Annaprasadam Trust for a day during Annadaanam?`,
      answer: `Yes. You may volunteer in Annaprasadam Trust for a day during Annadaanam?`,
    },
    {
      question: `How should I register as a volunteer in Annaprasadam Trust?`,
      answer: `You may register as a volunteer via the Devasthanam website.`,
    },
    {
      question: `Can I register offline to work as a volunteer at Annaprasadam Trust?`,
      answer: `Yes. You may register offline as a volunteer at Annaprasadam Trust?`,
    },
  ],
  darshanam: [
    {
      question: `What are the timings of Darshanam?`,
      answer: `The Darshanam starts in the Morning from 6:00 AM Onwards.`,
    },
    {
      question: `Is there any dress code for Darshanam?`,
      answer: `Yes. Every devotee who offers seva must wear Hindu Traditional Wear. (No modern clothes, sleeveless dress)`,
    },
    {
      question: `What is the price of Seeghra Darshanam?`,
      answer: `The ticket for Seeghra Darshanam costs Rs. 150/-.`,
    },
    {
      question: `What is the price of Athi Seeghra Darshanam?`,
      answer: `The ticket for Athi Seeghra Darshanam costs Rs. 500/-.`,
    },
    {
      question: `Where can I get the Darshanam tickets at Devasthanam?`,
      answer: `One may purchase Darshanam tickets at the Devasthanam offline counters.`,
    },
    {
      question: `What is the age limit for kids to purchase a ticket?`,
      answer: `All children above the age of 10 Years must purchase a ticket.`,
    },
    {
      question: `Will I have to carry ID proof for Darshanam?`,
      answer: `Yes, one must carry any Govt. id for Darshanam entry.`,
    },
    {
      question: `What is the process of booking an online ticket?`,
      answer: `Online tickets can be purchases via Devatshanam Official website -www.Kanipakamonline.com.`,
    },
    {
      question: `What documents or ID proofs are required for booking an online ticket for Darshanam?`,
      answer: `Any Govt. Id proof is required for booking an online ticket for Darshanam.`,
    },
    {
      question: `I don't have the online ticket receipt, what should I do?`,
      answer: `You may visit Devasthanam official website www.Kanipakamonline.com and reprint the ticket.`,
    },
    {
      question: `After booking tickets online, can I cancel the ticket?`,
      answer: `No. Once booked, the tickets can not be cancelled.`,
    },
    {
      question: `After the online booking, will I be able to change the date of Darshanam?`,
      answer: `No. Please book your dates carefully as once booked, the dates can not be changed.`,
    },
    {
      question: `May I transfer my online ticket to another person?`,
      answer: `No. Transfer of tickets to another person is not allowed.`,
    },
    {
      question: `How much time will it take for Sarva Darshanam on weekdays and weekends?`,
      answer: `It depends on the devotee count. Usually, it takes up to 1hr during weekdays and 2hrs during weekends approximately.`,
    },
    {
      question: `How much time will it take for Seeghra Darshanam on weekdays and weekends?`,
      answer: `It depends on the devotee count. On regular days it takes around 1.5 Hrs.`,
    },
    {
      question: `How much time will it take for Athi Seeghra Darshanam on weekdays and weekends?`,
      answer: `It depends on the devotee count. On regular days it takes around 30 Mins.`,
    },
    {
      question: `Is Sparsa Darshanam available if we purchase the Athi Seeghra Darshanam ticket?`,
      answer: `No. Sparsa Darshanam is not included in the Athi Seeghra Darshanam ticket.`,
    },
    {
      question: `Is there any specific difference between Seeghra and Athi Seeghra Darshanam other than timings?`,
      answer: `No. Other than the timing, there is no other difference.`,
    },
    {
      question: `May I carry items like mobile, water bottle etc, while going to Darshanam?`,
      answer: `No, carrying items like mobile, water bottle etc is not allowed.`,
    },
    {
      question: `Where should I keep my electronics safe?`,
      answer: `Please deposit your belongings at the Devasthanam clock room.`,
    },
    {
      question: `Do I have to pay fees to keep my electronics safe?`,
      answer: `No. We do not charge to keep your belongings safe.`,
    },
    {
      question: `By buying a ticket, will Devasthanam provide any Prasadam for us?`,
      answer: `No. Prasadam is available for purchase separately.`,
    },
    {
      question: `Will Devasthanam provide food or snacks in Queue, if it takes more time for Darshanam?`,
      answer: `Yes. If Darshanam takes too long than usual, we do provide snacks for the queue.`,
    },
    {
      question: `Is there any special queue for Handicapped or Postnatal women or pregnant women or aged people?`,
      answer: `:-Yes, there is a special queue dedicated to Handicapped, Postnatal women, Pregnant  Women & Aged People.`,
    },
    {
      question: `Any specific rules and regulations to follow during Darshanam?`,
      answer: `Devotees shall only enter in traditional attire to preserve the sanctity of the Temple.`,
    },
    {
      question: `For further queries and complaints, who should we contact?`,
      answer: `Devasthanam information centre employees can attend to all your queries & complaints. They shall record the calls and send them to the concerned department to serve you better.`,
    },
  ],
  donations: [
    {
      question: `What is the limit of donation?`,
      answer: `There is no specific limit to the donation amount.`,
    },
    {
      question: `What type of payments are accepted?`,
      answer: `All Debit /Credit cards, UPI, Net banking etc. are available for payments.`,
    },
    {
      question: `What if the transaction failed?`,
      answer: `Please contact the Devasthanam information centre available 24/7 to receive your complaints and suggestions. They will record your call and inform the concerned team to serve you better.`,
    },
    {
      question: `How many days will it take to refund the failed transaction amount?`,
      answer: `It takes around a minimum of 15 days to refund the amount.`,
    },
    {
      question: `Whom should we contact, if the failed transaction amount isn’t refunded?`,
      answer: `:- Please contact the Devasthanam information centre available 24/7 to receive your complaints  and suggestions. They will record your call and inform the concerned team to serve you better.`,
    },
    {
      question: `Can I donate through UPI as I don’t have an internet banking facility?`,
      answer: `Yes, You may donate UPI transaction.`,
    },
    {
      question: `Can I donate with a cheque or DD?`,
      answer: `Yes. Devasthanam accepts Cheques and DD's. For more Information contact Visit Support Section`,
    },
    {
      question: `Can I donate any assets of mine?`,
      answer: `Yes. Please contact the Executive officer to donate your assets.`,
    },
    {
      question: `Can I donate digital gold?`,
      answer: `No. We currently do not accept digital gold as a donation towards Devasthanam.`,
    },
    {
      question: `How will my donation be used?`,
      answer: `A devotee will be able to select a particular Seva or service while donating. We utilise your donation only to the Seva/service you've selected.`,
    },
    {
      question: `Can I dedicate my donation to other’s name?`,
      answer: `Yes. You may dedicate your donation to other's name.`,
    },
    {
      question: `Is my donation tax-deductible?`,
      answer: `Yes. Your donation is eligible for tax-deduction.`,
    },
    {
      question: `Can I get an 80G certificate?`,
      answer: `Yes. Once you donate, you shall receive an 80G certificate.`,
    },
    {
      question: `Do you trade my details to any other agencies?`,
      answer: `No. We take your privacy and safety seriously. We do not trade your details to any agency.`,
    },
    {
      question: `Will I get any privileges by donating?`,
      answer: `You are eligible for donor card privilege if you donate more than 1Lakh. A Donor Card  issued in the donor's name facilitates the Darshan for 5 Members.`,
    },
    {
      question: `How can I get a receipt of my donation?`,
      answer: `The donation receipt is sent to the email and a bond will be sent via Indian postal to the donor's address.`,
    },
    {
      question: `Can NRIs donate to Devasthanam?`,
      answer: `Yes. NRIs may donate to the Devasthanam.`,
    },
  ],
  e_hundi: [
    {
      question: `What is the limit of donation for e-Hundi?`,
      answer: `You may donate a Minimum of 1 Rupee to a Maximum of any amount your bank permits.`,
    },
    {
      question: `What types of payment methods are available for e-Hundi?`,
      answer: `All digital transactions are acceptable for e-Hundi donations.`,
    },
    {
      question: `What if the transaction failed?`,
      answer: `Please contact the Devasthanam information centre available 24/7 to receive your complaints and suggestions. They will record your complaint and inform the concerned team to serve you better.`,
    },
    {
      question: `How many days will it take to refund the failed transaction amount?`,
      answer: `It takes around a minimum of 15 days to refund the amount.`,
    },
    {
      question: `Whom should we contact, if the failed transaction amount isn’t refunded?`,
      answer: `Please contact the Devasthanam information centre available 24/7 to receive your complaints and suggestions. They will record your complaint and inform the concerned team to serve you better.`,
    },
    {
      question: `How can I donate gold or silver ornaments through e-Hundi?`,
      answer: `It is not possible to donate gold or silver ornaments digitally.`,
    },
    {
      question: `Is registration needed for e-Hundi?`,
      answer: `Yes. Registration is a must for e-Hundi donation.`,
    },
    {
      question: `Will I get any privileges by e-Hundi donation?`,
      answer: `There are no privileges for donor's donation via e-Hundi.`,
    },
    {
      question: `Is any receipt available on e-Hundi?`,
      answer: `Yes. The receipt will be sent to the respective Devotee’s email ID`,
    },
    {
      question: `Can I get an 80G certificate?`,
      answer: `Once you donate, you shall receive an 80G certificate.`,
    },
    {
      question: `Can I donate in some other’s name?`,
      answer: `Yes. You may donate in some other's name.`,
    },
    {
      question: `Can I get the e-Hundi financial summary?`,
      answer: `No. e-Hundi financial summary can not be viewed or claimed.`,
    },
  ],

  go_samrakshana_trust: [
    {
      question: `What is the donation limit of GoSamrakshana Trust?`,
      answer: `There is no specific limit to the donation amount.`,
    },
    {
      question: `What type of payments are accepted?`,
      answer: `All digital transactions are accepted.`,
    },
    {
      question: `Can I donate any assets?`,
      answer: `Yes. Please contact the Executive officer to donate your assets.`,
    },
    {
      question: `How many cows are there in Gosala?`,
      answer: `There are nearly 1350 Cows in Gosala.`,
    },
    {
      question: `Can I donate feed to the GoSamrakshana Trust?`,
      answer: `Yes. You may donate feed to the GoSamrakshana Trust.`,
    },
    {
      question: `Can I perform GoPuja in Gosala?`,
      answer: `Yes. You may offer a GoPuja in Gosala.`,
    },
    {
      question: `Can I book GoPuja online?`,
      answer: `Yes. You may book GoPuja via the Devasthanam website.`,
    },
    {
      question: `What is the feed given to cows at Gosala?`,
      answer: `Grass is offered to the cows as feed at Gosala.`,
    },
    {
      question: `Will you send cows for grazing outside?`,
      answer: `Yes. The cows in Gosala are free to graze outside.`,
    },
    {
      question: `Will you count the number of cows every day?`,
      answer: `Yes. We count the number of cows every day.`,
    },
    {
      question: `I would like to adopt a cow. How much do I have to donate for taking care of that cow?`,
      answer: `No. We currently do not allow the adoption of a cow.`,
    },
    {
      question: `Can I donate cows to Gosala?`,
      answer: `Yes. You may donate cows to Gosala.`,
    },
    {
      question: `If yes, how many cows can I donate to Gosala?`,
      answer: `You can donate as many cows to Gosala.`,
    },
    {
      question: `Can I donate in some other’s name?`,
      answer: `Yes. You may donate in other's name.`,
    },
    {
      question: `Will I get any privileges by donations?`,
      answer: `Yes. Some privileges are provided by the devasthanam.`,
    },
    {
      question: `How can I get a receipt of the donation?`,
      answer: `The donation receipt is sent to the donor's email.`,
    },
    {
      question: `Can NRIs donate to GoSamrakshana Trust?`,
      answer: `Yes. NRIs may donate to GoSamrakshana Trust`,
    },
    {
      question: `Can I get the GoSamrakshana Trust financial summary?`,
      answer: `No. The financial summary can not be viewed or claimed.`,
    },
    {
      question: `Are Go Products available online?`,
      answer: `Yes. Go Products are available online for purchase.`,
    },
  ],

  online_booking: [
    {
      question: `What documents need to be uploaded during online booking?`,
      answer: `Any Indian Govt.id proof is required for online booking.`,
    },
    {
      question: `What type of cards are accepted in internet banking?`,
      answer: `All Types of Debit/Credit cards are accepted.`,
    },
    {
      question: `Is there a UPI payment facility for online booking?`,
      answer: `Yes. We have provided a UPI payment facility for online booking.`,
    },
    {
      question: `Is it secure to give my personal details and my credit/debit card information for online booking/payments?`,
      answer: `Yes. It is safe to provide your personal details and credit/debit card information online booking/payments.`,
    },
    {
      question: `What if the transaction failed?`,
      answer: `Please contact the Devasthanam information centre available 24/7 to receive your complaints and suggestions. They will record your complaint and inform the concerned team to serve you better.`,
    },
    {
      question: `How many days will it take to refund the failed transaction amount?`,
      answer: `It takes around a minimum of 15 days to refund the amount.`,
    },
    {
      question: `Whom should we contact, if the failed transaction amount isn’t refunded?`,
      answer: `Please contact the Devasthanam information centre available 24/7 to receive your complaints and suggestions. They will record your complaint and inform the concerned team to serve you better`,
    },
    {
      question: `Can I cancel my ticket after online booking?`,
      answer: `No. Once booked, the tickets can not be cancelled.`,
    },
    {
      question: `How can I check my booking details?`,
      answer: `There is a user login facility available on the website. You may log in with your email ID and track your booking details.`,
    },
    {
      question: `Will I receive any confirmation through SMS or e-mail after online booking?`,
      answer: `Yes. The receipt will be generated after your transaction is completed and confirmation shall be sent via e-mail & SMS.`,
    },
    {
      question: `I didn’t receive a booking confirmation. What should I do?`,
      answer: `There is a user login facility available on the website. You may log in with your email ID and track your booking details.`,
    },
    {
      question: `Is a change in date of Darshanam/Seva/Accommodation or any online booking service possible?`,
      answer: `No. Please book your dates carefully as once booked, the dates can not be changed.`,
    },
    {
      question: `Can we book a ticket for Darshanam/Accommodation/Sevas on the same day of arrival?`,
      answer: `No, one must book tickets for Darshanam/Accommodation/Sevas at least 24 Hrs prior to arrival.`,
    },
    {
      question: `Can we make multiple bookings?`,
      answer: `Yes. You may make multiple bookings.`,
    },
    {
      question: `With one registration ID, how many tickets can I book?`,
      answer: `You may book up to 10 bookings for each service. But for Paroksha Seva, you can make unlimited bookings.`,
    },
    {
      question: `How much time will it take for booking confirmation?`,
      answer: `If the transaction is successful, the booking is confirmed immediately.`,
    },
    {
      question: `For further queries and complaints, whom should we contact?`,
      answer: `Devasthanam information centre employees can attend to all your queries & complaints. They shall record the calls and send them to the concerned department to serve you better.`,
    },
  ],
  Pranadana_trust: [
    {
      question: `What is the donation limit of Pranadana Trust?`,
      answer: `No limit.`,
    },
    {
      question: `What type of payments are accepted?`,
      answer: `All Digital transactions are accepted.`,
    },
    {
      question: `Can I donate with a cheque or DD?`,
      answer: `Yes.`,
    },
    {
      question: `Can I donate any assets?`,
      answer: `Yes.`,
    },
    {
      question: `Can I donate medicines to Pranadana Trust?`,
      answer: `Yes.`,
    },
    {
      question: `Will you do medical camps in remote areas of the forest region?`,
      answer: `Yes.`,
    },
    {
      question: `Can I donate in some other’s name?`,
      answer: `Yes.`,
    },
    {
      question: `How can I get a receipt of the donation?`,
      answer: `Respective e-Mail id will receive the receipt.`,
    },
    {
      question: `Can NRIs donate to Pranadana Trust?`,
      answer: `Yes.`,
    },
    {
      question: `Can I participate as a volunteer nurse in Pranadana Trust for a day?`,
      answer: `Yes.`,
    },
    {
      question: `How should I register as a volunteer in Pranadana Trust?`,
      answer: `You can register through the Kanipaka Devasthanam website`,
    },
    {
      question: `Can I register offline to work as a volunteer at Pranadana Trust?`,
      answer: `Yes. Consult the devasthanam administration office.`,
    },
  ],
  saswata_seva: [
    {
      question: `What is Saswata Seva?`,
      answer: `Amongst sevas performed in Kanipaka Devasthanam, a devotee can choose any seva to
    be performed on a specific date for 10 years on a chosen date.`,
    },
    {
      question: `How many years will you perform Seva on our name with Saswata Seva?`,
      answer: `saswata Seva is performed for 10 Years Only.`,
    },
    {
      question: `Will you send any Prasadam for us?`,
      answer: `Yes. Prasadam will be sent through Indian Postal to the devotee’s address.`,
    },
    {
      question: `Will we get any privilege by booking Saswata Seva?`,
      answer: `Devotees who've booked saswata Seva are eligible for donor ID privilege for 10 years
    period. A Donor Card issued in the donor's name facilitates the Darshan for 5 Members.`,
    },
    {
      question: `How will we know that you are performing Saswata Seva in our name?`,
      answer: `A notification will be sent via SMS before 1 Week.`,
    },
    {
      question: `Do I have to pay the whole amount at once?`,
      answer: `Yes. You will have to pay the whole amount in one go which will be received by
    Devasthanam.`,
    },
  ],
  SBMSD_trust: [
    {
      question: `What is the limit of donation to SBMSD Trust?`,
      answer: `There is no limit for donation to SBMSD Trust.`,
    },
    {
      question: `What type of payments are accepted?`,
      answer: `All digital transactions are acceptable.`,
    },
    {
      question: `Can I donate with a cheque or DD?`,
      answer: `Yes. We accept a cheque or a DD.`,
    },
    {
      question: `Can I donate any assets of mine?`,
      answer: `Yes. Please contact the Executive officer to donate your assets.`,
    },
    {
      question: `Can I donate digital gold?`,
      answer: `No. We currently do not accept digital gold as a donation.`,
    },
    {
      question: `Can I donate in some other’s name?`,
      answer: `Yes. You may donate in other's name.`,
    },
    {
      question: `Is my donation tax-deductible?`,
      answer: `Yes. Your donation is eligible for tax-deduction.`,
    },
    {
      question: `Can I get an 80G certificate?`,
      answer: `Yes. After you donate, you shall receive an 80G certificate.`,
    },
    {
      question: `Do you trade my details to any other agencies?`,
      answer: `No. We take your privacy and safety seriously. We do not trade your details with any agency.`,
    },
    {
      question: `Will I get any privileges by donating?`,
      answer: `You are eligible for donor card privilege if you donate more than 1Lakh. A Donor Card issued in the donor's name facilitates the Darshan for 5 Members.`,
    },
    {
      question: `How can I get a receipt of the donation?`,
      answer: `Receipt will be sent to respective Devotee's email ID.`,
    },
    {
      question: `Can NRIs donate to SBMSD Trust?`,
      answer: `Yes. NRIs may donate to SBMSD Trust.`,
    },
    {
      question: `Can I get the SBMSD Trust financial summary?`,
      answer: `No. SBMSD Trust financial summary can not be viewed or claimed`,
    },
  ],
  signin_up: [
    {
      question: `What is Kanipakam ?`,
      answer:
        "KANI” means one and a quarter land and, “PAKAM ” means the flow of water into the land i.e., irrigation.",
    },
    {
      question: `What is the age limit for registration?`,
      answer: `All people 12 years & above can register on the devasthanam website.`,
    },
    {
      question: `What should I do, if I forget my password?`,
      answer: (
        <div style={paddingStyle}>
          You can reset your password with your registered mobile number or
          e-mail ID. To reset your password, please follow the steps listed
          below:
          <br />
          Click on Forget Password on the login page
          <br />
          {`- `} Your page will be redirected to the Reset your Password screen
          <br />
          {`- `}Enter your registered email ID or mobile number
          <br />
          {`- `}Click continue
          <br />
          {`- `}A One Time Password (OTP) will be sent to your mentioned email
          ID or mobile number.
          <br />
          {`- `} Enter the OTP and click continue.
          <br />
          {`- `}You are redirected to the password reset screen.
          <br />
          {`- `}Enter your new password.
          <br />
          {`- `}Re-Enter your new password for confirmation.
          <br />
          {`- `} Click on continue to complete the process.
          <br />
          {`- `} You can now sign in with your new password.
        </div>
      ),
    },
    {
      question: `What if I forget the email address that I used for registration.?`,
      answer: `If you have an existing account, try to login with that account details. But if you forget your
    login credentials, please contact us for help locating your account sign-in information.`,
    },
    {
      question: `What if I didn’t get an OTP?`,
      answer: `Please check that you've entered the correct previously registered mobile number or
    email ID. Otherwise, check your spam or junk section of your email.`,
    },
    {
      question: `Does my email ID acts as a Username?`,
      answer: `The name you enter in the signup process will act as your username.`,
    },
    {
      question: `Do I need to provide my credit/debit card details for registration?`,
      answer: `There is no need to provide any credit/debit card details for registration.`,
    },
    {
      question: `Is registration mandatory for online booking?`,
      answer: `No. you can use the guest login.`,
    },
    {
      question: `Can I cancel my ticket, when I book a ticket through guest login?`,
      answer: `Yes, you may cancel your ticket.`,
    },
    {
      question: `After registration, will I get updates and news of Kanipaka Devasthanam regularly?`,
      answer: `Yes. You will receive updates and news of Kanipaka Devasthanam regularly.`,
    },
    {
      question: `For further quarries, whom should we contact?`,
      answer: `You may contact us via Live chat, 24/7 Customer support or through the Devasthanam
    e-mail.`,
    },
  ],
  swacha_Kanipakam: [
    {
      question: `What is the donation limit of Swachh Kanipakam Trust?`,
      answer: `There is no limit to the donation amount.`,
    },
    {
      question: `What type of payments are accepted?`,
      answer: `All digital transactions are accepted.`,
    },
    {
      question: `What if the transaction failed?`,
      answer: `Please contact the Devasthanam information centre available 24/7 to receive your complaints and suggestions. They will record your complaint and inform the concerned team to serve you better.`,
    },
    {
      question: `What are the objectives of Swachh Kanipakam?`,
      answer: `The objective of Swachh Kanipakam is to maintain the cleanliness in Kanipakam and surrounding premises.`,
    },
    {
      question: `Can I donate dustbins to Swachh Kanipakam Trust?`,
      answer: `Yes. You may donate dustbins to Swachh Kanipakam Trust.`,
    },
    {
      question: `Can I donate in some other’s name?`,
      answer: `Yes. You may donate in some other's name.`,
    },
    {
      question: `How can I get the receipt of the donation?`,
      answer: `Receipt will be sent to the respective email ID of the Devotee.`,
    },
    {
      question: `Can NRIs donate to Swachh Kanipakam Trust?`,
      answer: `Yes. NRIs may donate to Swachh Kanipakam Trust`,
    },
    {
      question: `Can I participate as a volunteer in Swachh Kanipakam Trust for a day?`,
      answer: `Yes. You may participate as a volunteer in Swachh Kanipakam Trust for a day.`,
    },
    {
      question: `How should I register as a volunteer in Swachh Kanipakam Trust?`,
      answer: `Visit Devasthanam official website www.Kanipakamdevasthanam.org to register as a volunteer.`,
    },
    {
      question: `Can I register offline to work as a volunteer at Swachh Kanipakam Trust?`,
      answer: `Yes. Consult the devasthanam administration office to register as a volunteer at Swachh Kanipakam Trust.`,
    },
    {
      question: `Can NGO’s participate in the Swachh Kanipakam campaign?`,
      answer: `Yes. NGO's can participate in Swachh Kanipakam campaign.`,
    },
  ],
  volunteer: [
    {
      question: `How to register as a volunteer?`,
      answer: `Devotees have to visit our website and go to the volunteer page. There you can register as a volunteer.`,
    },
    {
      question: `What is the age limit to register as a volunteer?`,
      answer: `18 years to 60 years.`,
    },
    {
      question: `What are the documents to be submitted to register as a volunteer?`,
      answer: `Any one of Government registered ID proofs such as Aadhar card, driving licence, voter identification card etc.`,
    },
    {
      question: `Can I register offline?`,
      answer: `Yes, You Can Register offline`,
    },
    {
      question: `Are there any accommodation facilities for volunteers?`,
      answer: `Yes. Accommodation will be provided by Devasthanam for volunteers.`,
    },
    {
      question: `What are the sectors available to work as volunteers?`,
      answer: `There are many sections for volunteers to work like Queue, AnnaPrasadam, Swacch Kanipakam etc.`,
    },
    {
      question: `Can I choose any specific sector to work as a volunteer?`,
      answer: `No. Devasthanam authority will allot the sector rotation basis.`,
    },
    {
      question: `Can we choose to work as volunteers on any specific dates?`,
      answer: `Based on availability of slots you are able to work on specific dates.`,
    },
    {
      question: `Can I work as a volunteer all over the year?`,
      answer: `No.`,
    },
    {
      question: `How can we know that Devasthanam needs a volunteer?`,
      answer: `Devasthanam website will announce the requirement of volunteer in website.`,
    },
    {
      question: `Will you provide any Identity Card for a volunteer?`,
      answer: `Yes. Devasthanam will provide the ID card for every volunteer.`,
    },
    {
      question: `What is the validity of Identity cards?`,
      answer: `It depends on the Utsavams of Kanipaka Devasthanam.`,
    },
    {
      question: `Can I withdraw my voluntary, after registration?`,
      answer: `Yes.`,
    },
    {
      question: `Can I withdraw my voluntary, after being assigned to work?`,
      answer: `No.`,
    },
    {
      question: `Can I change my voluntary service dates?`,
      answer: `Yes.`,
    },
  ],
  about: [
    {
      question: `What is Kanipakam?`,
      answer: `Ans : Kanipakam is an abode of Sri Varasiddhi Vinayaka Swamy`,
    },
    {
      question: `Where is Kanipakam?`,
      answer: `Ans : Kanipakam is located in the Irala Mandal of Chittoor district of the Indian state of Andhra Pradesh. It is at a distance of 12 Kilometers from Chittoor with coordinates 13.2776° North, 79.0355° East.`,
    },
    {
      question: `When was Kanipakam discovered?`,
      answer: `Kanipakam was discovered above 1100 years ago approximately.`,
    },
    {
      question: `What is the significance of Kanipakam?`,
      answer: `Ans : The significance of Kanipakam is mentioned above`,
    },

    // {
    //   question: `Why is Srisaila Mallikarjuna Swamy Lingam called as Maha Lingam?`,
    //   answer: `As no other Jyothirlinga temple is surrounded with 8 gateways, only Kanipakam Mallikarjuna Swamy lingam is mentioned as Mahalingam.`,
    // },
  ],
  "Kanipakam-Devasthanam": [
    {
      question: `What is Kanipakam?`,
      answer: `Kanipakam is an abode  of Sri Bhramaramba sametha Mallikarjuna Swamy who are reformations of Shakthi and Shiva respectively.`,
    },
    {
      question: `Where is Kanipakam?`,
      answer: `Kanipakam is in Kurnool district of Andhra Pradesh. It is 16.0833 North and 78.8667 East.`,
    },
    {
      question: `When was Kanipakam constructed?`,
      answer: `The temple was constructed in the early 11th century CE by the Chola king Kulothunga Chola.`,
    },
    {
      question: `Why is Kanipakam considered as best sanctified place for Shaivism and Shakthism?`,
      answer: `As Lord Shiva in Jyothirlinga form and Shakthi in Bramaramba Devi form resided under the same temple court wall like no other temple in the world, devotees consider it as most holy place of Lord Shiva and Parvathi.`,
    },
    {
      question: `How much vast the Kanipakam was at ancient time?`,
      answer: `Kanipakam has 8 gateways from ancient times. The area under these 8 gateways is considered as Kanipakam.`,
    },
    {
      question: `What is the significance of Kanipakam?`,
      answer: `Kanipakam is significant in many aspects and cannot be explained in a single line.`,
    },
    {
      question: `Why is Kanipakam called Bhukailasam?`,
      answer: `According to Puranas, Lord Shiva himself mentioned Kanipakam as his beloved place after Kailasam and Evan called Kanipakam as Bhukailasam.`,
    },
    {
      question: `Why is Kanipakam called as Siddha Kshetram?`,
      answer: `All along the ancient periods, many mystics, siddhas, yogis visited and stayed in Kanipakam. People believe that invisible siddhas still live in Kanipakam. Thus Kanipakam is called as Siddha Kshetram.`,
    },
    {
      question: `What are Mathas in Kanipakam?`,
      answer: `There are many Mathas in Kanipakam. Among them Pancha Mathas are very familiar.`,
    },
    {
      question: `Who built Mathas in Kanipakam?`,
      answer: `Maths were not built at a time. Each Matham was built by a king under his dynasty. Most of Mathas were built around the 10th and 11th century A.D..`,
    },
    {
      question: `What are special days and festivals celebrated in Kanipakam every year?`,
      answer: `Maha Shivaratri and Ugadhi are very special festivals celebrated in Kanipakam.`,
    },
    {
      question: `Is Kanipakam underwent devastation by invaders?`,
      answer: `According to history, Kanipakam was never underwent to devastation.`,
    },
    {
      question: `Did Adhi Shankaracharya visited Kanipakam?`,
      answer: `Yes.`,
    },
    {
      question: `Was Soundaryalahari written by Adhi Shankaracharya in Kanipakam?`,
      answer: `Yes.`,
    },
    // {
    //   question: `Why is Srisaila Mallikarjuna Swamy Lingam called as Maha Lingam?`,
    //   answer: `As no other Jyothirlinga temple is surrounded with 8 gateways, only Kanipakam Mallikarjuna Swamy lingam is mentioned as Mahalingam.`,
    // },
  ],
  "sanagala-basavanna": [
    {
      question: "Who is Sanagala Basavanna?",
      answer:
        "Sanagala Basavanna is the form of Lord Nandeeswara Swamy in Kanipakam.",
    },
    {
      question: "Where is Sanagala Basavanna?",
      answer:
        "Sanagala Basavanna is a big idol of Nandeeswara Swamy located in Nandhi Mandapam of Kaniapakam Temple.",
    },

    {
      question: "What is the significance of Sanagala Basavanna?",
      answer:
        "According to Puranas, Nandeeswara Swamy is mentioned as Prime Devotee of Lord Shiva and he has four feet which represents Dharma as austerity, chastity, charity and honesty. Thus by offering prayers to Sanagala Basavanna at Kanipakam provides blessings of Lord Mallikarjuna Swamy.",
    },
    {
      question: "How to visit Sanagala Basavanna?",
      answer:
        "Devotees who visit Kanipakam can make Darshanam of Sanagala Basavanna in queue way.",
    },
    {
      question: "Any special rituals to be performed here?",
      answer:
        "Priests of Kaniapakam Temple offer Abhishekam on every Tuesday and Trayodhasi thithi of every Paksha.",
    },
    {
      question: "What is the best time to visit?",
      answer:
        "There is no specific days mentioned to see Sanagala Basavanna as he resides in front of Lord Mallikarjuna Swamy at Kaniapakam Temple.",
    },
    {
      question: "Is there any dress rules to go?",
      answer:
        "Devotees has to wear traditional clothes while entering Kaniapakam Temple.",
    },
    {
      question: "What are the precautions to be taken before visiting?",
      answer:
        "Devotees must chant ‘Om Namah Sivaya’ mantra in queue and keep Vibhuti on forehead.",
    },
    {
      question: " Will devotees allowed to touch Sanagala Basavanna idol?",
      answer: "No. Devotees are not allowed to touch Sanagala Basavanna.",
    },
  ],
  "Ratnagarbha-Ganapathi": [
    {
      question: "Who is Ratnagarbha Ganapathi?",
      answer:
        "Among 52 forms of Lord Ganesh, Ratnagarbha Ganapathi is one, who resides in Main Temple of Lord Mallikarjuna Swamy at Kanipakam. Ratnagarbha Ganapathi is also well known as Chintamani Ganapathi who recalls in parents (God Shiva and Goddess Parvathi) in 32 different ways.   ",
    },
    {
      question: "Where is Ratnagarbha Ganapathi?",
      answer:
        "Ratnagarbha Ganapathi idol is located at Mukha Mandapam of Lord Mallikarjuna Swamy temple.",
    },
    {
      question: `How it formed?
    `,
      answer: `According to Mythology, Lord Ganesh after call from his parents, arrived in his truest form to Kanipakam and decided to reside here in the name of Ratnagarbha Ganapathi.`,
    },
    {
      question: `What is the significance of Ratnagarbha Ganapathi?
    `,
      answer: `According to Mantra Sastra, Ratnagarbha Ganapathi recalls his parents in 32 different ways and eliminates the troubles of his beloved devotees.`,
    },
    {
      question: `How to visit Ratnagarbha Ganapathi?
    `,
      answer: `Devotees can make Darshanam of Ratnagarbha Ganapathi while moving in the Darshanam queue of Lord Mallikarjuna Swamy.`,
    },
    {
      question: `Any special rituals to be performed here?
    `,
      answer: `Devasthanam offers special Abhishekam and Ganapathi homam.
    `,
    },
    {
      question: `Is there any dress rules to go?
    `,
      answer: `Devotees has to wear traditional clothes while entering Kaniapakam Temple.
    `,
    },
    {
      question: `What are the precautions to be taken before visiting?
    `,
      answer: ` Devotees must chant ‘Om Namah Sivaya’ mantra in queue and keep Vibhuti on forehead.`,
    },
    {
      question: `Will devotees allowed to touch Ratnagarbha Ganapathi idol?
    `,
      answer: `No. Devotees are not allowed to touch Ratnagarbha Ganapathi.`,
    },
    {
      question: `Why this idol was named as Ratnagarbha Ganapathi?`,
      answer: `People believe that there are precious stones kept in Stomach of Ganapathi idol while sculpturing it. Thus Chinthamani Ganapathi also now called as Ratnagarbha Ganapathi.`,
    },
  ],
  pratyaksha_seva: [
    {
      question: `What is Pratyaksha seva?`,
      answer: `This is seva offered to Lord Mallikarjuna Swamy and Bhramaramba Devi by devotees through Kanipaka Devasthanam.`,
    },
    {
      question: `What are the uses of Pratyaksha seva?`,
      answer: `Devotees can be blessed by Lord Shiva and Shakthi by offering Seva.`,
    },
    {
      question: `What is the difference between Paroksha seva and Pratyaksha seva?`,
      answer: `Devotees can directly participate in Seva through Pratyaksha seva whereas in Paroksha seva, devotees can participate indirectly by staying at home.`,
    },
    {
      question: `Who should attend Pratyaksha seva?`,
      answer: `Any devotee of Lord Shiva and Parvathi can attend seva.`,
    },
    {
      question: `Is there any age limit to attend Pratyaksha Seva?`,
      answer: `No.`,
    },
    {
      question: `Can unmarried persons attend Pratyaksha seva?`,
      answer: `Yes.`,
    },
    {
      question: `What are the timings of Pratyaksha seva?`,
      answer: `Based on the Seva , Pratyaksha Seva timings will differ. Seva timings are listed on the Devasthanam website..`,
    },
    {
      question: `Is there any dress code to attend Pratyaksha Seva?`,
      answer: `Yes. Every devotee who offers seva must wear Hindu Traditional Wear. (No modern clothes, sleeveless dress)`,
    },
    {
      question: `What are the rules and regulations to be followed during Pratyaksha seva?`,
      answer: `Proper attire, peaceful mind and soul is enough for offering seva.`,
    },
    {
      question: `Will Pratyaksha sevas be performed at same timings everyday?`,
      answer: `Yes, but varies during special dates like festivals.`,
    },
    {
      question: `What is the reporting time for Pratyaksha Seva?`,
      answer: `Devotee should report 15mins prior any seva.`,
    },
    {
      question: `Will you perform Pratyaksha seva on auspicious and festival days?`,
      answer: `Yes.`,
    },
    {
      question: `If yes, any change of timings in performing Pratyaksha seva?`,
      answer: `Yes. Timings may vary.`,
    },
    {
      question: `Can we book Pratyaksha seva online?`,
      answer: `Yes.`,
    },
    {
      question: `What are the proofs to be submitted to book Pratyaksha seva?`,
      answer: `Any Govt.ID is required to book a Paroksha seva.`,
    },
    {
      question: `How prior can we book Pratyaksha seva?`,
      answer: `Just on the day of seva.`,
    },
    {
      question: `What is the cut off time to book Pratyaksha seva?`,
      answer: `Devotee can book prior one hour of seva.`,
    },
    {
      question: `Can we attend Paroksha seva by booking ticket for Pratyaksha seva?`,
      answer: `No. Pratyaksha Seva ticket holders are allowed to utilise the ticket only for Pratyaksha sevas.`,
    },
    {
      question: `Will you perform two or more sevas at the same time?`,
      answer: `No.`,
    },
    {
      question: `Are there any offers available, if we book more sevas?`,
      answer: `No.`,
    },
    {
      question: `How many persons are allowed to attend Pratyaksha seva on one ticket?`,
      answer: `A single ticket is valid only for a single person.`,
    },
    {
      question: `Will you perform seva to each individual separately?`,
      answer: `No. We perform Samuhika seva.`,
    },
    {
      question: `What if, Sevadhari is non Telugu speaking person?`,
      answer: `No issues. Our priests will explain in the way devotees understand.`,
    },
    {
      question: `Can Sevadhari adopt native language translator while performing Seva?`,
      answer: `No.`,
    },
    {
      question: `Will you provide Prasadam for Pratyaksha seva attendees?`,
      answer: `Yes.`,
    },
    {
      question: `What Prasadam will be given by you for Pratyaksha seva attendees?`,
      answer: `It varies with seva offered by devotee.`,
    },
    {
      question: `Are there limited tickets for Pratyaksha seva?`,
      answer: `No.`,
    },
    {
      question: `Can we cancel the Pratyaksha seva ticket?`,
      answer: `No.`,
    },
    { question: `Can we transfer our ticket to other person?`, answer: `No.` },
    {
      question: `Can we change our Pratyaksha seva date?`,
      answer: `No.`,
    },
    {
      question: `If any queries and doubts, whom should we contact?`,
      answer: `Contact information centre at Kaniapakam Temple or contact us by calling to our information centre.`,
    },
  ],
  media_room: [
    {
      question: `What is a media room?`,
      answer: `The media room is where devotees can find additional information about happenings in Kanipakam, press reports, gallery and other information.`,
    },
    {
      question: `Can I download the files in the media room?`,
      answer: `Kanipaka Devasthanam is the sole owner of all the content on the website. Downloading or using the content for commercial purposes on our website is strictly prohibited. However, devotees can download the files for non-commercial or devotional purposes.`,
    },
    {
      question: `I am a Photographer and I want to donate my artwork to Kanipakam. How can I submit my photographs?`,
      answer: `We accept photographs, videos and artwork from professionals only. You may send your work for donation via email @endow-eosri@gov.in. After review, If your artwork/photographs/videos are up to our standards, we shall publish them on our website with due credits to the artist.`,
    },
    {
      question: `How can I subscribe to e-publications?`,
      answer: `You can subscribe to e-publications by registering and paying the prescribed amount of your subscription period.`,
    },
    {
      question: `How can I receive updates on Kanipakam via email?`,
      answer: `Please subscribe to our newsletter via our website. By doing so, you shall receive all the valuable communication via email.`,
    },
    {
      question: `Do you give us a Kanipakam diary and calendar if we pay an offline/online subscription to Srisaila Prabha?`,
      answer: `No. You should purchase them separately.`,
    },
    {
      question: `How to send photo/video/movie shooting requests on the premises of Kanipakam?`,
      answer: `All photo/video sessions or movie shooting requests should be sent a minimum of 15 days before by contacting administration department of Kanipaka Devasthanam`,
    },
    // {
    //   question: `Where can I register for Tenders?`,
    //   answer: `Click the new user icon on the homepage to register under the member's login section.`,
    // },
    {
      question: `From where can I download information and details about specific tenders?`,
      answer: `Click on your selected tender, you will go to that specific page from where you can download tender details.`,
    },
    {
      question: `Is there a helpline or email address for queries or concerns regarding the media room of Kanipakam?`,
      answer: `Please contact our customer support for further quarries.`,
    },
  ],
  shop: [
    {
      question: `How do I place an order?`,
      answer: `Browse the products here and click on the product you like. 'Add to cart' and proceed to pay to place orders online.`,
    },
    {
      question: `What is your return policy?`,
      answer: `Once ordered you may not return any products purchased.`,
    },
    {
      question: `What payment modes do you accept?`,
      answer: `We accept all credit/debit cards and net banking when checking out over the phone or online via our secure server.`,
    },
    {
      question: `How do I cancel my order?`,
      answer: `You may not change or cancel an order once placed.`,
    },
    {
      question: `It's been a long since I've ordered and my order hasn't been shipped?`,
      answer: `We try to ship items as soon as possible after ordering. However, Keep your order number ready before you call our helpline- +91-08573 281747, 281540 .`,
    },
    {
      question: `What should I do if products disappear from my cart?`,
      answer: `If products are disappearing from your cart, it may be a problem with your browser's cookies. Please make sure you have your browser set to accept cookies.`,
    },
    {
      question: `Can you fast track my order?`,
      answer: `Unfortunately, we are unable to prioritise deliveries. However, we will make every effort to deliver your purchased product within 5 to 15 working days to the address specified by you during the purchase process.`,
    },
    {
      question: `I tracked my order and it says 'delivered' but I have not received it?`,
      answer: `We ship purchased products to the address provided by the devotee. Please contact the courier partner if our product has not been delivered.`,
    },
    {
      question: `An item is missing from my order?`,
      answer: `Please double-check the invoice contained within your package and ensure all items were successfully ordered. If the invoice confirms the item has been successfully ordered and invoiced however you have not received it, please email @endow-eosri@gov.in or phone +91-08573 281747, 281540 for assistance.`,
    },
    {
      question: `I received an item that is faulty?`,
      answer: `We are committed to providing you with quality products. In the unlikely event you believe the goods to be faulty, please contact our customer support team.`,
    },
    {
      question: `Can I track my order?`,
      answer: `From the ‘Orders’ tab on the website, tap on your order to view its status.`,
    },
    {
      question: `Where can I lodge a complaint or feedback?`,
      answer: `You can give feedback through our feedback form on the website. Contact customer support team brought email or call for further details and complaints.`,
    },
  ],
  cart: [
    {
      question: `How many products can I put in my Cart?`,
      answer: `You can add as many products to your cart.`,
    },
    {
      question: `I put a few products in my cart. They’re not in my cart anymore! What happened?`,
      answer: `Maybe the products are not available anymore or might be a technical glitch. Please contact our helpline -+91-08573 281747, 281540.`,
    },
    {
      question: `What if an item is out of stock?`,
      answer: `We make every effort to keep all products in stock. However, there may be times when unexpected demands exceed our supply. If you place an order for an item that is not in stock, we will inform you through an email and credit your amount within 5-7 business days of purchase.`,
    },
    {
      question: `I want to be notified about the out of stock product in my cart. How?`,
      answer: `Please put the item in save for a later section. When the item is back in stock are allowed to place a new order for this product.`,
    },
    {
      question: `Do I have to sign up to buy something?`,
      answer: `You do not have to create an account when you place an order.`,
    },
    {
      question: `Will everything in my cart ship together?`,
      answer: `Yes. All items in your cart will be shipped together.`,
    },
    {
      question: `Can I ship different items in my cart to different shipping addresses?`,
      answer: `We can only process one shipping address per order. So, if you would like to order several items and ship them to different people, please treat these as separate orders.`,
    },
    {
      question: `Whom should I contact if I have problems with my cart?`,
      answer: `If you have any problems or concerns about your items in your cart, please contact our customer support team.`,
    },
  ],
  support: [
    {
      question: `Do you offer phone support?`,
      answer: `Yes, please ring us at +91-08573 281747, 281540 for support.`,
    },
    {
      question: `Do you offer email support?`,
      answer: `Yes. Mail us to endow-eosri@gov.in for support.`,
    },
    {
      question: `Do you offer chat support?`,
      answer: `Yes, connect with our friendly chat assistant by clicking on chat bot.`,
    },
    {
      question: `It is our first time to Kanipakam, can we be assigned a support specialist or a guide for making our visit easy?`,
      answer: `We provide detailed information to devotees at Information counter beside main temple of Kanipakam.`,
    },
    {
      question: `Is Kanipakam well connected in case of a medical emergency?`,
      answer: `Yes. Kanipaka Devasthanam organises a hospital for any medical emergency.`,
    },
    {
      question: `Where is the nearest airport?`,
      answer: `Hyderabad is the nearest airport to reach Kanipakam at a distance of 230kms.`,
    },
    {
      question: `Are cab services available at Kanipakam?`,
      answer: `Yes, there are plenty of cabs, buses and other public transport facilities.`,
    },
    {
      question: `When to contact your office?`,
      answer: `Everyday, from 09:00 a.m to 05:00 p.m, our staff are available at Temple office.`,
    },
    {
      question: `Are tempo vehicles (12,13 Seaters) and other state vehicles allowed on the ghat road?`,
      answer: `Yes.`,
    },
    {
      question: `How to participate in cultural programmes during festivities?`,
      answer: `Pilgrims who are interested to perform should contact temple office located in Kanipakam prior 30 days of celebrations.`,
    },
    {
      question: `Do you have Kanipaka Devasthanam run cloakrooms for devotees?`,
      answer: `Yes.`,
    },
    {
      question: `Is Kanipakam well facilitated for a month stay?`,
      answer: `Yes, you may stay in Kanipakam for more than a month comfortably as it has accommodation with all basic facilities, numerous food joints, shopping markets, hospitals, schools, etc.`,
    },
    {
      question: `I have other questions, whom should I contact?`,
      answer: `For further details contact our customer support team.`,
    },
  ],
  search: [
    {
      question: `Which deities are worshipped in Kanipakam?`,
      answer: `Sri Mallikarjuna Swamy and Bhramarambika devi are the main deitties worshiped in Kanipakam.`,
    },
    {
      question: `Is Photography allowed inside the temple?`,
      answer: `No, photography is prohibited inside the temple.`,
    },
    {
      question: `I see devotees bringing fruits/milk and placing it on the platform near the Deities. What does it mean?`,
      answer: `It is called Naivedyam, a token of gratitude to God.`,
    },
    {
      question: `What is the dress code of the temple?`,
      answer: `Yes. Every devotee who offers seva must wear Hindu Traditional Wear. (No modern clothes, sleeveless dress)`,
    },
    {
      question: `I see people walking in the prayer hall in a clockwise direction. What does it mean?`,
      answer: `It is called Circumambulation, a way to offer his/her prayers to his/her deity.`,
    },
  ],
  sevas_darshanam: [
    {
      question: `Is there any dress code for Darshanam?`,
      answer: `Yes. Every devotee who offers seva must wear Hindu Traditional Wear. (No modern clothes, sleeveless dress)`,
    },
    {
      question: `What is the price of Seeghra Darshanam?`,
      answer: `The ticket for Seeghra Darshanam costs Rs. 150/-.`,
    },
    {
      question: `What is the price of Athi Seeghra Darshanam?`,
      answer: `The ticket for Athi Seeghra Darshanam costs Rs. 500/-.`,
    },
    {
      question: `Where can I get the Darshanam tickets at Devasthanam?`,
      answer: `One may purchase Darshanam tickets at the Devasthanam offline counters.`,
    },
    {
      question: `What is the age limit for kids to purchase a ticket?`,
      answer: `All children above the age of 10 Years must purchase a ticket.`,
    },
    {
      question: `Will I have to carry ID proof for Darshanam?`,
      answer: `Yes, one must carry any Govt. id for Darshanam entry.`,
    },
    {
      question: `Can we attend Pratyaksha Seva by booking a ticket for Paroksha Seva?`,
      answer: `Pratyaksha Seva ticket holders are allowed to utilise the ticket only for Pratyaksha Sevas.`,
    },
    {
      question: `What is the difference between Paroksha seva and Pratyaksha Seva?`,
      answer: `Paroksha seva is for those who are unable to participate in Sevas directly. Devasthanam facilitates the devotee participation in the Seva virtually. For those who can visit the Devasthanam, they will be able to participate in the Seva directly at the temple.`,
    },
    {
      question: `What is the significance of Paroksha Seva?`,
      answer: `Paroksha Seva is for those who are unable to participate in Sevas directly. Devasthanam facilitates the devotee participation in the Seva virtually.`,
    },
    {
      question: ` How to book Paroksha seva? `,
      answer: `You may book Paroksha Seva via Devasthanam official website www.srisailadevasthanam.org, on the Paroksha Seva page. `,
    },
    {
      question: `Can we book Paroksha seva offline? `,
      answer: ` No. Paroksha Seva is an online service.`,
    },
    {
      question: `What are the proofs to be submitted to book Paroksha Seva?`,
      answer: `There is no need to submit any proof to book a Paroksha Seva. `,
    },
    {
      question: `How prior can we book Paroksha Seva?`,
      answer: `One may book Paroksha Seva 15 Days prior.`,
    },
    {
      question: `What are the timings of Pratyaksha seva?`,
      answer: `Based on the Seva , Pratyaksha Seva timings will differ. Seva timings are listed on the Devasthanam website..`,
    },
    {
      question: `Is there any dress code to attend Pratyaksha Seva?`,
      answer: `Yes. Every devotee who offers seva must wear Hindu Traditional Wear. (No modern clothes, sleeveless dress)`,
    },
    {
      question: `What are the rules and regulations to be followed during Pratyaksha seva?`,
      answer: `Proper attire, peaceful mind and soul is enough for offering seva.`,
    },
    {
      question: `Will Pratyaksha sevas be performed at same timings everyday?`,
      answer: `Yes, but varies during special dates like festivals.`,
    },
  ],
};
