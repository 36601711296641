import React, { useEffect, useState } from "react";
import {
  ganapathi,
  homam,
  orange_arrow,
  SD_Right_Arrow_white,
  SD_srisaila_tv,
  seshavaham,
  seva_and_darshanam,
} from "../../../Assets/imageList";
import "../../../Utils/sd-styles/sd-main-sup.scss";
import Iframe from "react-iframe";
import YoutubeService from "../../../services/sd-youtube.service";
const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];

const TemplateThree = () => {
  //-------------------------------------------------------------states--------------------------------------------------------//
  const [selectedlanguage, setSelectedlanguage] = React.useState("en-in");

  const [isYoutubeLive, setIsYoutubeLive] = useState(true);
  //-------------------------------------------------------------states--------------------------------------------------------//

  //-------------------------------------------------------------useEffects--------------------------------------------------------//

  useEffect(async () => {
    if (
      supportedLanguages.includes(
        history?.location?.pathname?.split?.("/")?.[1]
      )
    ) {
      setSelectedlanguage(history?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
    // try {
    //   const videoId = await YoutubeService.getHomePageId();
    //   // console.log(videoId);
    //   setIsYoutubeLive(videoId);
    // } catch (error) {
    //   setIsYoutubeLive(false);
    //   // console.log(`problem getting youtube video id`);
    // }
  }, []);

  //-------------------------------------------------------------useEffects--------------------------------------------------------//

  return (
    <div className="sd-template-three">
      <div className="sd-t3-one">
        <img src={ganapathi} width="100%" />
      </div>
      <div className="sd-t3-two">
        {" "}
        <div className="sd-box-one ">
          <div className="sd-box-one-image">
            <img
              src={homam}
              alt="SD_parokshaseva"
              className="sd-logo-img sd-size-four"
            />
          </div>
          <div className="sd-box-one-content">
            <h2>Paroksha Seva</h2>
            <p>
              In a world, that's filled with the essence of Ganesha, one can now
              wholeheartedly worship Swayambu Sri Varasidhi Vinayaka Swamy from
              anywhere in the world...
            </p>

            <button
              className="sd-b-orange"
              onClick={() => {
                // if (localStorage.getItem("accessToken")) {
                //   history?.push(
                //     `/${selectedlanguage}/devotee-app/online-booking/paroksha-seva`
                //   );
                // } else {
                //   history?.push(
                //     `/${selectedlanguage}/devotee/signin?to=devotee-app/online-booking/paroksha-seva`
                //   );
                // }
              }}
            >
              Book Now <img src={orange_arrow} alt=">" />
            </button>
          </div>
        </div>
      </div>
      <div className="sd-t3-three">
        {" "}
        <div className="sd-box-one ">
          <div className="sd-box-one-image">
            <img
              src={seshavaham}
              alt="SD_parokshaseva"
              className="sd-logo-img sd-size-four"
            />
          </div>
          <div className="sd-box-one-content">
            <h2>Live TV</h2>
            <p>
              Kanipakam Official is a Youtube Channel of Sri Kanipaka
              Devasthanam where the live telecast of various Sevas will be
              available to the devotees all over the world...
            </p>

            <button
              className="sd-b-orange"
              onClick={() => {
                history?.push(
                  `/${selectedlanguage}/devotee/signin?to=devotee-app/online-booking/paroksha-seva`
                );
              }}
            >
              Watch Now <img src={orange_arrow} alt=">" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TemplateThree;
