import axios from "axios";

export const reportsUrl =
  process.env.REACT_APP_REPORTING_API_URL ??
  "https://reporting-api.aptemples.org/api/";

const ReportingAxios = axios.create({
  baseURL: reportsUrl,
});

ReportingAxios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    console.log(originalRequest.url);
    // Prevent infinite loops
    if (
      error.response.status === 401 &&
      (originalRequest.url === "/user/refresh" ||
        originalRequest.url === "/user/login" ||
        originalRequest.url === "/user/logout" ||
        originalRequest.url === "/user/guest-login" ||
        originalRequest.url === "/user/login/phone-otp" ||
        originalRequest.url === "user/refresh" ||
        originalRequest.url === "user/login" ||
        originalRequest.url === "user/logout" ||
        originalRequest.url === "user/guest-login" ||
        originalRequest.url === "user/login/phone-otp")
    ) {
      if (originalRequest.url === "/user/logout") {
        localStorage.clear();
        return Promise.resolve();
      }
      return Promise.reject(error);
    }

    if (error.response.status === 401) {
      const refreshToken = localStorage.getItem("refreshToken");

      if (refreshToken) {
        try {
          const resp = await ReportingAxios.post(
            "user/refresh",
            {},
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("refreshToken")}`,
                "X-Temple-Code": process.env.REACT_APP_TEMPLE ?? "SSVVSD",
              },
            }
          );
          localStorage.setItem("accessToken", resp.data.accessToken);

          originalRequest.headers[
            "Authorization"
          ] = `Bearer ${localStorage.getItem("accessToken")}`;

          return ReportingAxios(originalRequest);
        } catch (e) {
          if (error.response.status === 401) {
            return Promise.reject(error);
          }
        }
      } else {
        return Promise.reject(error);
      }
    }
    // specific error handling done elsewhere
    return Promise.reject(error);
  }
);

export default ReportingAxios;
