import React from "react";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import {
  SD_Right_Arrow_Blue,
  SD_Right_Arrow_white,
  sd_rupee,
  sd_share,
  sd_wishlist_heart_active,
  sd_wishlist_heart_unactive,
} from "../../../../Assets/imageList";
import { SD_Context } from "../../../../context/sd-context";
// import IndiaTime from "../../../Utils/sd-moment/IndiaTime";
import "../../../../Utils/sd-styles/pratyakshaSeva.scss";

const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];

const SD_Pratyaksha_Seva_Inner_Component_Two = ({ first, history, data }) => {
  //-------------------------------------------------------------states-----------------------------------------------------------//
  const [selectedlanguage, setSelectedlanguage] = React.useState("en-in");
  const [copyFlag, setCopyFlag] = React.useState(false);
  const { fav, addFavorite, removeFavorite } = React.useContext(SD_Context);
  //-------------------------------------------------------------states-----------------------------------------------------------//

  //-------------------------------------------------------------useeffects-----------------------------------------------------------//
  React.useEffect(() => {
    if (
      supportedLanguages.includes(
        history?.location?.pathname?.split?.("/")?.[1]
      )
    ) {
      setSelectedlanguage(history?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
  }, []);
  //-------------------------------------------------------------useeffects-----------------------------------------------------------//

  //-------------------------------------------------------------functions-----------------------------------------------------------//
  const onShareFunction = (path) => {
    setCopyFlag(true);
    navigator.clipboard.writeText(path);
    setTimeout(() => {
      setCopyFlag(false);
    }, 2000);
  };
  const getImage = () => {
    if (localStorage.getItem("accessToken")) {
      let res = fav?.find(
        (item) => item.item_id === data.id && item.type === "pratyakshaSeva"
      );
      if (res) {
        return (
          <img
            className="border-l"
            src={sd_wishlist_heart_active}
            alt="active"
            onClick={() => {
              removeFavorite(res.id);
            }}
          />
        );
      } else {
        return (
          <img
            className="border-l"
            src={sd_wishlist_heart_unactive}
            alt="unactive"
            onClick={async () => {
              let resp = await addFavorite(data, "pratyakshaSeva");
              if (resp === "redirect") {
                history?.push(
                  `/${selectedlanguage}/devotee/signin?to=sevas-and-darshanam/pratyaksha-seva`
                );
              }
            }}
          />
        );
      }
    } else {
      return (
        <img
          className="border-l"
          src={sd_wishlist_heart_unactive}
          alt="unactive"
          onClick={async () => {
            history?.push(
              `/${selectedlanguage}/devotee/signin?to=sevas-and-darshanam/pratyaksha-seva`
            );
          }}
        />
      );
    }
  };
  //-------------------------------------------------------------functions-----------------------------------------------------------//
  return (
    <div className={`sd-pratyaksha-seva-div `}>
      <div
        id="#image"
        className="sd-pratyaksha-seva-inner-left"
        style={{
          backgroundImage: `url(${data.image_url})`,
          // backgroundPosition: "center",
          backgroundRepeat: "none",
          backgroundSize: "250px 100%",
        }}
      >
        <img src={data.image_url} />
      </div>
      <div className="sd-pratyaksha-seva-inner-middle">
        <div className="sd-pratyaksha-seva-inner-middle-top">
          <h4 className="border-l">{data?.name}</h4>
          <div>
            <img
              className="padding-r border-l"
              src={sd_share}
              alt="share"
              onClick={() => {
                onShareFunction(
                  `https://srikanipakadevasthanam.org/${selectedlanguage}/sevas-and-darshanam/paroksha-seva/${data?.name
                    ?.replace?.(/\s/g, "-")
                    ?.replace?.(/[{()}]/g, "")

                    .toLowerCase()}`
                );
              }}
            />
            {getImage()}
          </div>
        </div>
        <div className="sd-pratyaksha-seva-inner-middle-bottom">
          <div>
            <h5> {data.description} </h5>
          </div>
        </div>
      </div>
      <div className="sd-pratyaksha-seva-inner-right">
        <div className="sd-timings">
          <h3></h3>
        </div>
        <p>
          <img src={sd_rupee} alt="Rs." />
          {data.price}
        </p>
        {!data?.disabled && (
          <button
            onClick={() => {
              if (data?.disabled) {
                toast.info(
                  `We are sorry to say that Pratyaksha sevas is temporarily unavailable due to Covid-19. We regret the inconvenience.`
                );
              } else {
                if (localStorage.getItem("accessToken")) {
                  history?.push(
                    `/${selectedlanguage}/devotee-app/online-booking/pratyaksha-seva/booking/${data?.name
                      ?.replace?.(/\s/g, "-")
                      .toLowerCase()}`
                  );
                } else {
                  history?.push(
                    `/${selectedlanguage}/devotee/signin?to=devotee-app/online-booking/pratyaksha-seva/booking/${data?.name
                      ?.replace?.(/\s/g, "-")
                      .toLowerCase()}`
                  );
                }
              }
            }}
          >
            Book Now <img className="imgs" src={SD_Right_Arrow_white} alt=">" />
          </button>
        )}
      </div>
      {copyFlag && <div className="sd-copy">Link Copied</div>}{" "}
    </div>
  );
};
export default withRouter(SD_Pratyaksha_Seva_Inner_Component_Two);
