import React, { useEffect, useContext, useState, useRef } from "react";
import { toast } from "react-toastify";
import {
  sd_btn_loading,
  sd_dashboard_dob,
  SD_Right_Arrow_white,
  sd_script,
} from "../../../Assets/imageList";

import { SD_Context } from "../../../context/sd-context";
import "../../../Utils/sd-styles/sd-css/main.css";
import "react-toastify/dist/ReactToastify.css";
import Sd_loading from "../../../Components/sd-common-components/sd-loading";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../../Utils/sd-styles/sd-datepicker.scss";
import { withRouter } from "react-router";
import moment from "moment";
import ParokshaSevaService from "../../../services/sd-parokshaseva.service";
import Sd_Darshanam_service from "../../../services/sd-darshanam.service";
import { getMonth, getYear } from "date-fns";
import PhoneInput from "react-phone-input-2";
const Sd_dashboard_online_booking_darshanam = ({ history }) => {
  const {
    setActive,
    setActivePath,
    currentBookingDarshananam,
    setcurrentBookingDarshananam,
    devoteeList,
    userData,
    logout,
  } = useContext(SD_Context);
  //-------------------------------------------------------------states--------------------------------------------------------//

  const [mobile, setMobile] = useState("91");
  const [darshanamType, setDarshanamType] = useState("");
  const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];
  const [loading, setloading] = useState(true);
  const [noPersons, setNoperons] = useState(1);
  const [selectedLanguage, setSelectedlanguage] = React.useState("en-in");
  const [startDate, setStartDate] = useState("");
  const ref = useRef(null);
  const [slotsData, setSlotsData] = useState([]);
  const [autoFill, setAutoFill] = useState("none");
  const [personStates, setPersonStates] = useState([
    {
      id: 1,
      name: "",
      idProofType: "",
      idProofNumber: "",
      gender: "",
      dob: "",
      // docFileUrl: "",
    },
  ]);
  const [err, setErr] = useState([]);
  const [darshanams, setDarshanams] = useState([]);
  const [slot, setSlot] = useState("");
  const [slotLoading, setSlotLoading] = useState(false);
  const termsList = [
    "All the Pilgrims in group ticket have to report together.",
    "At the time of entry, all pilgrims shall produce the same original Photo ID (Aadhar Card) used during booking.",
    "The pilgrims shall wear Traditional Dress only. Male: Dhoti, Shirt / Kurtha, Pyjama. Female: Saree / Half Saree / Chudidar with Dupatta.",
    "Reporting and late reporting of Darshanam (after 1 1/2 hour only on booked slot).",
    "The pilgrims should not carry any luggage/cell phones/electronic gadgets while reporting.",
    "All bookings are FINAL: Postponement/advancement/cancellation/refund is not allowed.",
    "Entry with foot wear is not permitted into the queue lines, mada streets and Temple.",
    "SSVVSD reserves the right of cancellation of Darshan under Special Circumstances.",
    "Please contact our 24/7 help desk for queries at +91 - 08573 281747,281540",
  ];
  const templeID = process.env.REACT_APP_TEMPLE ?? "SSVVSD";
  const [highlightDates, setHighlightDates] = useState([]);
  const [excludeDates, setExcludeDates] = useState([]);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  //-------------------------------------------------------------states--------------------------------------------------------//

  //-------------------------------------------------------------useeffect--------------------------------------------------------//

  useEffect(() => {
    setActive("online-booking-darshanam");
    setActivePath("Onlinebooking-Darshanam");
    let path = new URLSearchParams(history?.location?.search).get("state");
    if (path === "clear") {
      setcurrentBookingDarshananam(null);
    }
    if (
      supportedLanguages.includes(
        history?.location?.pathname?.split?.("/")?.[1]
      )
    ) {
      setSelectedlanguage(history?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
    getData();
  }, []);

  useEffect(() => {
    listFunction();
  }, [noPersons]);
  //-------------------------------------------------------------useeffect--------------------------------------------------------//

  //-------------------------------------------------------------functions--------------------------------------------------------//

  const range = (start, end) => {
    var ans = [];
    for (let i = start; i <= end; i++) {
      ans.push(i);
    }
    return ans;
  };
  const getData = async () => {
    try {
      let res = await Sd_Darshanam_service.getAllvalidOnline(templeID);
      setDarshanams(res);
      if (res?.filter((e) => !e?.disabled)) {
        history?.push(
          `/${selectedLanguage}/devotee-app/online-booking/darshanam?state=clear`
        );
      }
      if (currentBookingDarshananam) {
        setNoperons(currentBookingDarshananam?.noPersons);
        setStartDate(currentBookingDarshananam?.startDate);
        setDarshanamType(currentBookingDarshananam?.darshanamType);
        setSlot(currentBookingDarshananam?.slot);
        setSlotsData(currentBookingDarshananam?.slotsData);
        setTimeout(() => {
          setPersonStates([...currentBookingDarshananam?.personStates]);
        }, 500);
        setMobile(currentBookingDarshananam?.mobile);
        setExcludeDates([]);
        setHighlightDates([]);
        getQuota(currentBookingDarshananam?.darshanamType);
      }
      setloading(false);
    } catch (e) {
      toast.warning(e.message);
      if (e.message === "Session Expired") {
        logout();

        history?.push(
          `/${selectedLanguage}/devotee/signin?to=devotee-app/online-booking/darshanam?state=clear`
        );
      }
      setloading(false);
    }
  };

  const getDates = (startDate, endDate, quotaDates, notAvailableDates) => {
    let date = startDate;
    let dates = [];
    while (date <= endDate) {
      if (
        !quotaDates?.includes(moment(date, "YYYY-MM-DD").format("YYYY-MM-DD"))
      ) {
        dates.push(new Date(date));
      }
      date = moment(date, "YYYY-MM-DD").add(1, "days").format("YYYY-MM-DD");
    }
    setExcludeDates([...notAvailableDates, ...dates]);
  };

  const getQuota = async (data) => {
    setSlotLoading(true);
    let quotaDates = [];
    let availableDates = [];
    let availableDatesString = [];
    let almostBookedDates = [];
    let almostBookedDatesString = [];
    let notAvailableDates = [];
    try {
      let res = await Sd_Darshanam_service.getDailyQuota(
        moment().add(data?.minOnlineHours, "hours").format("YYYY-MM-DD"),
        moment().add(data?.maxOnlineHours, "hours").format("YYYY-MM-DD"),
        Number(data?.id),
        data?.onlineEnabled,
        templeID
      );
      setSlotsData(res);
      res?.map((item) => {
        quotaDates.push(item?.date);
        if (Number(item?.availableQuantity / item?.quantity) * 100 > 50) {
          availableDates.push(new Date(item?.date));
          availableDatesString.push(new Date(item?.date).toString());
        } else if (
          Number(item?.availableQuantity / item?.quantity) * 100 <= 50 &&
          Number(item?.availableQuantity / item?.quantity) * 100 > 0
        ) {
          almostBookedDates.push(new Date(item?.date));
          almostBookedDatesString.push(new Date(item?.date).toString());
        } else {
          if (!availableDatesString.includes(new Date(item?.date).toString())) {
            if (
              !almostBookedDatesString.includes(new Date(item?.date).toString())
            ) {
              notAvailableDates.push(new Date(item?.date));
            }
          }
        }
      });
      notAvailableDates?.map((date) => {
        if (
          availableDatesString.includes(date.toString()) ||
          almostBookedDatesString.includes(date.toString())
        ) {
          delete notAvailableDates[notAvailableDates.indexOf(date)];
        }
      });
      setHighlightDates([
        { "react-datepicker__day--highlighted-custom-1": availableDates },
        { "react-datepicker__day--highlighted-custom-2": almostBookedDates },
      ]);
      getDates(
        moment().add(data?.minOnlineHours, "hours").format("YYYY-MM-DD"),
        moment().add(data?.maxOnlineHours, "hours").format("YYYY-MM-DD"),
        quotaDates,
        notAvailableDates?.filter((item) => item)
      );
      setSlotLoading(false);
    } catch (e) {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });

      toast.error(e.message);
      if (e.message === "Session Expired") {
        logout();

        history?.push(
          `/${selectedLanguage}/devotee/signin?to=devotee-app/online-booking/darshanam?state=clear`
        );
      }
      setSlotLoading(false);

      return;
    }
  };

  const handleSubmit = async () => {
    setloading(true);
    let a = true;
    if (a) {
      let data = [...err];
      if (startDate === "") {
        data.push({ type: "date", msg: "Please select a date" });
      }
      if (darshanamType === "") {
        data.push({ type: "type", msg: "Please select a darshanam type" });
      }
      if (slot === "") {
        data.push({ type: "slot", msg: "Please select a slot" });
      }
      if (mobile.substring(0, 2) === "91") {
        if (mobile.length !== 12) {
          data.push({ type: "phone", msg: "Invalid mobile number" });
        }
      } else {
        if (mobile.length < 8) {
          data.push({ type: "phone", msg: "Invalid mobile number" });
        }
      }
      personStates.map((item) => {
        for (var key of Object.keys(item)) {
          if (item[key] === "") {
            if (key === "idProofType") {
              data.push({
                type: `${item["id"]}-${key}`,
                msg: `Select a id Proof`,
              });
            } else if (key === "idProofNumber") {
              data.push({
                type: `${item["id"]}-${key}`,
                msg: `Invalid id number`,
              });
            } else {
              data.push({
                type: `${item["id"]}-${key}`,
                msg: `Invalid ${key}`,
              });
            }
          } else {
            if (key === "name") {
              if (item["name"].length < 3) {
                data.push({
                  type: `${item["id"]}-name`,
                  msg: "Minimun 3 characters",
                });
              }
            }

            if (key === "idProofType") {
              if (item["idProofNumber"] !== "") {
                if (item["idProofType"] === "pan") {
                  let txt = item["idProofNumber"].toUpperCase();
                  let regex =
                    /[a-zA-Z]{3}[PCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/;
                  let pan = {
                    C: "Company",
                    P: "Personal",
                    H: "Hindu Undivided Family (HUF)",
                    F: "Firm",
                    A: "Association of Persons (AOP)",
                    T: "AOP (Trust)",
                    B: "Body of Individuals (BOI)",
                    L: "Local Authority",
                    J: "Artificial Juridical Person",
                    G: "Govt",
                  };
                  pan = pan[txt[3]];
                  if (regex.test(txt)) {
                    if (pan !== "Personal") {
                      data.push({
                        type: `${item["id"]}-idProofNumber`,
                        msg: "Invalid Pan Number",
                      });
                    }
                  } else
                    data.push({
                      type: `${item["id"]}-idProofNumber`,
                      msg: "Invalid Pan Number",
                    });
                } else if (item["idProofType"] === "ration") {
                  let regex = /^([a-zA-Z0-9]){8,12}\s*$/;
                  if (!regex.test(item["idProofNumber"].toUpperCase())) {
                    data.push({
                      type: `${item["id"]}-idProofNumber`,
                      msg: "Invalid ration number",
                    });
                  }
                } else if (item["idProofType"] === "driving") {
                  let regex =
                    /^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/;
                  if (!regex.test(item["idProofNumber"].toUpperCase())) {
                    // data.push({
                    //   type: `${item["id"]}-idProofNumber`,
                    //   msg: "Invalid driving license number",
                    // });
                  }
                } else if (item["idProofType"] === "voter") {
                  let regex = /^([a-zA-Z]){3}([0-9]){7}?$/;
                  if (!regex.test(item["idProofNumber"].toUpperCase())) {
                    data.push({
                      type: `${item["id"]}-idProofNumber`,
                      msg: "Invalid voter id",
                    });
                  }
                } else if (item["idProofType"] === "aadhar") {
                  let regex = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;
                  if (!regex.test(item["idProofNumber"].toUpperCase())) {
                    data.push({
                      type: `${item["id"]}-idProofNumber`,
                      msg: "Invalid AADHAR number",
                    });
                  }
                }
              }
            }
          }
        }
      });
      setErr(data);
      if (data.length !== 0) {
        setloading(false);
        ref.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });

        return;
      }
    }

    try {
      let array = [...personStates];
      let arrayEx = array.map((e) => {
        let data = {
          personName: e?.name,
          dateOfBirth: moment(e.dob).format("YYYY-MM-DD"),
          gender: e.gender,
          documentType: e.idProofType,
          documentId: e.idProofNumber,
          // covid_certificate: e.doc_file_url.path
          //   ? e.doc_file_url.path
          //   : e.doc_file_url,
        };

        return data;
      });

      let schema = {
        cartContains: ["darshanam"],
        darshanamBookings: [
          {
            darshanamSlotId: slot?.productSlot?.id,
            bookingDate: moment(startDate).format("YYYY-MM-DD"),
            phoneNumber: `+${mobile}`,
            personDetails: arrayEx,
          },
        ],
        returnPriceStats: true,
      };

      let res = await ParokshaSevaService.bookings(schema, templeID);
      if (res) {
        let data = {
          darshanamType,
          startDate,
          noPersons,
          personStates,
          slot,
          slotsData,
          mobile,
          res,
        };
        setcurrentBookingDarshananam(data);
        setloading(false);

        history?.push(
          `/${selectedLanguage}/devotee-app/online-booking/darshanam/confirm`
        );
        setHighlightDates([]);
        setExcludeDates([]);
      }
    } catch (e) {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });

      toast.error(e.message);
      if (e.message === "Session Expired") {
        logout();

        history?.push(
          `/${selectedLanguage}/devotee/signin?to=devotee-app/online-booking/darshanam`
        );
      }
      setloading(false);
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });

      return;
    }
  };

  const getSlotsDetails = () => {
    if (slotsData?.length === 0 || !slotsData) {
      return <p>No Slots available</p>;
    }
    return slotsData
      ?.filter((item) => moment(startDate).format("YYYY-MM-DD") === item?.date)
      ?.map((item, i) => {
        if (noPersons > item?.availableQuantity) {
          return;
        }
        return (
          <div
            key={i + "slots-d"}
            onClick={() => {
              if (personStates?.length > item?.availableQuantity) {
                return;
              }
              setSlot(item);
              if (err?.find((e) => e.type === `slot`)) {
                let data = err.filter((e) => e.type !== "slot");
                setErr(data);
              }
            }}
          >
            <div
              className="sd-darshanam-box text-center"
              style={{
                display: "flex",
                alignItems: "center",
                border:
                  err.find((e) => e.type === "slot") ||
                  personStates?.length > item?.availableQuantity
                    ? "1px solid red"
                    : "",
              }}
              onClick={() => {
                if (personStates?.length > item?.availableQuantity) {
                  return;
                }
                setSlot(item);
                if (err?.find((e) => e.type === `slot`)) {
                  let data = err.filter((e) => e.type !== "slot");
                  setErr(data);
                }
              }}
            >
              <p
                className="sd-darshanam-time"
                style={{ marginBottom: "0px", maxWidth: "85px" }}
              >
                <span style={{ marginRight: "3px", fontWeight: "600" }}>
                  {item?.availableQuantity}
                </span>
                <span style={{ fontWeight: "400" }}> Available </span>
              </p>
              <div className="sd-side-heading-2 sd-acc">
                {window.innerWidth < 650 ? (
                  <span
                    style={{
                      fontWeight: "600",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Slot <br /> (
                    {moment(item?.productSlot?.startTime, "HH:mm:ss").format(
                      "hh:mm A"
                    )}{" "}
                    to
                    <span style={{ marginLeft: "5px" }}>
                      {moment(item?.productSlot?.endTime, "HH:mm:ss").format(
                        "hh:mm A"
                      )}
                      )
                    </span>
                  </span>
                ) : (
                  <span
                    style={{
                      fontWeight: "600",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Slot - (
                    {moment(item?.productSlot?.startTime, "HH:mm:ss").format(
                      "hh:mm A"
                    )}{" "}
                    -
                    {moment(item?.productSlot?.endTime, "HH:mm:ss").format(
                      "hh:mm A"
                    )}
                    )
                  </span>
                )}
              </div>
              <input
                className="sd-darshanam-radio"
                type="radio"
                id={"slot" + 11}
                name=""
                value={slot}
                onClick={() => {
                  if (personStates?.length > item?.availableQuantity) {
                    return;
                  }
                  setSlot(item);
                  if (err?.find((e) => e.type === `slot`)) {
                    let data = err.filter((e) => e.type !== "slot");
                    setErr(data);
                  }
                }}
                checked={slot.id === item?.id}
              />
            </div>
          </div>
        );
      });
  };

  const fnCalculateAge = (userDateinput) => {
    let birthDate = new Date(userDateinput);
    let difference = Date.now() - birthDate.getTime();
    let ageDate = new Date(difference);
    let calculatedAge = Math.abs(ageDate.getUTCFullYear() - 1970);
    return calculatedAge;
  };

  const handleChange = (index, event, name) => {
    // let data = [...personStates];
    // if (name === "docFileUrl" || name === "dob") {
    if (name === "dob") {
      let data = [...personStates];
      data[index][`${name}`] = event;
      setPersonStates(data);
    } else {
      if (name === "idProofType") {
        let data = [...personStates];

        let value = data[index][`${name}`];
        data[index][`${name}`] = event.target.value;
        if (value !== event.target.value) {
          data[index][`idProofNumber`] = "";
        }
        setPersonStates(data);
      } else if (name === "idProofNumber") {
        let data = [...personStates];
        data[index][`idProofNumber`] = event.target.value
          ?.toUpperCase()
          ?.replaceAll?.(" ", "");
        setPersonStates(data);
      } else {
        let data = [...personStates];
        data[index][`${name}`] = event.target.value;
        setPersonStates(data);
      }
    }
  };

  const listFunction = () => {
    if (noPersons === personStates.length) {
      return;
    }
    if (noPersons < personStates.length) {
      let arr = [...personStates];
      let b = personStates.length - noPersons;
      let total = personStates.length;
      while (b !== 0) {
        let data = err.filter((e) => {
          if (
            e.type !== `${total}-name` &&
            e.type !== `${total}-idProofType` &&
            e.type !== `${total}-idProofNumber` &&
            e.type !== `${total}-gender` &&
            e.type !== `${total}-dob`
            // e.type !== `${total}-docFileUrl`
          ) {
            return e;
          }
        });
        setErr(data);
        arr.pop();
        total--;
        b--;
      }

      setPersonStates([...arr]);
      return;
    }
    if (noPersons > personStates.length) {
      let arr = [...personStates];
      for (let i = personStates.length; i < noPersons; i++) {
        arr.push({
          id: personStates.length + 1,
          name: "",
          idProofType: "",
          idProofNumber: "",
          gender: "",
          dob: "",
          // docFileUrl: "",
        });
      }

      setPersonStates([...arr]);
      return;
    }
  };

  function addDays(date, days) {
    const copy = new Date(Number(date));
    copy.setDate(date.getDate() + days);
    return copy;
  }

  const autoFillFunction = (name, i) => {
    let array = [];
    let userOnClick = () => {
      let data = [...personStates];
      data[i]["name"] =
        userData?.firstName || userData?.lastName
          ? `${userData?.firstName} ${userData?.lastName}`
          : "";

      data[i]["dob"] = userData?.dateOfBirth
        ? new Date(userData?.dateOfBirth)
        : "";
      data[i]["gender"] = userData?.gender ? userData?.gender : "";
      data[i]["idProofType"] = userData?.idProofType
        ? userData?.idProofType
        : "";
      data[i]["idProofNumber"] = userData?.idProofNumber
        ? userData?.idProofNumber
        : "";
      setPersonStates(data);
      setMobile(userData?.phone?.replace("+", "") ?? "");
      let datas = err.filter((e) => {
        if (
          e.type !== `${i + 1}-name` &&
          e.type !== `${i + 1}-dob` &&
          e.type !== `${i + 1}-gender` &&
          e.type !== `${i + 1}-idProofNumber` &&
          e.type !== `${i + 1}-idProofType`
        ) {
          return e;
        }
      });
      setErr(datas);
      setAutoFill("none");
    };
    let a = personStates.filter((e) => {
      if (
        e?.name?.toLowerCase() ===
        `${userData?.firstName?.toLowerCase()} ${userData?.lastName?.toLowerCase()}`
      ) {
        return e;
      }
    });

    if (
      userData?.firstName?.toLowerCase()?.includes(name?.toLowerCase()) ||
      userData?.lastName?.toLowerCase()?.includes(name?.toLowerCase())
    ) {
      if (a?.length === 0) {
        array.push(
          <p
            className="sd-autofill-div"
            onClick={() => {
              userOnClick();
            }}
          >
            <p
              style={{ textTransform: "capitalize" }}
              onClick={() => {
                userOnClick();
              }}
            >
              Name:{" "}
              {userData?.firstName
                ? userData?.firstName
                : userData?.displayName}
              <span style={{ marginLeft: "3px" }}>{userData?.lastName}</span>
            </p>
            {userData?.dateOfBirth && (
              <p
                onClick={() => {
                  userOnClick();
                }}
              >
                Age:
                {fnCalculateAge(userData?.dateOfBirth)} yrs
              </p>
            )}
            {userData?.gender && <p>Gender: {userData?.gender}</p>}
          </p>
        );
      }
    }
    if (devoteeList !== null) {
      devoteeList.forEach((items) => {
        if (
          items?.firstName?.toLowerCase()?.includes(name?.toLowerCase()) ||
          items?.lastName?.toLowerCase()?.includes(name?.toLowerCase())
        ) {
          let a = personStates.filter((e) => {
            if (e?.name === `${items?.firstName} ${items?.lastName}`) {
              return e;
            }
          });
          if (a?.length === 0) {
            let onCLick = () => {
              let data = [...personStates];
              data[i]["name"] =
                items?.firstName || items?.lastName
                  ? `${items?.firstName} ${items?.lastName}`
                  : "";
              data[i]["dob"] = items?.dob ? new Date(items?.dob) : "";
              data[i]["gender"] = items?.gender
                ? items?.gender?.toLowerCase()
                : "";

              data[i]["idProofType"] = items?.idProofType
                ? items?.idProofType
                : "";
              data[i]["idProofNumber"] = items?.idProofNumber
                ? items?.idProofNumber
                : "";
              setPersonStates(data);
              let datas = err.filter((e) => {
                if (
                  e.type !== `${i + 1}-name` &&
                  e.type !== `${i + 1}-dob` &&
                  e.type !== `${i + 1}-gender` &&
                  e.type !== `${i + 1}-idProofNumber` &&
                  e.type !== `${i + 1}-idProofType`
                ) {
                  return e;
                }
              });
              setErr(datas);
              setAutoFill("none");
            };
            array.push(
              <p
                key={items.id + "list"}
                className="sd-autofill-div"
                onClick={() => {
                  onCLick();
                }}
              >
                <p
                  onClick={() => {
                    onCLick();
                  }}
                >
                  Name: {items?.firstName}
                  <span style={{ marginLeft: "3px" }}>{items?.lastName}</span>
                </p>
                <p
                  onClick={() => {
                    onCLick();
                  }}
                >
                  Age:
                  {fnCalculateAge(items?.dob)} yrs
                </p>
                <p>Gender: {items?.gender}</p>
              </p>
            );
          }
        }
      });
    }

    if (array.length !== 0) {
      return (
        <div className="sd-autofill bg">
          {array.map((item) => {
            return item;
          })}
        </div>
      );
    } else {
      if (name === "" && a?.length === 0) {
        let demo = (
          <p
            className="sd-autofill-div"
            onClick={() => {
              userOnClick();
            }}
          >
            <p
              style={{ textTransform: "capitalize" }}
              onClick={() => {
                userOnClick();
              }}
            >
              Name:{" "}
              {userData?.firstName
                ? userData?.firstName
                : userData?.displayName}
              <span style={{ marginLeft: "3px" }}>{userData?.lastName}</span>
            </p>
            {userData?.dateOfBirth && (
              <p
                onClick={() => {
                  userOnClick();
                }}
              >
                Age:
                {fnCalculateAge(userData?.dateOfBirth)} yrs
              </p>
            )}
            {userData?.gender && (
              <p
                onClick={() => {
                  userOnClick();
                }}
              >
                Gender: {userData?.gender}
              </p>
            )}
          </p>
        );

        let a = [...devoteeList, demo];
        return (
          <div className="sd-autofill bg ani">
            {a.map((item) => {
              return item;
            })}{" "}
          </div>
        );
      } else {
        return "";
      }
    }
  };
  //-------------------------------------------------------------functions--------------------------------------------------------//

  return (
    <div
      className="col-xs-12 col-sm-12 col-md-9 col-lg-9 sd-dashboard"
      style={{
        minHeight: document.getElementById("side-menu")?.offsetHeight,
      }}
    >
      {!loading ? (
        <div className="sd-profile sd-seva-form">
          <h2 className="sd-side-heading fw400">
            <span className="fw700" ref={ref}>
              Darshanam{" "}
            </span>
            Booking
          </h2>

          <div className="clearfix">
            {darshanams?.filter((e) => !e?.disabled)?.length === 0 ? (
              <p className="flex-c w-100 border-c pb-30  pt-20 br-4">
                <img
                  src={sd_script}
                  alt="#"
                  height="80px"
                  width="80px"
                  className="mr-5 mb-5 opace"
                />
                We are sorry to say that online darshanam booking is temporarily
                unavailable. We regret the inconvenience.
              </p>
            ) : (
              <form className="sd-form">
                <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12  xssm-pr-0 pl-0 ">
                  <label>
                    Select Darshanam Type<span className="sd-imp">*</span>
                  </label>
                  <div className="clearfix">
                    {darshanams?.map((item, i) => {
                      if (item?.disabled) {
                        return;
                      }
                      return (
                        <div
                          className="col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0 pl-0"
                          key={i + "darshanamtype"}
                        >
                          <div
                            className={
                              err.find((e) => e.type === "type")
                                ? " sd-red sd-radio-select"
                                : "sd-radio-select"
                            }
                            onClick={() => {
                              if (err?.find((e) => e.type === `type`)) {
                                let data = err.filter((e) => e.type !== "type");
                                setErr(data);
                              }
                              setSlot("");
                              setSlotsData([]);
                              setStartDate("");
                              setDarshanamType(item);
                              setExcludeDates([]);
                              setHighlightDates([]);
                              getQuota(item);
                            }}
                          >
                            <input
                              type="radio"
                              name="optradio1"
                              id={`Annadanam${i}`}
                              checked={
                                darshanamType?.name === item?.name
                                  ? true
                                  : false
                              }
                            />
                            <label
                              htmlFor={`Annadanam${i}`}
                              className="radio-inline"
                              onClick={() => {
                                if (err?.find((e) => e.type === `type`)) {
                                  let data = err.filter(
                                    (e) => e.type !== "type"
                                  );
                                  setErr(data);
                                }
                                setDarshanamType(item);
                              }}
                            >
                              <p>
                                {item?.name}
                                <br></br>
                                <span>Rs. {item.price}.00</span>
                              </p>
                            </label>
                          </div>
                        </div>
                      );
                    })}
                    <p className="sd-EM mr-35" style={{ left: "0" }}>
                      {err?.find((e) => e.type === `type`)?.msg}
                    </p>
                  </div>
                </div>
                <hr className="clearfix"></hr>
                <div className="sd-profile">
                  <div className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-4 xssm-pr-0 pl-0">
                    <label>
                      Select Date<span className="sd-imp">*</span>
                    </label>

                    <div
                      className={
                        err?.find((e) => e.type === `date`)
                          ? "sd-dob customDatePickerWidth sd-red"
                          : startDate !== ""
                          ? "sd-date-voilet sd-dob customDatePickerWidth"
                          : "sd-dob customDatePickerWidth"
                      }
                    >
                      <DatePicker
                        allowSameDay={false}
                        placeholderText="DD/MM/YYYY"
                        dateFormat={"dd/MM/yyyy"}
                        showDisabledMonthNavigation
                        style={{
                          width: "90%",
                          border: "none",
                          backgroundColor: "blue",
                        }}
                        highlightDates={highlightDates}
                        excludeDates={excludeDates}
                        minDate={addDays(
                          new Date(),
                          darshanamType?.minOnlineHours / 24
                        )}
                        maxDate={addDays(
                          new Date(),
                          darshanamType?.maxOnlineHours / 24
                        )}
                        calendarIcon={<img src={sd_dashboard_dob} alt="" />}
                        clearIcon={null}
                        selected={startDate}
                        onChange={(date) => {
                          if (darshanamType === "" || !darshanamType) {
                            toast.error("Please select darshanam type");
                            return;
                          }
                          if (
                            err.find(
                              (e) => e.type === "date" || e.type === "server"
                            )
                          ) {
                            let data = err.filter((e) => {
                              if (e.type !== "date" && e.type !== "server") {
                                return e;
                              }
                            });
                            setErr(data);
                          }
                          setStartDate(date);
                        }}
                      >
                        <div className="sd-quota">
                          <div className="sd-quota-div">
                            <p
                              style={{
                                backgroundColor: "rgb(0, 207, 0)",
                                color: "rgb(0, 207, 0)",
                              }}
                            ></p>
                            Available
                          </div>
                          <div className="sd-quota-div">
                            <p
                              style={{
                                backgroundColor: "orange",
                                color: "orange",
                              }}
                            ></p>
                            Almost Booked
                          </div>
                        </div>
                        <div className="sd-quota">
                          <div className="sd-quota-div">
                            <p
                              style={{
                                backgroundColor: "rgb(201, 0, 0)",
                                color: "rgb(201, 0, 0)",
                              }}
                            ></p>
                            Not Available
                          </div>
                        </div>
                      </DatePicker>
                    </div>
                    <p className="sd-EM mr-15">
                      {err?.find((e) => e.type === `date`)?.msg}
                    </p>
                  </div>

                  <div className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-4  xssm-pl-0 xssm-pr-0">
                    <label>
                      Number of Person(s)<span className="sd-imp">*</span>
                    </label>
                    <select
                      className={
                        noPersons !== ""
                          ? "form-control sd-voilet"
                          : "form-control"
                      }
                      onChange={(e) => {
                        setNoperons(e.target.value);
                        setSlot("");
                      }}
                      value={noPersons}
                      // defaultValue={noPersons}
                    >
                      <option key={"1"} value={"1"}>
                        1
                      </option>
                      <option key={"2"} value={"2"}>
                        2
                      </option>
                      <option key={"3"} value={"3"}>
                        3
                      </option>
                      <option key={"4"} value={"4"}>
                        4
                      </option>
                      <option key={"5"} value={"5"}>
                        5
                      </option>
                    </select>
                    <p style={{ color: "#181818", fontWeight: "600" }}>
                      Free for Children under 10 years
                    </p>
                  </div>
                  <div className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-4  xssm-pr-0 pl-0">
                    <label>
                      Mobile Number<span className="sd-imp">*</span>
                    </label>
                    <div className={"sd-profile-mobile"}>
                      <PhoneInput
                        inputExtraProps={{
                          autoFocus: true,
                          enableSearch: true,
                          countryCodeEditable: false,
                        }}
                        onlyCountries={["in"]}
                        placeholder="Enter Your 10 digit mobile number"
                        maxLength="15"
                        countryCodeEditable={false}
                        containerClass={
                          err?.find((e) => e.type === "phone")
                            ? "sd-phone-voilet sd-red"
                            : mobile !== ""
                            ? "sd-phone-voilet"
                            : ""
                        }
                        country="in"
                        value={mobile}
                        onChange={(e) => {
                          if (
                            err?.find(
                              (e) => e.type === "phone" || e.type === "common"
                            )
                          ) {
                            let array = err.filter((e) => {
                              if (e.type !== "phone" && e.type !== "common") {
                                return e;
                              }
                            });
                            setErr(array);
                          }
                          setMobile(e);
                        }}
                      />
                      <p className="d-EM ">
                        {err.find((e) => e.type === "phone")
                          ? err.find((e) => e.type === "phone")?.msg
                          : ""}
                      </p>
                    </div>
                  </div>
                </div>
                {slotsData?.length !== 0 && startDate ? (
                  <>
                    <hr className="clearfix"></hr>

                    <div
                      className="clearfix sd-darshanam-content"
                      style={{ position: "relative" }}
                    >
                      <br />
                      {getSlotsDetails()}
                      <p
                        style={{
                          position: "absolute",
                          top: "-12px",
                          left: "0px",
                          textAlign: "right",
                          color: "red",
                        }}
                      >
                        {err?.find((e) => e.type === `slot`)?.msg}
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    {startDate && !slotLoading ? (
                      <>
                        <hr className="clearfix"></hr>{" "}
                        <div
                          className="clearfix sd-darshanam-content"
                          style={{ position: "relative", color: "red" }}
                        >
                          No Slots available on{" "}
                          {moment(startDate).format("DD-MM-YYYY")}
                        </div>
                      </>
                    ) : (
                      startDate && (
                        <>
                          <hr className="clearfix"></hr>{" "}
                          <div
                            className="clearfix sd-darshanam-content"
                            style={{ position: "relative" }}
                          >
                            Fetching slots...Please wait{" "}
                          </div>
                        </>
                      )
                    )}
                  </>
                )}
                <div className="clearfix">
                  <hr className="clearfix"></hr>
                </div>
                {personStates.map((item, i) => {
                  return (
                    <>
                      {" "}
                      <div className="clearfix" key={i + "personstates-d"}>
                        <div
                          className="form-group col-xs-12 col-sm-12 col-md-1 col-lg-1  xssm-pr-0 pl-0"
                          style={{ width: "2vw" }}
                        >
                          <label>{i + 1}.</label>
                        </div>
                        <div
                          className="form-group col-xs-12 col-sm-12 col-md-11 col-lg-11  xssm-pl-0 pr-0 "
                          style={{ marginBottom: "0px" }}
                        >
                          <div className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-4  xssm-pr-0 pl-0">
                            <label>
                              Name<span className="sd-imp">*</span>
                            </label>
                            <input
                              maxLength="30"
                              type="text"
                              autoComplete="new-password"
                              placeholder="Please enter your name."
                              className={
                                err?.find((e) => e.type === `${i + 1}-name`)
                                  ? "form-control sd-red"
                                  : item?.name !== ""
                                  ? "form-control sd-voilet"
                                  : "form-control"
                              }
                              value={item?.name}
                              onFocus={() => {
                                if (autoFill === `${i}autofill`) {
                                  setAutoFill("none");
                                } else {
                                  setAutoFill(`${i}autofill`);
                                }
                              }}
                              onBlur={() => {
                                if (autoFill === `${i}autofill`) {
                                  setTimeout(() => {
                                    setAutoFill("none");
                                  }, 250);
                                }
                              }}
                              onChange={(e) => {
                                if (
                                  /^[a-zA-Z ]*$/.test(
                                    String(e.target.value) ||
                                      e.target.value === ""
                                  )
                                ) {
                                  if (
                                    err.find((e) => e.type === `${i + 1}-name`)
                                  ) {
                                    let data = err.filter(
                                      (e) => e.type !== `${i + 1}-name`
                                    );
                                    setErr(data);
                                  }
                                  handleChange(i, e, "name");
                                }
                              }}
                            />
                            <p className="sd-EM mr-15 ">
                              {err.find((e) => e.type === `${i + 1}-name`)?.msg}
                            </p>
                            {autoFill === `${i}autofill` &&
                              autoFillFunction(item?.name, i)}
                          </div>
                          <div className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-4  xssm-pl-0 xssm-pr-0 ">
                            <label>
                              Date of Birth<span className="sd-imp">*</span>
                            </label>
                            <div
                              className={
                                err?.find((e) => e.type === `${i + 1}-dob`)
                                  ? "sd-dob customDatePickerWidth  sd-red"
                                  : item?.dob !== ""
                                  ? "sd-dob customDatePickerWidth  sd-date-voilet"
                                  : "sd-dob customDatePickerWidth"
                              }
                            >
                              <DatePicker
                                renderCustomHeader={({
                                  date,
                                  changeYear,
                                  changeMonth,
                                }) => (
                                  <div
                                    style={{
                                      margin: 10,
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <select
                                      className="monthYearSelectContainer"
                                      value={getYear(date)}
                                      onChange={({ target: { value } }) =>
                                        changeYear(value)
                                      }
                                    >
                                      {range(
                                        getYear(new Date()) - 100,
                                        getYear(new Date())
                                      ).map((option) => (
                                        <option key={option} value={option}>
                                          {option}
                                        </option>
                                      ))}
                                    </select>

                                    <select
                                      className="monthYearSelectContainer"
                                      value={months[getMonth(date)]}
                                      onChange={({ target: { value } }) =>
                                        changeMonth(months.indexOf(value))
                                      }
                                    >
                                      {months.map((option) => (
                                        <option key={option} value={option}>
                                          {option}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                )}
                                allowSameDay={false}
                                placeholderText={"DD/MM/YYYY"}
                                dateFormat={"dd/MM/yyyy"}
                                showDisabledMonthNavigation
                                style={{
                                  width: "90%",
                                  border: "none",
                                  backgroundColor: "blue",
                                }}
                                maxDate={addDays(new Date(), -3652)}
                                clearIcon={null}
                                selected={item?.dob}
                                onChange={(date) => {
                                  if (
                                    err.find((e) => e.type === `${i + 1}-dob`)
                                  ) {
                                    let data = err.filter(
                                      (e) => e.type !== `${i + 1}-dob`
                                    );
                                    setErr(data);
                                  }
                                  handleChange(i, date, "dob");
                                }}
                              />
                            </div>
                            <p className="sd-EM mr-15 ">
                              {err.find((e) => e.type === `${i + 1}-dob`)?.msg}
                            </p>
                          </div>
                          <div className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-4  xssm-pl-0 xssm-pr-0">
                            <label>
                              Gender<span className="sd-imp">*</span>
                            </label>
                            <select
                              className={
                                err?.find((e) => e.type === `${i + 1}-gender`)
                                  ? "form-control sd-red"
                                  : item?.gender !== ""
                                  ? "form-control sd-voilet"
                                  : "form-control"
                              }
                              value={item?.gender}
                              onChange={(e) => {
                                if (
                                  err.find((e) => e.type === `${i + 1}-gender`)
                                ) {
                                  let data = err.filter(
                                    (e) => e.type !== `${i + 1}-gender`
                                  );
                                  setErr(data);
                                }
                                handleChange(i, e, "gender");
                              }}
                            >
                              {" "}
                              <option style={{ display: "none" }}>
                                Select a gender
                              </option>
                              <option key="male" value="male">
                                Male
                              </option>
                              <option key="female" value="female">
                                Female
                              </option>
                              <option key="others" value="others">
                                Others
                              </option>
                            </select>
                            <p className="sd-EM mr-15">
                              {
                                err.find((e) => e.type === `${i + 1}-gender`)
                                  ?.msg
                              }
                            </p>
                          </div>
                          <div style={{ display: "flex", width: "100%" }}>
                            <div className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-4  xssm-pl-0 xssm-pr-0 pl-0">
                              <label>
                                Photo ID Proof<span className="sd-imp">*</span>
                              </label>
                              <select
                                placeholder="Select a valid id proof"
                                className={
                                  err?.find(
                                    (e) => e.type === `${i + 1}-idProofType`
                                  )
                                    ? "form-control sd-red"
                                    : item.idProofType !== ""
                                    ? "form-control sd-voilet"
                                    : "form-control"
                                }
                                value={item?.idProofType}
                                onChange={(e) => {
                                  if (
                                    err.find(
                                      (e) => e.type === `${i + 1}-idProofType`
                                    )
                                  ) {
                                    let data = err.filter(
                                      (e) => e.type !== `${i + 1}-idProofType`
                                    );
                                    setErr(data);
                                  }
                                  handleChange(i, e, "idProofType");
                                }}
                              >
                                <option
                                  style={{
                                    display: "none",
                                  }}
                                >
                                  Select a valid id proof
                                </option>

                                <option key="pan" value="pan">
                                  PAN
                                </option>
                                <option key="aadhar" value="aadhar">
                                  AADHAR
                                </option>
                                <option key="driving" value="driving">
                                  Driving License
                                </option>
                                <option key="ration" value="ration">
                                  Ration Card
                                </option>
                                <option key="voter" value="voter">
                                  Voter ID
                                </option>
                              </select>
                              <p className="sd-EM mr-15">
                                {
                                  err.find(
                                    (e) => e.type === `${i + 1}-idProofType`
                                  )?.msg
                                }
                              </p>
                            </div>
                            <div className="form-group col-xs-12 col-sm-12 col-md-4 col-lg-4   xssm-pl-0 xssm-pr-0">
                              <label>
                                Photo ID Number<span className="sd-imp">*</span>
                              </label>

                              <input
                                placeholder="Please enter your id number"
                                type="text"
                                maxLength="30"
                                className={
                                  err?.find(
                                    (e) => e.type === `${i + 1}-idProofNumber`
                                  )
                                    ? "form-control sd-red"
                                    : item?.idProofNumber !== ""
                                    ? "form-control sd-voilet"
                                    : "form-control"
                                }
                                style={{ textTransform: "none" }}
                                value={item?.idProofNumber}
                                onChange={(e) => {
                                  if (
                                    /^[#.0-9a-zA-Z\s]+$/.test(e.target.value) ||
                                    e.target.value === ""
                                  ) {
                                    if (
                                      err.find(
                                        (e) =>
                                          e.type === `${i + 1}-idProofNumber`
                                      )
                                    ) {
                                      let data = err.filter(
                                        (e) =>
                                          e.type !== `${i + 1}-idProofNumber`
                                      );
                                      setErr(data);
                                    }
                                    handleChange(i, e, "idProofNumber");
                                  }
                                }}
                              />
                              <p className="sd-EM mr-15 ">
                                {
                                  err.find(
                                    (e) => e.type === `${i + 1}-idProofNumber`
                                  )?.msg
                                }
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr className="clearfix"></hr>
                    </>
                  );
                })}
                <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12  xssm-pl-0 pr-0">
                  <p
                    className="ml--15"
                    style={{
                      color: "#181818",
                      fontWeight: "600",

                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Note: Pilgrims who book for Darshanam should bring the
                    printed copy of their receipt.{" "}
                  </p>
                  <ol className="sd-terms-list">
                    {termsList.map((item, i) => {
                      return (
                        <li className="active" key={i + "terms-d"}>
                          <a style={{ paddingRight: "3px" }}>{item}</a>
                        </li>
                      );
                    })}
                  </ol>
                </div>
                <hr className="clearfix"></hr>

                <div className="clearfix mt-50">
                  <a
                    className="sd-btn-orange"
                    onClick={() => {
                      if (!loading) {
                        handleSubmit();
                      }
                    }}
                  >
                    Confirm Booking{" "}
                    {loading ? (
                      <img
                        src={sd_btn_loading}
                        alt=".."
                        className="sd-arrow-btn ml-5 h-20"
                      />
                    ) : (
                      <img
                        className="sd-arrow-btn ml-5"
                        src={SD_Right_Arrow_white}
                      />
                    )}
                  </a>
                </div>
              </form>
            )}
          </div>
        </div>
      ) : (
        <Sd_loading />
      )}
    </div>
  );
};
export default withRouter(Sd_dashboard_online_booking_darshanam);
