import React, { useEffect, useContext, useState, useRef } from "react";
import { toast } from "react-toastify";
import "../../../Utils/sd-styles/sd-css/main.css";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router";
import { SD_Context } from "../../../context/sd-context";
import {
  sd_btn_loading,
  sd_dashboard_edit_ico,
  sd_dashboard_side_arrow,
  sd_failure_icon,
  sd_success_icon,
} from "../../../Assets/imageList";
import ParokshaSevaService from "../../../services/sd-parokshaseva.service";
import DisplayRazorpay from "../../../Utils/sd-razorpay/sd-razorpay-display";
import moment from "moment";
import Sd_loading from "../../../Components/sd-common-components/sd-loading";
import { BASE_BANK } from "../../../Utils/sd-axios/sd-api-url";

const Sd_dashboard_online_booking_darshanam_confirm = ({ history }) => {
  //-------------------------------------------------------------states--------------------------------------------------------//
  const [successOrfailureFlag, setSuccessOrfailureFlag] = useState(false);
  const templeID = process.env.REACT_APP_TEMPLE ?? "SSVVSD";
  const [status, setStatus] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [selectedlanguage, setSelectedlanguage] = useState("en-in");
  const [loading, setLoading] = useState(true);
  const [dissmissed, setDissmissed] = useState("");
  const [halfState, setHalfState] = useState(null);
  const refContainer = useRef();
  // const [charges, setCharges] = useState("");
  const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];
  const [timestamp, setTimestamp] = useState("");

  const {
    setActive,
    setActivePath,
    currentBookingDarshananam,
    userData,
    logout,
    setcurrentBookingDarshananam,
  } = useContext(SD_Context);
  //-------------------------------------------------------------states--------------------------------------------------------//
  //-------------------------------------------------------------useeefect--------------------------------------------------------//
  useEffect(async () => {
    setLoading(true);
    if (
      supportedLanguages.includes(
        history?.location?.pathname?.split?.("/")?.[1]
      )
    ) {
      setSelectedlanguage(history?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
    if (!currentBookingDarshananam) {
      history?.push(
        `/${selectedlanguage}/devotee-app/online-booking/paroksha-seva/booking/ganapathi-homam`
      );
    }

    // let res = await Sd_DashboardService.get_taxes(
    //   currentBookingDarshananam?.darshanamType?.price *
    //     currentBookingDarshananam?.noPersons,
    //   userData?.phone.substring(0, 3) === "+91" ? "INR" : "USD"
    // );

    // setCharges(res);
    setActive("onlinebooking-darshanam-confirm-details");
    setActivePath("Onlinebooking-Darshanam-Confirm Details");
    setTimestamp("");
    setLoading(false);
    return () => {
      setTimestamp("");
    };
  }, []);

  useEffect(() => {
    if (dissmissed === "failed") {
      setErrorMsg("Cancelled by User");
      setSuccessOrfailureFlag(true);
      setStatus("failed");
      setLoading(false);
      setDissmissed("");
    }
  }, [dissmissed]);

  useEffect(() => {
    refContainer?.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }, [status]);
  //-------------------------------------------------------------useeeffect--------------------------------------------------------//

  //-------------------------------------------------------------functions--------------------------------------------------------//
  const handleSubmit = async () => {
    let res;
    if (!halfState) {
      try {
        setLoading(true);
        let array = [...currentBookingDarshananam?.personStates];
        // console.log(array);
        let arrayEx = array.map((e) => {
          return {
            personName: e?.name,
            dateOfBirth: moment(e.dob).format("YYYY-MM-DD"),
            gender: e.gender,
            documentType: e.idProofType,
            documentId: e.idProofNumber,
            // covid_certificate: e.doc_file_url.path
            //   ? e.doc_file_url.path
            //   : e.doc_file_url,
          };
        });
        // console.log(arrayEx);

        let schema = {
          cartContains: ["darshanam"],
          darshanamBookings: [
            {
              darshanamSlotId: currentBookingDarshananam?.slot?.productSlot?.id,
              bookingDate: moment(currentBookingDarshananam?.startDate).format(
                "YYYY-MM-DD"
              ),
              phoneNumber: `+${currentBookingDarshananam?.mobile}`,
              personDetails: arrayEx,
            },
          ],
          returnPriceStats: false,
        };
        res = await ParokshaSevaService.bookings(schema, templeID);
        setHalfState(res);
      } catch (e) {
        // console.log(e);
        toast.error(e.message);
        if (e.message === "Session Expired") {
          logout();

          history?.push(
            `/${selectedlanguage}/devotee/signin?to=devotee-app/online-booking/darshanam`
          );
        }
        setLoading(false);

        return;
      }
    }
    if (currentBookingDarshananam?.res?.totalAmount === 0) {
      setHalfState(null);
      setSuccessOrfailureFlag(true);
      setStatus("success");
      setTimestamp("");
      setLoading(false);
      return;
    }
    try {
      if (halfState !== null) {
        if (Math.floor((Date.now() - timestamp) / 1000) > 900) {
          toast.error(
            "Redirecting to Previous Page as buffer time of 15 min for retrying the payment has been exceeded."
          );
          setTimestamp("");
          setTimeout(() => {
            history?.push(
              `/${selectedlanguage}/devotee-app/online-booking/paroksha-seva/booking/ganapathi-homam`
            );
          }, 3000);
          return;
        }

        await DisplayRazorpay(
          halfState?.paymentInformation?.razorOrder?.id,
          halfState?.paymentInformation?.razorOrder?.currency,
          halfState?.paymentInformation?.razorOrder?.amount,
          halfState?.cartItems?.donation,
          halfState?.paymentInformation?.onlinePaymentEntity?.id,
          BASE_BANK,
          setDissmissed,
          userData,
          dissmissed,
          halfState?.priceStats?.templePaymentConfig?.razorpay?.key,
          templeID
        );

        // console.log(result);
        setHalfState(null);
        setcurrentBookingDarshananam(null);
        setTimestamp("");
        setSuccessOrfailureFlag(true);
        setStatus("success");
        setLoading(false);
      } else {
        setTimestamp(Date.now());

        await DisplayRazorpay(
          res?.paymentInformation?.razorOrder?.id,
          res?.paymentInformation?.razorOrder?.currency,
          res?.paymentInformation?.razorOrder?.amount,
          res?.cartItems?.donation,
          res?.paymentInformation?.onlinePaymentEntity?.id,
          BASE_BANK,
          setDissmissed,
          userData,
          dissmissed,
          res?.priceStats?.templePaymentConfig?.razorpay?.key,
          templeID
        );
        // console.log(result);
        setHalfState(null);
        setSuccessOrfailureFlag(true);
        setStatus("success");
        setTimestamp("");
        setcurrentBookingDarshananam(null);
        setLoading(false);
      }
    } catch (e) {
      // console.log(e);
      setErrorMsg(e.message);
      setSuccessOrfailureFlag(true);
      setStatus("failed");
      setLoading(false);
    }
  };

  const getStatusFunction = () => {
    switch (status) {
      case "success":
        return (
          <div className="sd-success" ref={refContainer}>
            <div className="sd-success-top sd-green">
              <img src={sd_success_icon} alt="success" /> <p>Success</p>
            </div>
            <div className="sd-success-bottom">
              <h3>
                Confirmed! Your online booking for{" "}
                <span className="mr-3">
                  {currentBookingDarshananam?.darshanamType?.name}
                </span>{" "}
                on{" "}
                <span className="mr-3">
                  {" "}
                  {moment(currentBookingDarshananam?.startDate).format(
                    "DD-MM-YYYY"
                  )}
                </span>
                has been made successfully. Looking forward to meeting you on a
                journey towards spirituality.
              </h3>
              <div className="sd-success-bottom-down">
                <p
                  className="sd-border-right"
                  onClick={() => {
                    history?.push(
                      `/${selectedlanguage}/devotee-app/booking-history`
                    );
                  }}
                >
                  Booking History
                </p>{" "}
                <p
                  onClick={() => {
                    history?.push(`/${selectedlanguage}/home`);
                  }}
                >
                  Go to Home
                </p>
              </div>
            </div>
          </div>
        );
      case "failed":
        return (
          <div className="sd-success" ref={refContainer}>
            <div className="sd-success-top sd-redback">
              <img src={sd_failure_icon} alt="success" />{" "}
              <p>Sorry, your booking could not be placed. {errorMsg} </p>
            </div>
            <div className="sd-success-bottom">
              <h3>
                Your booking was not placed as there was a problem processing
                payement. Check your booking history or SMS after 20 mins for
                confirmation. Please note that any funds debited will credit
                back to your account within 7 working days.
              </h3>
              <div className="sd-success-bottom-down">
                <p
                  className="sd-border-right"
                  onClick={() => {
                    setStatus("");
                    setSuccessOrfailureFlag(false);
                  }}
                >
                  Try Again
                </p>{" "}
                <p
                  onClick={() => {
                    history?.push(`/${selectedlanguage}/support/contactUs`);
                  }}
                >
                  Contact Us
                </p>
              </div>
            </div>
          </div>
        );
    }
  };

  const getDetailsFunction = () => {
    let array = [];
    let i = 0;
    currentBookingDarshananam?.personStates.map((obj) => {
      if (obj.name !== "") {
        array.push(
          <tr>
            <td>{i + 1}</td>
            <td>{obj?.name}</td>
            <td>{obj?.gender}</td>
            <td>{obj?.idProofNumber}</td>
          </tr>
        );
      }
      i++;
    });
    return array;
  };
  //-------------------------------------------------------------functions--------------------------------------------------------//
  return (
    <div
      className="col-xs-12 col-sm-12 col-md-9 col-lg-9 sd-dashboard"
      style={{
        minHeight: document.getElementById("side-menu")?.offsetHeight,
      }}
    >
      {loading ? (
        <Sd_loading />
      ) : successOrfailureFlag ? (
        getStatusFunction()
      ) : (
        <div className="sd-confirm-details">
          <h2 className="sd-side-heading fw400">
            <span className="fw400">
              <span className="fw700">Confirm</span> Details
            </span>
            <a
              onClick={() => {
                history?.push(
                  `/${selectedlanguage}/devotee-app/online-booking/darshanam`
                );
              }}
              className="sd-confirm-edit-button"
            >
              <img src={sd_dashboard_edit_ico} /> Edit details
            </a>
          </h2>
          <div className="clearfix  sd-booking-details">
            <h4>Booking Details</h4>
            <table className="table table-bordered sd-toggle-table">
              <thead>
                <tr>
                  <th width="35%">Booking for</th>
                  <th width="20%">Date</th>
                  <th width="20%">No of Persons</th>
                  <th width="25%">Total Price</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    className="sd-bl-none"
                    style={{ textTransform: "capitalize" }}
                  >
                    {console.log(currentBookingDarshananam)}
                    {currentBookingDarshananam?.darshanamType?.name} <br />
                    Slot -{" "}
                    {moment(
                      currentBookingDarshananam?.slot?.productSlot?.startTime,
                      "HH:mm:ss"
                    ).format("hh:mm A")}{" "}
                    -{" "}
                    {moment(
                      currentBookingDarshananam?.slot?.productSlot?.endTime,
                      "HH:mm:ss"
                    ).format("hh:mm A")}
                  </td>

                  <td>
                    {" "}
                    {moment(currentBookingDarshananam?.startDate).format(
                      "DD-MM-YYYY"
                    )}
                  </td>
                  <td>Persons - {currentBookingDarshananam?.noPersons}</td>
                  <td>
                    Rs. {currentBookingDarshananam?.res?.totalAmount}.00 <br />{" "}
                    (Handling charges: Rs.{" "}
                    {parseFloat(
                      currentBookingDarshananam?.res?.templeFee
                    ).toFixed(2)}{" "}
                  </td>
                </tr>
                {/* <tr>
                  <td colSpan="3" height="20px"></td>
                </tr> */}
              </tbody>

              {currentBookingDarshananam?.occasion && (
                <>
                  <thead className="clearfix mt-20">
                    <tr>
                      <th width="40%"></th>
                      {currentBookingDarshananam?.occasion ? (
                        <th width="20%" colSpan="2">
                          Auspicious Day
                        </th>
                      ) : (
                        ""
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td></td>
                      <td colSpan="2">{currentBookingDarshananam.occasion}</td>
                    </tr>
                  </tbody>
                </>
              )}
            </table>
            <div className="sd-table-detoggler">
              <div className="sd-booking-details-up bg">
                <div className="sd-actions-icons-main">
                  {" "}
                  <label style={{ color: "grey", fontWeight: 500 }}>
                    BOOKING FOR
                  </label>
                  <br />
                  <span
                    style={{ fontWeight: "600", textTransform: "capitalize" }}
                  >
                    {currentBookingDarshananam?.darshanamType?.name} - Persons:
                    ({currentBookingDarshananam?.personStates?.length} )<br />
                    Slot -{" "}
                    {moment(
                      currentBookingDarshananam?.slot?.productSlot?.startTime,
                      "HH:mm:ss"
                    ).format("hh:mm A")}{" "}
                    -{" "}
                    {moment(
                      currentBookingDarshananam?.slot?.productSlot?.endTime,
                      "HH:mm:ss"
                    ).format("hh:mm A")}{" "}
                  </span>
                </div>
              </div>
              <div className="sd-booking-details-down ">
                <div style={{ marginRight: "0px" }}>
                  {" "}
                  <label>AMOUNT</label>
                  <span>
                    {" "}
                    {userData?.phone.substring(0, 3) === "+91" ? "Rs." : "$"}
                    {currentBookingDarshananam?.res?.totalAmount}.00
                    <br />{" "}
                    <span style={{ fontSize: "12px" }}>
                      (Handling charges:{" "}
                      {userData?.phone.substring(0, 3) === "+91" ? "Rs." : "$"}
                      {~~currentBookingDarshananam?.additionalAmount}.00)
                    </span>
                  </span>
                </div>

                <div>
                  <label>DATE/SLOT</label>
                  <span>
                    {moment(currentBookingDarshananam.startDate).format(
                      "DD-MM-YYYY"
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="clearfix sd-devotee-details mt-30">
            <h4>
              Devotee Details - Mobile: +{currentBookingDarshananam?.mobile}
            </h4>
            <table className="table table-bordered ">
              <thead>
                <tr>
                  <th width="5%">S.No.</th>
                  <th width="30%">Name</th>
                  <th width="30%">Gender</th>

                  <th width="35%">ID Proof</th>
                </tr>
              </thead>
              <tbody>{getDetailsFunction()}</tbody>
            </table>
          </div>
          <div className="clearfix mt-50 sd-form">
            <a
              className="sd-btn-orange"
              onClick={() => {
                if (!loading) {
                  handleSubmit();
                }
              }}
            >
              Proceed to Payment{" "}
              {loading ? (
                <img
                  src={sd_btn_loading}
                  alt=".."
                  className="sd-arrow-btn ml-5 h-20"
                />
              ) : (
                <img
                  className="sd-arrow-btn ml-10"
                  src={sd_dashboard_side_arrow}
                />
              )}
            </a>
          </div>
        </div>
      )}{" "}
    </div>
  );
};
export default withRouter(Sd_dashboard_online_booking_darshanam_confirm);
