import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import Sd_gallery_semi_view from "../../Components/sd-common-components/sd-gallery-semi-view";
import TemplateFour from "../../Components/body/sd-home-template/sd-home-component-four";
import TemplateOne from "../../Components/body/sd-home-template/sd-home-component-one";
import TemplateThree from "../../Components/body/sd-home-template/sd-home-component-three";
import TemplateTwo from "../../Components/body/sd-home-template/sd-home-component-two";
import "../../Utils/sd-styles/sd-home.scss";
import { SD_Context } from "../../context/sd-context";
import TemplateFive from "../../Components/body/sd-home-template/sd-home-component-five";
const Sd_Home = () => {
  const { setActiveTopHeader, setActiveDownHeader, setCurrentDashboardLower } =
    useContext(SD_Context);
  useEffect(() => {
    setActiveDownHeader("");
    setActiveTopHeader("");
    setCurrentDashboardLower("");
  }, []);
  // console.log("home element rerender");
  return (
    <div className="w-100">
      <Helmet>
        <meta charSet="utf-8" />
        <title> Kanipaka Devasthanam Official Website</title>
        <meta name="description" content="Home Page of Kanipaka Devasthanam" />
        <meta name="keywords" content="Kanipakam, Devasthanam , Home" />
      </Helmet>
      <TemplateOne />
      <TemplateTwo />
      <TemplateThree />
      <TemplateFour />
      <Sd_gallery_semi_view />
      <TemplateFive />
    </div>
  );
};
export default Sd_Home;
