import React, { useEffect, useState } from "react";
import {
  // ganapathi,
  // homam,
  orange_arrow,
  SD_divider,
  // seshavaham,
  template5,
} from "../../../Assets/imageList";
import "../../../Utils/sd-styles/sd-main-sup.scss";
// import YoutubeService from "../../../services/sd-youtube.service";
const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];

const TemplateFive = () => {
  //-------------------------------------------------------------states--------------------------------------------------------//
  const [selectedlanguage, setSelectedlanguage] = React.useState("en-in");

  // const [isYoutubeLive, setIsYoutubeLive] = useState(true);
  //-------------------------------------------------------------states--------------------------------------------------------//

  //-------------------------------------------------------------useEffects--------------------------------------------------------//

  useEffect(async () => {
    if (
      supportedLanguages.includes(
        history?.location?.pathname?.split?.("/")?.[1]
      )
    ) {
      setSelectedlanguage(history?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
    // try {
    //   const videoId = await YoutubeService.getHomePageId();
    //   // console.log(videoId);
    //   setIsYoutubeLive(videoId);
    // } catch (error) {
    //   setIsYoutubeLive(false);
    //   // console.log(`problem getting youtube video id`);
    // }
  }, []);

  //-------------------------------------------------------------useEffects--------------------------------------------------------//

  return (
    <div className="sd-template-six ">
      <div className="sd-t6-one">
        <img src={template5} alt="alt" />
      </div>

      <div className="sd-t6-two">
        <div>
          <h1>About Temple</h1>
          <img src={SD_divider} alt="-" style={{ height: "20px" }}></img>
          <p>
            Sri Varasiddhi Vinayaka Swamy Temple is nestled in the Kanipakam
            Village of Chittoor District. This ancient Ganesha Temple is also
            known as Kanipakam Vinayaka Temple. The Prime deity of this Temple
            is Sri Varasiddhi Vinayaka Swamy is a swayambhu, self-manifested
            idol. A very interesting fact is associated with this temple is that
            Sri Vinayaka Idol size is increasing day by day.
          </p>
          <h5>
            It is believed that the idol will be growing in size until the end
            of Kaliyuga and then Sri Vinayaka will appear in person. The well
            from where Sri Varasiddhi Vinayaka manifested is still exist in the
            temple. The holy water from the well is distributed among the
            devotees as prasadam in this temple.
          </h5>
          <div className="temples">
            {" "}
            <div>
              <img src={orange_arrow} height="20px" />
              The Temple
            </div>
            <div>
              <img src={orange_arrow} height="20px" />
              The Hisory
            </div>
            <div>
              <img src={orange_arrow} height="20px" />
              General Information
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TemplateFive;
