import React, { useEffect, useContext, useState } from "react";
import { toast } from "react-toastify";
import {
  sd_dashboard_dob,
  sd_dashboard_side_arrow,
  sd_edit_black,
  sd_move_left,
  sd_move_right,
  sd_script,
  sd_side_arrow,
  sd_trash,
  sd_white_plus,
} from "../../../Assets/imageList";
import Sd_loading from "../../../Components/sd-common-components/sd-loading";
import { SD_Context } from "../../../context/sd-context";
import Sd_DashboardService from "../../../services/sd-dashboard.service";
import "../../../Utils/sd-styles/sd-css/main.css";
//import "react-day-picker/lib/style.css";
import DatePicker from "react-date-picker";
import moment from "moment";
import { addDays } from "date-fns";

const Sd_dashboard_ma_devotee_master = ({ history }) => {
  //-------------------------------------------------------------states--------------------------------------------------------//
  const { setActive, setActivePath, setDevoteeList, logout } =
    useContext(SD_Context);
  const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];
  const templeID = process.env.REACT_APP_TEMPLE ?? "SSVVSD";
  const [listLoad, setListLoad] = useState(false);
  const [list, setList] = useState([]);
  const [edit, setEdit] = useState(false);
  const [innerLoad, setInnerLoad] = useState(false);
  const [uploadFlag, setUploadFlag] = useState(false);
  const [selectedLanguage, setSelectedlanguage] = React.useState("en-in");
  //add-data

  const [firstName, setFirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [gothram, setGothram] = useState("");
  const [uploadLink, setUploadLink] = useState("");
  const [startDate, setStartDate] = useState("");
  const [docNumber, setdocNumber] = useState("");
  const [docType, setDockType] = useState("");
  const [gender, setGender] = useState("");
  const [editFlag, seteditFlag] = useState(false);
  const [id, setID] = useState("");
  const [err, setErr] = useState([]);
  const [meta, setMeta] = useState({});
  const [act, setAct] = useState("");
  //-------------------------------------------------------------states--------------------------------------------------------//

  //-------------------------------------------------------------useefect--------------------------------------------------------//
  useEffect(() => {
    if (!localStorage.getItem("accessToken")) {
      history?.push(`/${selectedLanguage}/`);
    }
    setActive("devotee-master-list");
    setActivePath("My Account-Devotee Master List");
    if (
      supportedLanguages.includes(
        history?.location?.pathname?.split?.("/")?.[1]
      )
    ) {
      setSelectedlanguage(history?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
    getData(1, 10);
  }, []);
  useEffect(() => {
    displayFunction();
  }, [list]);
  //-------------------------------------------------------------useefect--------------------------------------------------------//

  //-------------------------------------------------------------functions--------------------------------------------------------//

  function capitalize(str) {
    let splitStr = str?.toLowerCase?.().split?.(" ");
    for (let i = 0; i < splitStr?.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr?.join(" ");
  }

  const getData = async (items, page) => {
    setListLoad(true);
    try {
      let res = await Sd_DashboardService.get_master_list({
        page: items,
        limit: page,
        id: templeID,
      });
      setList(res);
      setDevoteeList(res?.items);
      setMeta({});
      setAct(page);
      setListLoad(false);
    } catch (e) {
      if (e.message === "Session Expired") {
        logout();

        history?.push(`/${selectedLanguage}/`);
      }
      toast.error(e.message);
      setListLoad(false);
    }
  };

  const displayFunction = () => {
    if (list?.items?.length === 0) {
      return (
        <p className="flexy ">
          <img
            src={sd_script}
            alt="#"
            height="80px"
            width="80px"
            className="mr-5 mb-5 opace"
          />
          There is no data of devotees"
        </p>
      );
    } else
      return list?.items?.map((item, i) => {
        return (
          <div
            key={i}
            className="col-xs-12 col-sm-12 col-md-4 col-lg-4 xssm-pr-0 pl-0"
          >
            <div className="sd-favorites-list">
              <h4 className="text-center mt-10 mb-10 sd-master-list-name">
                {`${item.firstName}
                 ${item.lastName}`}
              </h4>
              <p className="text-center">
                <span style={{ color: "#181818", textTransform: "capitalize" }}>
                  {item.age}{" "}
                  <span style={{ textTransform: "none" }}>years</span>,
                  {item.gender}
                </span>
                <br></br>
                <span style={{ color: "#181818", textTransform: "capitalize" }}>
                  {item.gothram} Gothram
                </span>
              </p>
              <div className="sd-background-grey text-center sd-adaar-card-bg">
                <span>
                  ID -
                  <span style={{ textTransform: "uppercase" }}>
                    {item.idProofType}
                  </span>
                  <br></br>
                  {item.idProofNumber}
                </span>
              </div>
              <div className="mt-10 clearfix flex">
                <span className="sd-form col-md-6 pl-0 pr-5 xs-pr-0">
                  <a
                    className="sd-btn-light-pink "
                    onClick={() => {
                      handleEdit(item);
                    }}
                  >
                    <img src={sd_edit_black} className="mr-10" /> Edit
                  </a>
                </span>
                <span className="sd-form col-md-6 pr-0 pl-5 xs-pl-0">
                  <a
                    className="sd-btn-light-pink margin-l"
                    onClick={() => {
                      handleDelete(item);
                    }}
                  >
                    <img src={sd_trash} className="mr-5" height="15px" /> Delete
                  </a>
                </span>
              </div>
            </div>
          </div>
        );
      });
  };

  const handleFileUpload = async (e) => {
    setUploadFlag(true);
    try {
      if (e.target.files[0].size / 1024 / 1024 > 5) {
        setUploadFlag(false);
        document.getElementById("uploadInputdm").value = "";

        throw new Error(
          "Please make sure the file you are attaching are below 5 mb "
        );
      }
      let formData = new FormData();
      formData.append("file", e.target.files[0]);
      let res = await Sd_DashboardService.upload_document(
        formData,
        "devoteeMasterList",
        templeID
      );
      toast.info("File Uploaded");
      setUploadLink(res);
      setUploadFlag(false);
    } catch (e) {
      toast.error(e.message);
      setUploadFlag(false);

      setUploadLink("");
      document.getElementById("uploadInputdm").value = "";

      if (e.message === "Session Expired") {
        logout();

        history?.push(
          `/${selectedLanguage}/devotee/signin?to=devotee-app/my-account/devotee-masterlist`
        );
      }
      return;
    }
  };

  const clearData = () => {
    setFirstName("");
    setlastName("");
    setDockType("");
    setdocNumber("");
    setUploadLink("");
    setGender("");
    setStartDate("");
    setGothram("");
  };

  const handleSubmit = async () => {
    setInnerLoad(true);
    let a = true;
    if (a) {
      let data = [...err];
      if (firstName === "") {
        data.push({ type: "first", msg: "Enter your first name" });
      } else if (firstName.length < 3) {
        data.push({ type: "first", msg: "Minimum 3 characters" });
      }
      if (lastName === "") {
        data.push({ type: "last", msg: "Enter your last name" });
      } else if (lastName.length < 3) {
        data.push({ type: "last", msg: "Minimum 3 characters" });
      }
      if (startDate === "") {
        data.push({ type: "date", msg: "Please select a date" });
      }
      if (gender === "") {
        data.push({ type: "gender", msg: "Select a gender" });
      }
      if (gothram === "") {
        data.push({ type: "gothram", msg: "Please enter your gothram" });
      } else if (gothram.length < 3) {
        data.push({ type: "gothram", msg: "Minimum 3 characters" });
      }
      if (docType === "") {
        data.push({ type: "docType", msg: "Select the type of id proof" });
      } else {
        if (docType === "pan") {
          let txt = docNumber.toUpperCase();
          let regex =
            /[a-zA-Z]{3}[PCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/;
          let pan = {
            C: "Company",
            P: "Personal",
            H: "Hindu Undivided Family (HUF)",
            F: "Firm",
            A: "Association of Persons (AOP)",
            T: "AOP (Trust)",
            B: "Body of Individuals (BOI)",
            L: "Local Authority",
            J: "Artificial Juridical Person",
            G: "Govt",
          };
          pan = pan[txt[3]];
          if (regex.test(txt)) {
            if (pan !== "Personal") {
              data.push({ type: "docNumber", msg: "Invalid pan number" });
            }
          } else data.push({ type: "docNumber", msg: "Invalid pan number" });
        } else if (docType === "ration") {
          let regex = /^([a-zA-Z0-9]){8,12}\s*$/;
          if (!regex.test(docNumber.toUpperCase())) {
            data.push({ type: "docNumber", msg: "Invalid ration number" });
          }
        } else if (docType === "driving") {
          let regex =
            /^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/;
          if (!regex.test(docNumber.toUpperCase())) {
            // data.push({
            //   type: "docNumber",
            //   msg: "Invalid driving license number",
            // });
          }
        } else if (docType === "voter") {
          let regex = /^([a-zA-Z]){3}([0-9]){7}?$/;
          if (!regex.test(docNumber.toUpperCase())) {
            data.push({ type: "docNumber", msg: "Invalid voter id" });
          }
        } else if (docType === "aadhar") {
          let regex = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;
          if (!regex.test(docNumber.toUpperCase())) {
            data.push({ type: "docNumber", msg: "Invalid AADHAR number" });
          }
        }
        if (uploadLink === "") {
          data.push({ type: "upload", msg: "Please attach a valid id proof" });
        }
      }
      setErr(data);
      if (data.length !== 0) {
        setInnerLoad(false);
        return;
      }
    }
    try {
      let data = {
        firstName: capitalize(firstName),
        dob: moment(startDate).format("YYYY-MM-DD"),
        gender: gender,
        gothram: capitalize(gothram),
        idProofType: docType,
        idProofNumber: docNumber,
        docFileUrl: uploadLink.fileUrl,
      };
      if (lastName !== "") {
        data.lastName = capitalize(lastName);
      }
      let res = await Sd_DashboardService.add_master_list(data, templeID);
      if (res) {
        setEdit(false);
        clearData();
        setInnerLoad(false);
        getData(1, 10);
      }
    } catch (e) {
      toast.error(e?.message);
      if (e.message === "Session Expired") {
        logout();

        // localStorage.clear();
        history?.push(`/${selectedLanguage}/`);
        setInnerLoad(false);
      }
    }
  };

  const handleDelete = async (item) => {
    console.log(id);
    setListLoad(true);
    try {
      await Sd_DashboardService.delete_master_list(item?.id, templeID);

      toast.success("Delete succesfull");
      getData(1, 10);
      setListLoad(false);
    } catch (e) {
      if (e.message === "Session Expired") {
        logout();

        // localStorage.clear();
        history?.push(`/${selectedLanguage}/`);
        setListLoad(false);
      }
    }
  };

  const handleEdit = async (editData) => {
    seteditFlag(true);
    setID(editData?.id);
    setFirstName(capitalize(editData?.firstName));
    setlastName(capitalize(editData?.lastName));
    setGender(editData?.gender);
    setGothram(capitalize(editData?.gothram));
    setUploadLink(editData?.docFileUrl);
    setStartDate(new Date(editData?.dob));
    setDockType(editData?.idProofType);
    setdocNumber(editData?.idProofNumber);
    setEdit(true);
  };

  const handleEditSubmit = async () => {
    setInnerLoad(true);
    let b = true;
    if (b) {
      let data = [...err];
      if (firstName === "") {
        data.push({ type: "first", msg: "Enter your first name" });
      } else if (firstName.length < 3) {
        data.push({ type: "first", msg: "Minimum 3 characters" });
      }
      if (lastName === "") {
        data.push({ type: "last", msg: "Enter your last name" });
      } else if (lastName.length < 3) {
        data.push({ type: "last", msg: "Minimum 3 characters" });
      }
      if (startDate === "") {
        data.push({ type: "date", msg: "Please select a date" });
      }
      if (gender === "") {
        data.push({ type: "gender", msg: "Select a gender" });
      }
      if (gothram === "") {
        data.push({ type: "gothram", msg: "Please enter your gothram" });
      } else if (gothram.length < 3) {
        data.push({ type: "gothram", msg: "Minimum 3 characters" });
      }
      if (docType === "") {
        data.push({ type: "docType", msg: "Select the type of id proof" });
      } else {
        if (docType === "pan") {
          let txt = docNumber.toUpperCase();
          let regex =
            /[a-zA-Z]{3}[PCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/;
          let pan = {
            C: "Company",
            P: "Personal",
            H: "Hindu Undivided Family (HUF)",
            F: "Firm",
            A: "Association of Persons (AOP)",
            T: "AOP (Trust)",
            B: "Body of Individuals (BOI)",
            L: "Local Authority",
            J: "Artificial Juridical Person",
            G: "Govt",
          };
          pan = pan[txt[3]];
          if (regex.test(txt)) {
            if (pan !== "Personal") {
              data.push({ type: "docNumber", msg: "Invalid pan number" });
            }
          } else data.push({ type: "docNumber", msg: "Invalid pan number" });
        } else if (docType === "ration") {
          let regex = /^([a-zA-Z0-9]){8,12}\s*$/;
          if (!regex.test(docNumber.toUpperCase())) {
            data.push({ type: "docNumber", msg: "Invalid ration number" });
          }
        } else if (docType === "driving") {
          let regex =
            /^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/;
          if (!regex.test(docNumber.toUpperCase())) {
            // data.push({
            //   type: "docNumber",
            //   msg: "Invalid driving license number",
            // });
          }
        } else if (docType === "voter") {
          let regex = /^([a-zA-Z]){3}([0-9]){7}?$/;
          if (!regex.test(docNumber.toUpperCase())) {
            data.push({ type: "docNumber", msg: "Invalid voter id" });
          }
        } else if (docType === "aadhar") {
          let regex = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;
          if (!regex.test(docNumber.toUpperCase())) {
            data.push({ type: "docNumber", msg: "Invalid AADHAR number" });
          }
        }
        if (uploadLink === "") {
          data.push({ type: "upload", msg: "Please attach a valid id proof" });
        }
      }
      setErr(data);
      if (data.length !== 0) {
        setInnerLoad(false);
        return;
      }
    }

    let a = docNumber;

    try {
      let data = {
        firstName: capitalize(firstName),
        lastName: capitalize(lastName),
        dob: moment(startDate).format("YYYY-MM-DD"),
        gender: gender,
        gothram: capitalize(gothram),
        idProofType: docType,
        idProofNumber: a,
      };
      if (uploadLink?.fileUrl) {
        data.docFileUrl = uploadLink.fileUrl;
      } else {
        if (uploadLink !== "") {
          data.docFileUrl = uploadLink;
        }
      }
      await Sd_DashboardService.update_master_list(data, id, templeID);

      toast.success("Update succesfull");
      setEdit(false);
      clearData();
      setInnerLoad(false);
      getData(1, 10);
      console.log("succ");
    } catch (e) {
      if (e.message === "Session Expired") {
        logout();
        // localStorage.clear();
        history?.push(
          `/${selectedLanguage}/devotee/signin?to=devotee-app/my-account/devotee-masterlist`
        );
        setInnerLoad(false);
      }
      setInnerLoad(false);
    }
  };
  const getTotal = () => {
    let array = [];
    for (let i = 1; i <= list?.meta?.totalPages; i++) {
      array.push(
        <li
          className={act ? "sd-background" : ""}
          onClick={() => {
            if (act === i) {
              return;
            }
            getData(i, 10);
          }}
        >
          <a>{i}</a>
        </li>
      );
    }
    return array;
  };
  //-------------------------------------------------------------functions--------------------------------------------------------//

  return (
    <div
      className="col-xs-12 col-sm-12 col-md-9 col-lg-9 sd-dashboard"
      style={{
        minHeight: document.getElementById("side-menu")?.offsetHeight,
      }}
    >
      {listLoad ? (
        <Sd_loading />
      ) : edit ? (
        innerLoad ? (
          <Sd_loading />
        ) : (
          <div className="col-xs-12 col-sm-12 col-md-11 col-lg-10 pl-0 xs-pr-0 sd-profile sd-seva-form">
            <h2 className="sd-side-heading fw400 ">
              <span className="fw700">Add </span> New Devotee
            </h2>

            <div className="clearfix">
              <form className="sd-form">
                <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0 pl-0">
                  <label>
                    First Name<span className="sd-imp">*</span>
                  </label>
                  <input
                    autoFocus={true}
                    maxLength="30"
                    placeholder="Please enter your first name"
                    type="text"
                    value={firstName}
                    className={
                      err.find((e) => e.type === "first")
                        ? "sd-red form-control"
                        : firstName !== ""
                        ? "sd-voilet sd-more form-control "
                        : "form-control"
                    }
                    onChange={(e) => {
                      if (
                        /^[a-zA-Z ]*$/.test(String(e.target.value)) ||
                        e.target.value
                      ) {
                        if (
                          err.find(
                            (e) => e.type === "first" || e.type === "common"
                          )
                        ) {
                          let data = err.filter((e) => {
                            if (e.type !== "first" && e.type !== "common") {
                              return e;
                            }
                          });
                          setErr(data);
                        }
                        setFirstName(e.target.value);
                      }
                    }}
                  />
                  <p className="sd-EM mr-15">
                    {err.find((e) => e.type === "first")?.msg}
                  </p>
                  <input style={{ display: "none" }} value={id}></input>
                </div>
                <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pl-0 xssm-pr-0 pr-0">
                  <label>
                    Last Name<span className="sd-imp">*</span>
                  </label>
                  <input
                    maxLength="30"
                    placeholder="Please enter your last name"
                    type="text"
                    value={lastName}
                    className={
                      err.find((e) => e.type === "last")
                        ? "sd-red form-control"
                        : lastName !== ""
                        ? "sd-voilet form-control "
                        : "form-control"
                    }
                    onChange={(e) => {
                      if (
                        /^[a-zA-Z ]*$/.test(String(e.target.value)) ||
                        e.target.value === ""
                      ) {
                        if (
                          err.find(
                            (e) => e.type === "last" || e.type === "common"
                          )
                        ) {
                          let data = err.filter((e) => {
                            if (e.type !== "last" && e.type !== "common") {
                              return e;
                            }
                          });
                          setErr(data);
                        }
                        setlastName(e.target.value);
                      }
                    }}
                  />
                  <p className="sd-EM ">
                    {err.find((e) => e.type === "last")?.msg}
                  </p>
                </div>
                <div className="form-group col-xs-12 col-sm-12 col-md-3 col-lg-3 xssm-pr-0 pl-0">
                  <label>
                    Date of Birth<span className="sd-imp">*</span>
                  </label>
                  <div
                    className={
                      err.find((e) => e.type === "date")
                        ? "sd-dob customDatePickerWidth  sd-red"
                        : startDate !== ""
                        ? "sd-voilet sd-dob customDatePickerWidth"
                        : "sd-dob customDatePickerWidth"
                    }
                  >
                    <DatePicker
                      dayPlaceholder="DD"
                      monthPlaceholder="MM"
                      yearPlaceholder="YYYY"
                      style={{
                        width: "90%",
                        border: "none",
                        backgroundColor: "blue",
                      }}
                      // formatLongDate={"DD-MM-YYYY"}
                      showLeadingZeros={true}
                      format="dd-MM-y"
                      maxDate={addDays(new Date(), -2555)}
                      calendarIcon={<img src={sd_dashboard_dob} alt="" />}
                      clearIcon={null}
                      value={startDate}
                      onChange={(date) => {
                        if (
                          err.find(
                            (e) => e.type === "date" || e.type === "common"
                          )
                        ) {
                          let data = err.filter((e) => {
                            if (e.type !== "date" && e.type !== "common") {
                              return e;
                            }
                          });
                          setErr(data);
                        }
                        setStartDate(date);
                      }}
                    />
                  </div>
                  <p className="sd-EM mr-15">
                    {err.find((e) => e.type === "date")?.msg}
                  </p>
                </div>
                <div className="form-group col-xs-12 col-sm-12 col-md-3 col-lg-3  xssm-pl-0 xssm-pr-0">
                  <label>
                    Gender<span className="sd-imp">*</span>
                  </label>
                  <select
                    className={
                      err.find((e) => e.type === "gender")
                        ? "form-control sd-red"
                        : gender !== ""
                        ? "form-control sd-voilet"
                        : "form-control"
                    }
                    value={gender}
                    onChange={(e) => {
                      if (
                        err.find(
                          (e) => e.type === "gender" || e.type === "common"
                        )
                      ) {
                        let data = err.filter((e) => {
                          if (e.type !== "gender" && e.type !== "common") {
                            return e;
                          }
                        });
                        setErr(data);
                      }
                      setGender(e.target.value);
                    }}
                  >
                    <option key="" value="" style={{ display: "none" }}>
                      Select a gender
                    </option>
                    <option key="Male" value="male">
                      Male
                    </option>
                    <option key="Female" value="female">
                      Female
                    </option>
                    <option key="Others" value="others">
                      Others
                    </option>
                  </select>
                  <p className="sd-EM mr-15">
                    {err.find((e) => e.type === "gender")?.msg}
                  </p>
                </div>
                <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pl-0 pr-0">
                  <label>
                    Gothram<span className="sd-imp">*</span>
                  </label>
                  <input
                    type="text"
                    maxLength="30"
                    className={
                      err.find((e) => e.type === "gothram")
                        ? "form-control sd-red"
                        : gothram !== ""
                        ? "form-control sd-voilet"
                        : "form-control"
                    }
                    value={gothram}
                    placeholder="Please enter your gothram"
                    onChange={(e) => {
                      if (
                        /^[a-zA-Z ]*$/.test(String(e.target.value)) ||
                        e.target.value === ""
                      ) {
                        if (
                          err.find(
                            (e) => e.type === "gothram" || e.type === "common"
                          )
                        ) {
                          let data = err.filter((e) => {
                            if (e.type !== "gothram" && e.type !== "common") {
                              return e;
                            }
                          });
                          setErr(data);
                        }
                        setGothram(e.target.value);
                      }
                    }}
                  />
                  <p className="sd-EM mr-5">
                    {err.find((e) => e.type === "gothram")?.msg}
                  </p>
                </div>
                <hr className="clearfix"></hr>
                <div className="form-group col-xs-12 col-sm-12 col-md-3 col-lg-3  pl-0  xssm-pr-0">
                  <label>
                    Select Type of ID Proof<span className="sd-imp">*</span>
                  </label>
                  <select
                    className={
                      err.find((e) => e.type === "docType")
                        ? "sd-red form-control"
                        : docType !== ""
                        ? "form-control sd-voilet"
                        : "form-control"
                    }
                    value={docType}
                    onChange={(e) => {
                      if (
                        err.find(
                          (e) => e.type === "docType" || e.type === "common"
                        )
                      ) {
                        let data = err.filter((e) => {
                          if (e.type !== "docType" && e.type !== "common") {
                            return e;
                          }
                        });
                        setErr(data);
                      }
                      setDockType(e.target.value);
                    }}
                  >
                    <option style={{ display: "none" }} value="" key="">
                      Select type of ID proof
                    </option>
                    <option key="pan" value="pan">
                      PAN
                    </option>
                    <option key="aadhar" value="aadhar">
                      AADHAR
                    </option>
                    <option key="driving" value="driving">
                      Driving License
                    </option>
                    <option key="ration" value="ration">
                      Ration Card
                    </option>
                    <option key="voter" value="voter">
                      Voter ID
                    </option>
                  </select>
                  <p className="sd-EM mr-15">
                    {err.find((e) => e.type === "docType")?.msg}
                  </p>
                </div>
                <div className="form-group col-xs-12 col-sm-12 col-md-3 col-lg-3   xssm-pl-0 xssm-pr-0">
                  <label>
                    Photo ID Number<span className="sd-imp">*</span>
                  </label>
                  <input
                    maxLength="30"
                    type="text"
                    placeholder="Enter a valid id proof
                    "
                    className={
                      err.find((e) => e.type === "docNumber")
                        ? "form-control sd-red"
                        : docNumber !== ""
                        ? "form-control sd-voilet"
                        : "form-control"
                    }
                    style={{ paddingLeft: "15px", textTransform: "none" }}
                    value={docNumber}
                    onChange={(e) => {
                      if (
                        /^[#.0-9a-zA-Z\s]+$/.test(e.target.value) ||
                        e.target.value === ""
                      ) {
                        if (
                          err.find(
                            (e) => e.type === "docNumber" || e.type === "common"
                          )
                        ) {
                          let data = err.filter((e) => {
                            if (e.type !== "docNumber" && e.type !== "common") {
                              return e;
                            }
                          });
                          setErr(data);
                        }
                        setdocNumber(
                          e.target.value?.replaceAll?.(" ", "")?.toUpperCase()
                        );
                      }
                    }}
                  />
                  <p className="sd-EM mr-15">
                    {err.find((e) => e.type === "docNumber")?.msg}
                  </p>
                </div>
                <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6   xssm-pl-0 pr-0">
                  <label>
                    Upload ID Proof<span className="sd-imp">*</span>
                  </label>
                  {uploadLink !== "" && (
                    <div className="upload-button">
                      <p>
                        {uploadLink?.originalname
                          ? uploadLink?.originalname
                          : "Uploaded"}{" "}
                      </p>{" "}
                      <img
                        src={sd_trash}
                        alt="delete"
                        onClick={() => {
                          if (
                            err.find(
                              (e) => e.type === "upload" || e.type === "common"
                            )
                          ) {
                            let data = err.filter((e) => {
                              if (e.type !== "upload" && e.type !== "common") {
                                return e;
                              }
                            });
                            setErr(data);
                          }
                          setUploadLink("");
                        }}
                      />
                    </div>
                  )}
                  {uploadFlag && (
                    <div className="upload-button">
                      <p>Uploading...</p>{" "}
                    </div>
                  )}
                  {uploadLink === "" && !uploadFlag ? (
                    <input
                      style={{ padding: "0px 12px" }}
                      type="file"
                      placeholder="Browse"
                      id="uploadInputdm"
                      accept="image/png, image/jpeg,image/jpg,.pdf"
                      className={
                        err.find((e) => e.type === "upload")
                          ? "form-control sd-red"
                          : uploadLink !== ""
                          ? "form-control sd-voilet"
                          : "form-control"
                      }
                      onChange={(e) => {
                        if (
                          err.find(
                            (e) => e.type === "upload" || e.type === "common"
                          )
                        ) {
                          let data = err.filter((e) => {
                            if (e.type !== "upload" && e.type !== "common") {
                              return e;
                            }
                          });
                          setErr(data);
                        }
                        handleFileUpload(e);
                      }}
                    />
                  ) : (
                    ""
                  )}
                  <p className="sd-EM ">
                    {err.find((e) => e.type === "upload")?.msg}
                  </p>
                </div>
                <hr className="clearfix"></hr>
                <div className="clearfix mt-50">
                  <a
                    className="sd-btn-orange"
                    style={{ marginRight: "10px" }}
                    onClick={() => {
                      if (!editFlag) {
                        setEdit(false);
                        clearData();
                      } else {
                        setEdit(false);
                        clearData();

                        seteditFlag(false);
                      }
                    }}
                  >
                    <img
                      style={{
                        transform: "rotate(180deg)",
                        marginRight: "10px",
                      }}
                      className="sd-arrow-btn ml-5"
                      src={sd_side_arrow}
                      alt=">"
                    />
                    Back{" "}
                  </a>
                  <a
                    className="sd-btn-orange"
                    onClick={() => {
                      if (!uploadFlag) {
                        if (editFlag) {
                          handleEditSubmit();
                        } else {
                          handleSubmit();
                        }
                      }
                    }}
                  >
                    {editFlag ? "Update" : "Continue"}{" "}
                    <img
                      className="sd-arrow-btn ml-5"
                      src={sd_dashboard_side_arrow}
                      alt=">"
                    />
                  </a>
                </div>
              </form>
            </div>
          </div>
        )
      ) : (
        <div className="sd-profile sd-seva-form">
          <h2 className="sd-side-heading fw400 col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0 sd-650 pr-0">
            <span className="fw400">
              <span className="fw700">Devotee </span> Master List
            </span>
            <span className="float-right mr-0">
              <span className="sd-view-all">
                <a
                  className="sd-btn-orange-lg mr-0"
                  onClick={() => {
                    setEdit(true);
                  }}
                >
                  <img className="sd-arrow-btn ml-5" src={sd_white_plus} /> New
                  Devotee
                </a>
              </span>
            </span>
          </h2>
          <div className="clearfix ">{displayFunction()}</div>
          <br />
          {list?.items?.length !== 0 && <hr className="clearfix"></hr>}
          {list?.items?.length !== 0 && (
            <div className="clearfix mt-20">
              <p className="float-left">
                Showing {list?.items?.length} of {list?.meta?.itemCount}
              </p>
              <ul className="pagination mt-0 float-right">
                <li>
                  <a>
                    <img src={sd_move_left} alt="<-" />
                  </a>
                </li>
                {getTotal()}

                <li>
                  <a>
                    <img src={sd_move_right} />
                  </a>
                </li>
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default Sd_dashboard_ma_devotee_master;
