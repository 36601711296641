import React, { useState, useEffect } from "react";
import "../../Utils/sd-styles/sd-home.scss";
import Sd_about_general_information_component from "../../Components/body/sd-about/sd-about-general-information";
import {
  bannerAlt,
  greaterthan,
  orange_x,
  SD_Hamburger,
  // SD_parokshaseva_banner_img,
} from "../../Assets/imageList";
import { withRouter } from "react-router";
import Sd_faqs_semi_view from "../../Components/sd-common-components/sd-faqs-semi-view";
import Sd_gallery_semi_view from "../../Components/sd-common-components/sd-gallery-semi-view";
const Sd_about_general_information = ({ history }) => {
  const [selectedLanguage, setSelectedlanguage] = useState("en-in");
  const [visible, setVisible] = useState(true);

  const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];
  useEffect(() => {
    if (
      supportedLanguages.includes(
        history?.location?.pathname?.split?.("/")?.[1]
      )
    ) {
      setSelectedlanguage(history?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
  }, []);
  useEffect(() => {
    if (window.innerWidth < 600) {
      setVisible(false);
    }
  }, []);
  return (
    <div className="w-100">
      <div className="sd-Paroksha-one">
        <div className="banner">
          <div className="sd-banner-top">
            <h1>
              <span>General Information </span>
            </h1>
            <h4>Kanipakam - Sathya Pramanala Nilayam.</h4>
          </div>

          <div className="sd-banner-bottom">
            <img src={bannerAlt} alt="icon" />
          </div>
        </div>
        <div className="breadcrumb-div-about">
          <div className="sd-bc-left-about">
            <p className="voilet">Home</p>
            <img src={greaterthan} className="mr-2 ml-2" alt=">" />
            <p
              onClick={() => {
                history?.push(`/${selectedLanguage}/about`);
              }}
              className="voilet"
            >
              About
            </p>
            <img src={greaterthan} className="mr-2 ml-2" alt=">" />

            <p>General Information</p>
          </div>
          <div className="sd-bc-right-about">
            <button
              onClick={() => {
                setVisible(!visible);
              }}
            >
              Menu <img src={visible ? orange_x : SD_Hamburger} alt="X" />
            </button>
          </div>
        </div>
      </div>{" "}
      <Sd_about_general_information_component
        visible={visible}
        setVisible={setVisible}
      />
      <Sd_faqs_semi_view from="about" />
      <Sd_gallery_semi_view />
    </div>
  );
};
export default withRouter(Sd_about_general_information);
