/* eslint-disable react/jsx-pascal-case */
import React from "react";
import {
  banner2,
  // SD_ganapathi_homam_banner_img,
  // SD_parokshaseva_banner_img,
} from "../../../Assets/imageList";
import "../../../Utils/sd-styles/sd-parokshaseva.scss";
import Sd_breadcrumb from "../../sd-common-components/sd-breadcrumb";

const SD_Pratyaksha_Seva_One = ({ from, data }) => {
  //-------------------------------------------------------------consts-----------------------------------------------------------//
  const pratyakshasevaArray = [
    "p-Sri Sakshi Ganapathi Homam",
    "p-Mrityunjaya Homam",
    "p-VIP Break Darshanam",
    "p-Abhishekam at Garbhalayam",
    "p-Abhishekam Alakara Mandapam",
    "p-Sri Swamy Ammavarla Kalyanam",
    "p-Abhishekam",
    "p-Chandi Homam",
    "p-Ekantha Seva",
    "p-Ganapathi Homam",
    "p-Kalyanotsavam",
    "p-Kumkumarchana in Antharalayam",
    "p-Kumkuma Pooja Sri Chakram",
    "p-Laksha Bilvarchakam",
    "p-Laksha Kumkumarchana",
    "p-Mahanyasa Purvaka Rudrabhishekam",
    "p-Rudra Homam",
    "p-Sarva Seva Pathakam",
    "p-Suprabhata Seva",
    "p-Vruddha Mallikarjuna Swamy Vaari Abhishekam",
    "p-Valli Devasena sametha Subramanyeswara Swamy Kalyanam",
    "p-Samuhika Abhishekamulu",
    "p-Leela Kalyanotsavam",
  ];
  //-------------------------------------------------------------consts-----------------------------------------------------------//
  return (
    <div className="sd-Paroksha-one">
      <div className="banner">
        <div className="sd-banner-top">
          <h1>
            {from === "Pratyaksha Seva" ? (
              <span>
                Pratyaksha<span>Seva</span>
              </span>
            ) : pratyakshasevaArray.includes(from) ? (
              <span>{from.split("-")[1]}</span>
            ) : (
              <span>{from} </span>
            )}
          </h1>
          <h4>Kanipakam - Sathya Pramanala Nilayam.</h4>
        </div>

        <div className="sd-banner-bottom">
          <img
            src={from === "Ganapathi-homam" ? banner2 : banner2}
            alt="icon"
          />
        </div>
      </div>
      <Sd_breadcrumb
        data={data}
        from={from === "Pratyaksha Seva" ? "Pratyaksha Seva" : from}
      />
    </div>
  );
};
export default SD_Pratyaksha_Seva_One;
