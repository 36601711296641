import React from "react";
import { loader } from "../../Assets/imageList";
import "../../Utils/sd-styles/sd-home.scss";
const Sd_loading = ({ dist }) => {
  return (
    <div className="sd-loading" style={{ width: dist ? "67% !important" : "" }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        style={{
          margin: "auto",
          background: "white none repeat scroll 0% 0%",
          display: "block",
          shapeRendering: "auto",
        }}
        width={
          window.innerWidth < 600
            ? "90px"
            : window.innerWidth < 900
            ? "110px"
            : "140px"
        }
        height={
          window.innerWidth < 600
            ? "90px"
            : window.innerWidth < 900
            ? "110px"
            : "140px"
        }
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <image
          overflow="visible"
          width="85"
          xlinkHref={loader}
          transform="matrix(0.6242 0 0 0.6242 25 18.1847)"
        ></image>
      </svg>
      <br />
    </div>
  );
};
export default Sd_loading;
