import moment from "moment";
import React, { useState } from "react";
import { withRouter } from "react-router";
import {
  calender,
  hand_icon,
  SD_divider,
  sun_icon,
} from "../../../Assets/imageList";
import "../../../Utils/sd-styles/sd-main-sup.scss";
const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];

const TemplateFour = ({ history }) => {
  const [selectedlanguage, setSelectedlanguage] = React.useState("en-in");
  const [active, setActive] = useState("news");
  const timings = [
    { time: "4.00 A.M To 5.00 A.M", name: "SUPRABATHA SEVA" },
    { time: "5-00 A.M To 5-30 A.M", name: "NIJAROOPA DARSHANAM" },
    { time: "5-30 A.M To 6-00 A.M", name: "PANCHAMRUTHABHISHEKAM" },
    { time: "6-00 A.M To 6-15 A.M", name: "MAHA HARATHI" },
    { time: "6-15 A.M To 7-00 A.M", name: "SARVA DARSHANAM" },
    { time: "7-00 A.M To 7-30 A.M", name: "NIJAROOPA DARSHANAM" },
    { time: "7-30 A.M To 8-00 A.M", name: "PALABHISHEKAM" },
    { time: "8-00 A.M To 8-30 A.M", name: "SARVA DARSHANAM" },
    { time: "8-30 A.M To 9-00 A.M", name: "NIJAROOPA DARSHANAM" },
    { time: "9-00 A.M To 10-00 A.M", name: "PANCHAMRUTHABHISHEKAM" },
    { time: "10-00 A.M To 10-30 A.M", name: "SARVA DARSHANAM" },
    { time: "10-30 A.M To 11-00 A.M", name: "NIJAROOPA DARSHANAM" },
    { time: "11-00 A.M To 12-00 P.M", name: "PANCHAMRUTHABHISHEKAM" },
    { time: "12-00 P.M To 4-30 P.M", name: "SARVA DARSHANAM" },
    { time: "4-30 P.M To 5-00 P.M", name: "NIJAROOPA DARSHANAM" },
    { time: "5-00 P.M To 5-45 P.M", name: "PRAMANAM & TEMPLE CLEANING" },
    { time: "5-45 P.M To 6-15 P.M", name: "PALABHISHEKAM" },
    { time: "6-15 P.M To 6-30 P.M", name: "MAHA HARATHI, MANTRA PUSHPAMULU" },
    { time: "6-30 P.M To 9- 30 P.M", name: "SARVA DARSHANAM" },
  ];

  React.useEffect(() => {
    if (
      supportedLanguages.includes(
        history?.location?.pathname?.split?.("/")?.[1]
      )
    ) {
      setSelectedlanguage(history?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
  }, []);
  return (
    <>
      <div className="top bc-yellow">
        <h1>Happenings in Temple</h1>
        <img src={SD_divider} alt="-" style={{ height: "20px" }}></img>
      </div>
      <div className="sd-template-four">
        <div className="sd-t4-one">
          <div className="sd-t4-one-start">
            <span className="span">Panchangam</span>
            <img src={sun_icon} alt="alt" />
          </div>
          <div className="sd-t4-calender">
            <span>{moment().format("dddd, D MMM YYYY ")}</span>{" "}
            <img src={calender} />
          </div>
          <div
            style={{ textAlign: "center", margin: "0px" }}
            className="element-panchangam-wrapper"
          >
            <div className="element-panchangam">
              <div className="w-100">
                <p>Samvatsaram</p>
                <h5>Sri Shubhakrut Nama Samvathsaram</h5>
              </div>
            </div>
            <div className="element-panchangam">
              <div>
                <p>Masam</p>
                <h5>Sravana</h5>
              </div>
              <div>
                <p>Paksham</p>
                <h5>Sukla</h5>
              </div>
              <div>
                <p>Sunrise</p>
                <h5>05:45 a.m</h5>
              </div>
            </div>
            <div className="element-panchangam">
              <div>
                <p>Tithi</p>
                <h5>
                  Dasami upto <br /> 03:53 a.m <br /> afterwards Ekadasi
                </h5>
              </div>
              <div>
                <p>Nakshatram</p>
                <h5>
                  Rohini upto
                  <br /> 06:47 a.m <br />
                  afterwards Mrigisira{" "}
                </h5>
              </div>
              <div>
                <p>Sunset</p>
                <h5>06:25 p.m</h5>
              </div>
            </div>
            <div className="element-panchangam">
              <div>
                <p>Yamagandam </p>
                <h5>
                  12:00 p.m <br /> to 01:30 p.m
                </h5>
              </div>
              <div>
                <p>Rahukalam </p>
                <h5>
                  04:30 p.m <br /> to 06:00 p.m
                </h5>
              </div>
              <div>
                <p>Amruta Gadi</p>
                <h5>
                  10:27 p.m <br />
                  to 12:12 a.m
                </h5>
              </div>
            </div>
            <div className="element-panchangam">
              <div>
                <p>Varjyam</p>
                <h5>12:55 p.m to 02:40 p.m</h5>
              </div>
              <div>
                <p>Durmuhurtham</p>
                <h5>04:41 p.m to 05:31 p.m</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="sd-t4-one">
          <div className="sd-t4-one-middle">
            <span className="span">Day Schedule</span>{" "}
            <img src={hand_icon} alt="alt" />
          </div>
          <div className="sd-t4-calender">
            <span>{moment().format("dddd, D MMM YYYY ")}</span>{" "}
            <img src={calender} />
          </div>

          <div className="sd-t4-one-end-m">
            {timings.map((item, i) => {
              return (
                <div key={"sasdasdsd " + i} className="element-date-m">
                  <div>{item.time}</div>
                  <p>{item.name}</p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="sd-t4-one">
          {" "}
          <div className="sd-t4-one-end">
            <span
              className={active === "news" ? "span-active" : ""}
              onClick={() => {
                setActive("news");
              }}
            >
              News
            </span>{" "}
            <span
              className={active === "events" ? "span-active" : ""}
              onClick={() => {
                setActive("events");
              }}
            >
              Events
            </span>{" "}
            <span
              className={active === "tenders" ? "span-active" : ""}
              onClick={() => {
                setActive("tenders");
              }}
            >
              Tenders
            </span>
          </div>
          <div className="sd-t4-one-end-d">
            {active === "news" ? (
              [
                "Sri Kanipaka Devasthanam gets 10 Crores facelift from donors for the reconstruction of the Temple.",
                "The reconstruction of Kanipakam temple was completed within a short period and ready for giving the sanctified Darshanam to devotees.",
                "Thousands of devotees are reaching Kanipakam for the Darshanam of Mula Virat as the main deity is disclosed from 3 months for the reconstruction of the temple.",
                "Kanipakam is filled with devotees for the Kumbhashikem on 21st of August, where many Ministers are invited for the sacred cause.",
                "The new temple of Swayambhu Sri Varasiddhi Vinayaka Swamy is considered as the fastest constructed structure in recent times.",
              ].map((item, i) => {
                return (
                  <div key={"sasdasd +i"} className="element-date">
                    <div>
                      AUG
                      <br />
                      <span>21</span>
                    </div>
                    <p style={{ color: "#171717" }}>{item}</p>
                  </div>
                );
              })
            ) : (
              <p style={{ textAlign: "center", marginTop: "20px" }}>
                No {active}
              </p>
            )}
          </div>
        </div>
      </div>{" "}
    </>
  );
};
export default withRouter(TemplateFour);
