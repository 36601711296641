import React from "react";
import "../../Utils/sd-styles/sd-misc.scss";
import ParokshaSevaOne from "../../Components/body/paroksha-seva/sd-paroksha-seva-component-one";
import Sd_comming_soon from "../../Components/sd-common-components/sd-content-loading";
const Sd_terms_and_conditions = () => {
  return (
    <div>
      {/* <Sd_breadcrumb from="Terms and Conditions"></Sd_breadcrumb> */}
      <ParokshaSevaOne from="Terms and Conditions" />
      <Sd_comming_soon />
    </div>
  );
};
export default Sd_terms_and_conditions;
