import React from "react";
import CommsService from "../services/sd-comms.service";
import Sd_Reload from "../Components/sd-common-components/sd-reload";
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  async componentDidCatch(error, errorInfo) {
    console.log(error);
    // You can also log the error to an error reporting service
    // await CommsService.errors(error?.message, errorInfo);
    console.log(error);
    //   logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <Sd_Reload />;
    }

    return this.props.children;
  }
}
export default ErrorBoundary;
