import React from "react";
import { toast } from "react-toastify";
import Sd_faqs_semi_view from "../../../Components/sd-common-components/sd-faqs-semi-view";
import SD_Paroksha_Seva_One from "../../../Components/body/paroksha-seva/sd-paroksha-seva-component-one";
import SD_Paroksha_Seva_Inner_Component_One from "../../../Components/body/paroksha-seva/sd-paroksha-seva-inner-components/sd-paroksha-seva-inner-component-one";
import SD_Paroksha_Seva_Inner_Component_Two from "../../../Components/body/paroksha-seva/sd-paroksha-seva-inner-components/sd-paroksha-seva-inner-component-two";
import Sd_gallery_semi_view from "../../../Components/sd-common-components/sd-gallery-semi-view";
import { SD_Context } from "../../../context/sd-context";
import ParokshaSevaService from "../../../services/sd-parokshaseva.service";
import "../../../Utils/sd-styles/sd-home.scss";
import Sd_loading from "../../../Components/sd-common-components/sd-loading";
import { Helmet } from "react-helmet";

const Sd_sevas_and_darshanam_paroksha_seva_nandeeshwara_abhishekam = () => {
  //-------------------------------------------------------------states-----------------------------------------------------------//
  const { parokshaSevaData, setParokshaSevaData } =
    React.useContext(SD_Context);
  const [data, setData] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  //-------------------------------------------------------------states-----------------------------------------------------------//
  //-------------------------------------------------------------useffect-----------------------------------------------------------//
  React.useEffect(() => {
    if (parokshaSevaData.length !== 0) {
      setLoading(true);
      let datas = parokshaSevaData.find(
        (item) =>
          item?.name?.replace?.(/\s/g, "").toLowerCase() ===
          "nandeeshwaraswamyvarivisesharchana"
      );
      if (datas) {
        setData(datas);
      }
      setLoading(false);
    } else {
      getData();
    }
  }, []);
  //-------------------------------------------------------------useffect-----------------------------------------------------------//
  //-------------------------------------------------------------functions-----------------------------------------------------------//
  const getData = async () => {
    setLoading(true);
    try {
      let data = await ParokshaSevaService.getAll();
      if (data) {
        setParokshaSevaData(data);
        let datas = data.find(
          (item) =>
            item?.name?.replace?.(/\s/g, "").toLowerCase() ===
            "nandeeshwaraswamyvarivisesharchana"
        );
        if (datas) {
          setData(datas);
        }
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  //-------------------------------------------------------------functions-----------------------------------------------------------//
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Nandeeshwara Swamy vari Visesharchana| Nandeeshwara Swamy vari
          Visesharchana at Kanipakam | Kanipakam Online
        </title>
        <meta
          name="description"
          content="Nandeeshwara Swamy vari Visesharchana at Kanipakam using honey, milk, flowers, water, gandham etc is very powerful. Book the Abhishekam at Kanipakam Online.ekam at Kanipakam"
        />
        <meta
          name="keywords"
          content=" Nandeeshwara Swamy vari Visesharchana,
          Nandeeshwara Swamy vari Visesharchana,
          Nandeeshwarudu,
          Nandeeshwarudu pooja,
          Srisaila nandeeshwarudu ,Sanagala Basavanna, Sanagala Basavanna at Kanipakam, Kanipakam Online"
        />
      </Helmet>
      {loading ? (
        <Sd_loading />
      ) : (
        <div className="w-100">
          <SD_Paroksha_Seva_One
            from={"Nandeeshwara Swamy vari Visesharchana"}
            data={data}
          />
          <SD_Paroksha_Seva_Inner_Component_One data={data} />
          <SD_Paroksha_Seva_Inner_Component_Two data={data} />
          <Sd_faqs_semi_view from="about" />
          <Sd_gallery_semi_view />
        </div>
      )}
    </>
  );
};
export default Sd_sevas_and_darshanam_paroksha_seva_nandeeshwara_abhishekam;
